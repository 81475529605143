<template>
	<div style="background: white; width: 100%">
		<div class="panel">
			<ztable2
				:detailColumns="detailColumns"
				:columns="tableColumns"
				:dataSource="dataSource"
				size="small"
				@change="onChange"
			>
			</ztable2>
		</div>
	</div>
</template>

<script>
export default {
	name: '',
	data() {
		return {
			detailColumns: [
				{
					props: 'id',
					type: 'tag',
					color: 'red',
				},
			],
			tableColumns: [
				{
					dataIndex: 'id',
					title: 'ID',
					sorter: true,
				},
				{
					dataIndex: 'name',
					title: 'Name',
				},
			],
			dataSource: [
				{
					id: '120',
					name: 'GavinGavin ',
				},
			],
		}
	},
	methods: {
		onChange(pagination, filters, sorter) {
			console.log('params', pagination, filters, sorter)
		},
	},
}
</script>

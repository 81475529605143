<!--
 * @Author: Yin Xiang Zheng
 * @LastEditors: Yin Xiang Zheng
 * @LastEditTime: 2020-11-14 23:46:42
-->
<!--
 * @Author: Yin Xiang Zheng
 * @LastEditors: Yin Xiang Zheng
 * @LastEditTime: 2020-07-18 10:16:21
--> 
<template>
	<div class="news-content">
		<a-form layout="inline" style="margin-bottom: 12px">
			<a-row>
				<a-col :span="8">
					<a-form-item label="模糊搜索">
						<a-input
							v-model="queryParam.queryValue"
							placeholder="公告标题、公告内容"
						/>
					</a-form-item>
				</a-col>
			</a-row>
			<a-row>
				<a-col :span="12">
					<span class="table-page-search-submitButtons">
						<a-button type="primary" @click="getTableDatas"
							>查询</a-button
						>
						<a-button
							style="margin-left: 8px"
							@click="
								() => {
									this.queryParam = {}
									this.getTableDatas()
								}
							"
							>重置</a-button
						>
					</span>
				</a-col>
				<a-col :span="12" style="text-align: right">
					<span class="table-page-search-submitButtons">
						<a-button type="primary" @click="addNewHandle"
							>新建</a-button
						>
					</span>
				</a-col>
			</a-row>
		</a-form>
		<ztable2
			:dataSource="dataSource"
			:pagination="pagination"
			:columns="tableConfig"
			:detailColumns="detailConfig"
			@change="tableChangeHandle"
		></ztable2>
	</div>
</template>

<script>
import { list, updateNews } from '@/api/announcement'
import moment from 'moment'

export default {
	data() {
		return {
			// 查询参数
			queryParam: {},
			dataSource: [],
			detailConfig: [
				{
					props: 'newsTitle',
					renderContent: ({ value, dataSource }) => {
						return dataSource.title
					},
				},
				{
					type: 'text',
					props: 'status',
					renderContent: ({value}) => {
						let color = value === 0 ? 'orange' : 'green'
						let tagText = value === 0 ? '未发布' : '已发布'
						return <a-tag color={color}>{tagText}</a-tag>
					},
				},
				{
					type: 'text',
					props: 'createdAt',
					renderContent: ({value}) => {
						return moment(value).format('YYYY-MM-DD HH:mm:ss')
					},
				},
				{
					props: 'operating',
					type: 'text',
					renderContent: ({value, dataSource}) => {
						const aText = dataSource.status === 0 ? '发布' : '下架'
						const props = {
							on: {
								click: (event) => {
									updateNews(dataSource._id, {
										...dataSource,
										status: dataSource.status === 0 ? 1 : 0,
									}).then((res) => {
										this.getTableDatas()
									})
								},
							},
							style: {
								marginRight: '14px',
							},
						}
						const editProps = {
							on: {
								click: (event) => {
									this.$router.push({
										path: '/community/ONewsEdit',
										query: { _id: dataSource._id },
									})
								},
							},
						}
						return (
							<div>
								<a {...props}>{aText}</a>
								<a {...editProps}>编辑</a>
							</div>
						)
					},
				},
			],
			tableConfig: [
				{
					title: '公告标题',
					dataIndex: 'newsTitle',
				},
				{
					title: '访问量',
					dataIndex: 'viewsCount',
				},
				{
					title: '状态',
					dataIndex: 'status',
				},
				{
					title: '创建时间',
					dataIndex: 'createdAt',
				},
				{
					title: '操作',
					dataIndex: 'operating',
				},
			],
			addModalVisible: false,
			addModalConfig: {
				title: '添加公司',
				width: 800,
			},
			pagination: {
				total: 0,
				pageSize: 10,
				current: 1,
				size: 'small',
				showTotal: (total) => `共计 ${total} 条`,
				showQuickJumper: true,
				showSizeChanger: true,
				pageSizeOptions: ['10', '20', '50', '100'],
			},
			params: {
				queryValue: '',
				pageNum: 1,
				pageSize: 10,
				sortBies: [],
			},
			form: this.$form.createForm(this, { name: 'addModal' }),
		}
	},
	components: {},
	mounted() {
		this.getTableDatas()
	},
	methods: {
		tableChangeHandle(pagination, filters, sorter) {
			console.log(pagination, filters, sorter);
			let { current, pageSize } = pagination
			this.params.pageNum = current
			this.params.pageSize = pageSize

			for (let i in filters) {
				this.params[i] = filters[i][0]
			}

			this.pagination.current = current
			this.pagination.pageSize = pageSize

			if (sorter.order) {
				this.params['sortBies'][0] = {
					asc: sorter.order === 'ascend' ? 1 : 0,
					field: sorter.field,
				}
			}
			this.getTableDatas()
		},
		getTableDatas() {
			let params = { ...this.params }
			if (!params['sortBies']) {
				params['sortBies'] = []
				params['sortBies'][0] = {
					asc: 0,
					field: 'createdAt',
				}
			}
			Object.assign(params, this.queryParam)

			this.loading = true
			return new Promise((resolve, reject) => {
				list(params)
					.then((res) => {
						console.log('res', res)
						this.loading = false
						this.dataSource = res.list || res
						this.pagination.total = res.total
						this.pagination.current = res.pageNum
						this.pagination.pageSize = res.pageSize
						if (this.getTableDatasCallBack) {
							this.getTableDatasCallBack()
						}
						resolve()
					})
					.catch((err) => {
						console.log(err)
						this.loading = false
						reject(err)
					})
			})
		},
		updateNews(id, data) {
			updateStatus(id, data)
				.then((res) => {
					this.$message.success('更新成功！')
					this.getTableDatas()
				})
				.catch((err) => {
					this.$message.error('更新失败')
				})
		},
		addNewHandle() {
			this.$router.push('/community/ONewsEdit')
		},
	},
}
</script>

<style lang="less" scoped>
.news-content {
	background: white;
	padding: 16px;
	height: 100%;
}
</style>
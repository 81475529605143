<!--
 * @Author: Yin Xiang Zheng
 * @LastEditors: Yin Xiang Zheng
 * @LastEditTime: 2021-03-09 17:40:50
-->
<template>
	<div class="cw-detail-content">
		<a-spin size="large" tip="正在生成PDF..." :spinning="spinning">
		<a-spin size="large" tip="加载中" :spinning="spinningLoading">
			<div class="title-content gen-block">
				<a-page-header
					title="会议详情"
					:breadcrumb="{ props: { routes } }"
				>
					<template slot="extra">
						<a-button type="primary" @click="backHandle"
							>返回</a-button
						>
					</template>
				</a-page-header>
				<!-- <h4></h4> -->
				<!-- <div class="bread-crumbs">
				<a-breadcrumb>
					<a-breadcrumb-item
						><a href="#" @click="backHandle()"
							>会议工作台</a
						></a-breadcrumb-item
					>
					<a-breadcrumb-item>会议详情</a-breadcrumb-item>
				</a-breadcrumb>
			
			</div> -->
			</div>

			<div class="gen-block steps-content">
				<a-steps
					:current="
						flowDetail.completedNode
							? flowDetail.completedNode.length - 1
							: 0
					"
					label-placement="vertical"
				>
					<a-step
						v-for="(item, index) of nodes"
						:key="index"
						:title="item.flowName"
					>
					<div class="step-item-sub-item" slot="subTitle">
						{{getSubTitle(item.flowNo)}}
					</div>
					<!-- :sub-title="item.relatedDocuments[item.flowNo]['CONFERENCE_ANNOUNCEMENT'].createdTime ? moment(item.relatedDocuments[item.flowNo]['CONFERENCE_ANNOUNCEMENT'].createdTime).format('YYYY-MM-DD HH:mm:ss') : ''" -->
						<div slot="description" class="sc-description">
							<template
								v-if="item.flowNo === 'CONFERENCE_ANNOUNCEMENT'"
							>
								<!-- 会议公示 -->
								<a-timeline>
									<a-timeline-item>
										<a-button
											block
											:type="
												isDisabledBtn(
													item.flowNo,
													'CONFERENCE_ANNOUNCEMENT'
												)
													? null
													: 'primary'
											"
											@click="
												showAdditionalInformationModal(
													'CONFERENCE_ANNOUNCEMENT',
													item.flowNo
												)
											"
										>
											生成公示
										</a-button>
									</a-timeline-item>
									<a-timeline-item>
										<a-button
											block
											@click="
												editCONFERENCE_ANNOUNCEMENT()
											"
										>
											修改公示
										</a-button>
									</a-timeline-item>
									<a-timeline-item>
										<a-button
											block
											@click="
												showCONFERENCE_ANNOUNCEMENTHis()
											"
										>
											查看公示修改记录
										</a-button>
									</a-timeline-item>
									<a-timeline-item>
										<a-button
											block
											:disabled="
												isDisabledBtn(
													item.flowNo,
													'CONFERENCE_ANNOUNCEMENT'
												)
											"
										>
											推送电子公示
										</a-button>
									</a-timeline-item>
									<a-timeline-item>
										<a-button
											block
											:disabled="
												isDisabledBtn(
													item.flowNo,
													'CONFERENCE_ANNOUNCEMENT'
												)
											"
											@click="
												downRelatedDocuments(
													item.flowNo,
													'CONFERENCE_ANNOUNCEMENT'
												)
											"
										>
											下载会议公示
										</a-button>
									</a-timeline-item>
									<a-timeline-item
										@click="showAMapModel(item.flowNo)"
									>
										<a-button
											block
											:disabled="
												isDisabledBtn(
													item.flowNo,
													'CONFERENCE_ANNOUNCEMENT'
												)
											"
										>
											公示信息公开
										</a-button>
									</a-timeline-item>
									<a-timeline-item>
										<a-popconfirm
											:disabled="
												isDisabledBtn(
													item.flowNo,
													'CONFERENCE_ANNOUNCEMENT'
												)
											"
											title="确认进行下一步?"
											ok-text="是"
											cancel-text="否"
											@confirm="completedNodeHandle(item.flowNo)"
											style="width: 100%"
										>
											<a-button
												block
												:disabled="
													isDisabledBtn(
														item.flowNo,
														'CONFERENCE_ANNOUNCEMENT'
													)
												"
											>
												下一步
											</a-button>
										</a-popconfirm>
									</a-timeline-item>
								</a-timeline>
							</template>

							<template
								v-if="item.flowNo === 'MEETING_ANNOUNCEMENT'"
							>
								<!-- 会议公告
								showAdditionalInformationModal(
													'MEETING_ANNOUNCEMENT',
													item.flowNo
												) -->
								<a-timeline>
									<a-timeline-item>
										<a-button
											block
											@click="
												checkAnnouncementForm('MEETING_ANNOUNCEMENT',
													item.flowNo)
												
											"
											:type="
												isDisabledBtn(
													item.flowNo,
													'MEETING_ANNOUNCEMENT'
												)
													? null
													: 'primary'
											"
										>
											生成公告
										</a-button>
									</a-timeline-item>
									<a-timeline-item>
										<a-button
											block
											:disabled="
												isDisabledBtn(
													item.flowNo,
													'MEETING_ANNOUNCEMENT'
												)
											"
										>
											推送电子公告
										</a-button>
									</a-timeline-item>
									<a-timeline-item>
										<a-button
											block
											@click="
												downRelatedDocuments(
													item.flowNo,
													'MEETING_ANNOUNCEMENT'
												)
											"
											:disabled="
												isDisabledBtn(
													item.flowNo,
													'MEETING_ANNOUNCEMENT'
												)
											"
										>
											下载会议公告
										</a-button>
									</a-timeline-item>
									<a-timeline-item>
										<a-button
										@click="showAMapModel(item.flowNo)"
											block
											:disabled="
												isDisabledBtn(
													item.flowNo,
													'MEETING_ANNOUNCEMENT'
												)
											"
										>
											公告信息公开
										</a-button>
									</a-timeline-item>
									<a-timeline-item >
										<a-popconfirm
											:disabled="
												isDisabledBtn(
													item.flowNo,
													'MEETING_ANNOUNCEMENT'
												)
											"
											title="确认进行下一步?"
											ok-text="是"
											cancel-text="否"
											@confirm="completedNodeHandle(item.flowNo)"
											style="width: 100%"
										>
											<a-button
												block
												:disabled="
													isDisabledBtn(
														item.flowNo,
														'MEETING_ANNOUNCEMENT'
													)
												"
											>
												下一步
											</a-button>
										</a-popconfirm>
									</a-timeline-item>
								</a-timeline>
							</template>

							<template v-if="item.flowNo === 'SERVE_A_VOTE'">
								<!-- 送达表决票 -->
								<a-timeline>
									<a-timeline-item>
										<a-button
											block
											@click="
												generatePDF('VOTE', item.flowNo)
											"
											:type="
												isDisabledBtn(
													item.flowNo,
													'VOTE'
												)
													? null
													: 'primary'
											"
										>
											生成表决票
										</a-button>
									</a-timeline-item>
									<a-timeline-item>
										<a-button
											block
											:disabled="
												isDisabledBtn(
													item.flowNo,
													'VOTE'
												)
											"
										>
											推送电子表决票
										</a-button>
									</a-timeline-item>
									<a-timeline-item>
										<a-button
											block
											:disabled="
												isDisabledBtn(
													item.flowNo,
													'VOTE'
												)
											"
											@click="
												downRelatedDocuments(
													item.flowNo,
													'VOTE'
												)
											"
										>
											下载表决票
										</a-button>
									</a-timeline-item>
									<a-timeline-item>
										<a-button
											block
											:disabled="
												isDisabledBtn(
													item.flowNo,
													'VOTE'
												)
											"
											@click="bigScreenVotingHandle1(`/conferenceWorkbench/votingDetails/${flowId}?hideDetail=1`)"
										> 已投票查看 </a-button>
									</a-timeline-item>
									<a-timeline-item>
										<a-button
											block
											:disabled="
												isDisabledBtn(
													item.flowNo,
													'VOTE'
												)
											"
											@click="makeUpVoteVisible = true"
										> 
										补打表决票 </a-button>
									</a-timeline-item>
									<a-timeline-item>
										<a-button
											block
											:disabled="
												isDisabledBtn(
													item.flowNo,
													'VOTE'
												)
											"
											@click="signingSituationManageHandle"
										>
											表决票签收照片管理
										</a-button>
									</a-timeline-item>
									<a-timeline-item>
										<a-button
											block
											:type="
												isDisabledBtn(
													item.flowNo,
													'NON_PERSONAL_SERVICE'
												)
													? null
													: 'primary'
											"
											@click="
												showAdditionalInformationModal(
													'NON_PERSONAL_SERVICE',
													item.flowNo
												)
											"
										>
											生成非当面送达表决票
										</a-button>
									</a-timeline-item>
									<a-timeline-item>
										<a-button
											block
											:disabled="
												isDisabledBtn(
													item.flowNo,
													'NON_PERSONAL_SERVICE'
												)
											"
											@click="
												downRelatedDocuments(
													item.flowNo,
													'NON_PERSONAL_SERVICE'
												)
											"
										>
											下载非当面送达表决票
										</a-button>
									</a-timeline-item>
									<a-timeline-item>
										<a-button
											block
											style="height: 50px"
											@click="showAMapModel(item.flowNo)"
											:disabled="
												isDisabledBtn(
													item.flowNo,
													'NON_PERSONAL_SERVICE'
												)
											"
										>
											非当面送达表决票<br />公告信息公开
										</a-button>
									</a-timeline-item>
									<a-timeline-item >
										<a-popconfirm
											:disabled="
												isDisabledBtn(
													item.flowNo,
													'NON_PERSONAL_SERVICE'
												)
											"
											title="确认进行下一步?"
											ok-text="是"
											cancel-text="否"
											@confirm="completedNodeHandle(item.flowNo)"
											style="width: 100%"
										>
											<a-button
												block
												:disabled="
													isDisabledBtn(
														item.flowNo,
														'NON_PERSONAL_SERVICE'
													)
												"
											>
												下一步
											</a-button>
										</a-popconfirm>
									</a-timeline-item>
								</a-timeline>
							</template>

							<template v-if="item.flowNo === 'RECYCLING_VOTES'">
								<!-- 回收表决票 -->
								<a-timeline>
									<a-timeline-item>
										<a-button
											block
											:type="
												isDisabledBtn(
													item.flowNo,
													'TICKET_INSPECTION_TEAM'
												)
													? null
													: 'primary'
											"
											@click="
												showAdditionalInformationModal(
													'TICKET_INSPECTION_TEAM',
													item.flowNo
												)
											"
										>
											生成验票小组公告
										</a-button>
									</a-timeline-item>
									<a-timeline-item>
										<a-button
											block
											:disabled="
												isDisabledBtn(
													item.flowNo,
													'TICKET_INSPECTION_TEAM'
												)
											"
										>
											推送验票公告
										</a-button>
									</a-timeline-item>
									<a-timeline-item>
										<a-button
											block
											@click="
												downRelatedDocuments(
													item.flowNo,
													'TICKET_INSPECTION_TEAM'
												)
											"
											:disabled="
												isDisabledBtn(
													item.flowNo,
													'TICKET_INSPECTION_TEAM'
												)
											"
										>
											下载验票小组公告
										</a-button>
									</a-timeline-item>
									<a-timeline-item>
										<a-button
											block
											@click="showAMapModel(item.flowNo)"
											:disabled="
												isDisabledBtn(
													item.flowNo,
													'TICKET_INSPECTION_TEAM'
												)
											"
										>
											验票小组公告信息公开
										</a-button>
									</a-timeline-item>
									<a-timeline-item>
										<a-button
											block
											:disabled="
												isDisabledBtn(
													item.flowNo,
													'TICKET_INSPECTION_TEAM'
												)
											"
											@click="makeUpVoteVisible = true"
										> 
										补打表决票 </a-button>
									</a-timeline-item>
									<a-timeline-item >
										<a-popconfirm
											:disabled="
												isDisabledBtn(
													item.flowNo,
													'TICKET_INSPECTION_TEAM'
												)
											"
											title="确认进行下一步?"
											ok-text="是"
											cancel-text="否"
											@confirm="completedNodeHandle(item.flowNo)"
											style="width: 100%"
										>
											<a-button
												block
												:disabled="
													isDisabledBtn(
														item.flowNo,
														'TICKET_INSPECTION_TEAM'
													)
												"
											>
												下一步
											</a-button>
										</a-popconfirm>
									</a-timeline-item>
								</a-timeline>
							</template>

							<template v-if="item.flowNo === 'VOTING_COUNTING'">
								<!-- 唱票、计票 -->
								<a-timeline>
									<a-timeline-item>
										<a-button block @click="uploadVoteHandle">
											上传表决票
										</a-button>
									</a-timeline-item>
									<a-timeline-item>
										<a-button block @click="bigScreenVotingHandle(`/conferenceWorkbench/bigScreenVoting/${flowId}`)"> 大屏唱票 </a-button>
									</a-timeline-item>
									<a-timeline-item>
										<a-button block  @click="bigScreenVotingHandle(`/conferenceWorkbench/votingDetails/${flowId}`)"> 唱票详情 </a-button>
									</a-timeline-item>
									<a-timeline-item>
										<a-button block @click="bigScreenVotingHandle(`/conferenceWorkbench/votingStatistics/${flowId}`)"> 唱票统计 </a-button>
									</a-timeline-item>
									<a-timeline-item >
										<a-popconfirm
											:disabled="
												isDisabledBtn(
													'RECYCLING_VOTES',
													'TICKET_INSPECTION_TEAM'
												)
											"
											title="确认进行下一步?"
											ok-text="是"
											cancel-text="否"
											@confirm="completedNodeHandle(item.flowNo)"
											style="width: 100%"
										>
											<a-button
												block
												:disabled="
													isDisabledBtn(
														'RECYCLING_VOTES',
														'TICKET_INSPECTION_TEAM'
													)
												"
											>
												下一步
											</a-button>
										</a-popconfirm>
									</a-timeline-item>
								</a-timeline>
							</template>

							<template
								v-if="
									item.flowNo ===
									'ANNOUNCEMENT_OF_MEETING_RESULTS'
								"
							>
								<!-- 会议结果公告 -->
								<a-timeline>
									<a-timeline-item>
										<a-button
											block
											@click="
												showAdditionalInformationModal(
													'RESULTS_ANNOUNCEMENT',
													item.flowNo
												)
											"
											:type="
												isDisabledBtn(
													item.flowNo,
													'RESULTS_ANNOUNCEMENT'
												)
													? null
													: 'primary'
											"
										>
											生成结果公告
										</a-button>
									</a-timeline-item>
									<a-timeline-item>
										<a-button
											block
											:disabled="
												isDisabledBtn(
													item.flowNo,
													'RESULTS_ANNOUNCEMENT'
												)
											"
										>
											推送电子结果公告
										</a-button>
									</a-timeline-item>
									<a-timeline-item>
										<a-button
											block
											@click="
												downRelatedDocuments(
													item.flowNo,
													'RESULTS_ANNOUNCEMENT'
												)
											"
											:disabled="
												isDisabledBtn(
													item.flowNo,
													'RESULTS_ANNOUNCEMENT'
												)
											"
										>
											下载会议结果公告
										</a-button>
									</a-timeline-item>
									<a-timeline-item>
										<a-button
											@click="showAMapModel(item.flowNo)"
											block
											:disabled="
												isDisabledBtn(
													item.flowNo,
													'RESULTS_ANNOUNCEMENT'
												)
											"
										>
											公告信息公开
										</a-button>
									</a-timeline-item>
								</a-timeline>
							</template>
						</div>
					</a-step>
				</a-steps>
			</div>

			<div class="gen-block other-content" v-if="currentNode === 'MEETING_ANNOUNCEMENT'">
				<a-form :form="announcementForm" :label-col="{ span: 12 }" :wrapper-col="{ span: 12 }">
					<a-row>
						<a-col :span="7">
							
							<a-form-item label="会议公告开始时间">
								
								<a-date-picker
									show-time
									placeholder="会议公告开始时间"
									v-decorator="['announcementStartTime', { rules: [{ required: true, message: '请输入必填项!' }] }]"
								/>
								<span class="tips-text">
								提示：请在生成公示7天后，创建公告！
								</span>
							</a-form-item>
							
						</a-col>
						<a-col :span="7">
							<a-form-item label="表决票送达截止时间">
								<a-date-picker
									show-time
									placeholder="表决票送达截止时间"
									v-decorator="['deadlineTime', { rules: [{ required: true, message: '请输入必填项!' }] }]"
								/>
							</a-form-item>
						</a-col>
					</a-row>

					<h3>投票时间及地点</h3>
					<a-row>
					  <a-col :span="7">
						  <a-form-item label="线上投票开始时间">
						<a-date-picker
							show-time
							placeholder="线上投票开始时间"
							v-decorator="['onlineVotingStartTime', { rules: [{ required: true, message: '请输入必填项!' }] }]"
						/>
						  </a-form-item>
					  </a-col>
					  <a-col :span="7">
						  <a-form-item label="线上投票截止时间">
						  <a-date-picker
							show-time
							placeholder="线上投票截止时间"
							v-decorator="['onlineDeadlineForVoting', { rules: [{ required: true, message: '请输入必填项!' }] }]"
						/>
						  </a-form-item>
					  </a-col>
					</a-row>

					<a-row
					>
						<a-col :span="7">
							<a-form-item label="线下投票开始时间">
								<a-date-picker
									show-time
									placeholder="线下投票开始时间"
									v-decorator="[`offlineVotingStartTime`, { rules: [{ required: true, message: '请输入必填项!' }] }]"
								/>
							</a-form-item>
						</a-col>
						<a-col :span="7">
							<a-form-item label="线下投票截止时间">
								<a-date-picker
									show-time
									placeholder="线下投票截止时间"
									v-decorator="[`deadlineOfflineVoting`, { rules: [{ required: true, message: '请输入必填项!' }] }]"
								/>
							</a-form-item>
						</a-col>
					</a-row>

					<a-row
						v-for="(k) in announcementForm.getFieldValue('keys')"
						:key="k"
					>
						<a-col :span="7">
							<a-form-item label="线下投票地点">
								<a-input
									placeholder="线下投票地点"
									v-decorator="[`offlineAddress_${k}`, { rules: [{ required: true, message: '请输入必填项!' }] }]"
								/>
							</a-form-item>
						</a-col>
						<a-col :span="2" style="text-align: center;">
							<a
								v-if="
									announcementForm.getFieldValue('keys')
										.length > 1
								"
								:disabled="
									announcementForm.getFieldValue('keys')
										.length === 1
								"
								@click="() => removeVotingTimePlace(k)"
								>删除</a
							>
						</a-col>
					</a-row>
					<a-form-item>
						<a-button
							type="dashed"
							style="width: 100%"
							@click="addVotingTimePlace"
						>
							添加选项
						</a-button>
					</a-form-item>

					<h3>唱票、计票时间及地点</h3>
					<a-row>
						<a-col :span="7">
							<a-form-item label="唱票、计票时间">
								<a-date-picker
									show-time
									placeholder="唱票、计票时间"
									v-decorator="['countingTime', { rules: [{ required: true, message: '请输入必填项!' }] }]"
								/>
								<span class="tips-text">
									提示：请在生成公告15天后，唱票、计票！
								</span>
							</a-form-item>
						</a-col>
						<a-col :span="7">
							<a-form-item label="唱票、计票地点">
								<a-input
									show-time
									placeholder="唱票、计票地点"
									v-decorator="['countingAddress', { rules: [{ required: true, message: '请输入必填项!' }] }]"
								/>
							</a-form-item>
						</a-col>
					</a-row>
					<a-form-item label="计票规则" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
						<a-radio-group
							v-decorator="['countingRules', { rules: [{ required: true, message: '请输入必填项!' }] }]"
							:options="[{label: '纸质票和电子票冲突、以纸质票为准', value: 1}, {label: '纸质票和电子票冲突、以电子票为准', value: 2}]"
						/>
							
						<!-- :options="[{label: '纸质票和电子票冲突、以纸质票为准', value: 1}, {label: '纸质票和电子票冲突、以电子票为准', value: 2}, {label: '视为无效票', value: 3}]" -->
					</a-form-item>
					<a-form-item label="废票视为" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
						<a-radio-group
							v-decorator="['deemedInvalid', { rules: [{ required: true, message: '请输入必填项!' }] }]"
							:options="[
								{label: '视为弃权票', value: 4},
								{label: '视为无效票', value: 5},
							]"
						/>
					</a-form-item>
				</a-form>
			</div>

			<div class="gen-block other-content">
				<a-table
					bordered
					:columns="columns"
					:data-source="otherFilesData"
					:title="() => '相关文件、备注信息'"
					class="components-table-demo-nested"
				>
					<span slot="logEntry" slot-scope="text, record">
						{{text.length}}
					</span>
					<div slot="operation" slot-scope="text, record">
						<a @click="editRemark(record)">新增备注</a>
					</div>
					<a-table
						slot="expandedRowRender"
						slot-scope="record, index, indent, expanded"
						:columns="innerColumns"
						:data-source="record.logEntry"
						:pagination="false"
					>
						<span slot="fileName" slot-scope="text, record">
							<a v-for="(fileItem, fileIndex) of record.fileList" :key="fileIndex" target="_blank" :href="fileItem.url">
								{{fileItem.name}}
								<br>
							</a>
						</span>
						<span slot="createAt" slot-scope="text">
							{{moment(text).format("YYYY-MM-DD HH:mm:ss")}}
						</span>
							
					</a-table>
				</a-table>
			</div>
		</a-spin>
		</a-spin>

			<a-modal
				title="公示信息公开"
				:visible="aMapModelVisible"
				@ok="aMapModelVisible = false"
				@cancel="aMapModelVisible = false"
				width="900px"
			>
				<div class="self-publicities-block">
					<div @click="previousMapHandle" class="pre-next-block">
						<a-icon type="left-circle" theme="twoTone" />
					</div>
					<div style="flex: 1">
						<div v-for="(item, index) of publicities" :key="index" v-show="activePublicitiesKey === index">
							<div class="self-map-container" :id="'container_' + index"></div>
							<div class="aMapModel-img-content">
								<PreviewImg :fileList="item.fileInfo.fileList"></PreviewImg>
							</div>
							<div class="aMapModel-img-content">
								创建人：{{item.createBy}}<br>
								创建时间：{{moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss")}}<br>
								查看进度：{{activePublicitiesKey + 1}} / {{publicities.length}}
							</div>
						</div>
					</div>
					<div @click="nextMapHandle" class="pre-next-block">
						<a-icon type="right-circle" theme="twoTone" />
					</div>
				</div>
			</a-modal>

			<a-modal
				title="编辑备注"
				:visible="remarkVisible"
				@ok="zmodalRemarkHandleOk"
				@cancel="zmodalRemarkHandleCancel"
			>
				<zform
					:formConfig="remarkFormConfig"
					:formData="{}"
					ref="modal-form"
				></zform>
			</a-modal>

			<a-modal
				title="公示修改记录"
				:visible="CONFERENCE_ANNOUNCEMENTHisVisible"
				@ok="CONFERENCE_ANNOUNCEMENTHisVisible = false"
				@cancel="CONFERENCE_ANNOUNCEMENTHisVisible = false"
			>
				<a-timeline v-if="flowDetail.relatedInformationHis">
					<a-timeline-item v-for="(item, index) of flowDetail.relatedInformationHis" :key="index">
						<p>修改时间：{{ moment(item.createdTime).format("YYYY-MM-DD HH:mm:ss")}}</p>
						<p>修改人：{{item.createdByName}}</p>
						<p>表决项：
							<p v-for="(cotingMattersItem, cotingMattersIndex) of  item.cotingMatters" :key="cotingMattersIndex">
								{{cotingMattersIndex +1}}: {{cotingMattersItem.votingMatters}}  && {{votingFormMapping[cotingMattersItem.votingForm]}}
							</p>
						</p>
						<p>会议主题：{{item.theme}}</p>
						<p>联系人：{{item.contactDetails}}</p>
						<p>联系地址：{{item.contactAddress}}</p>
						<p>参会人员：{{formateParticipants(item.participants)}}</p>
					</a-timeline-item>
				</a-timeline>
			</a-modal>

		<a-modal
			title="请输入补充信息"
			:visible="additionalInformationVisible"
			@ok="additionalInformationHandleOk"
			@cancel="additionalInformationVisible = false"
			:destroyOnClose="true"
		>
			<zform
				:formConfig="additionalInformationFormConfig"
				:formData="{}"
				ref="additionalInformationFormRes"
			></zform>
		</a-modal>

		<SigningSituationManageModal :flowDetail="flowDetail" :flowId="flowId" v-model="signingSituationManageVisible"></SigningSituationManageModal>
		<UploadVoteModal :flowId="flowId" v-model="uploadVoteModalVisible"></UploadVoteModal>
		<MakeUpVote :flowId="flowId" v-model="makeUpVoteVisible"></MakeUpVote>
	</div>
</template>

<script>
import {
	getFlowTypeNode,
	getOtherFiles,
	updateOtherFiles,
	updateRelatedDocuments,
	getDetail,
	completedNodeFn,
} from '@/api/flow'
import PreviewImg from '@/components/previewImg.vue'
import SigningSituationManageModal from '@/components/signingSituationManageModal.vue'
import UploadVoteModal from '@/components/uploadVoteModal.vue'
import MakeUpVote from '@/components/makeUpVote.vue'
import moment from 'moment'

const columns = [
	{ title: '会议流程', dataIndex: 'flowName', key: 'flowName' },
	{
		title: '记录条目',
		dataIndex: 'logEntry',
		scopedSlots: { customRender: 'logEntry' },
	},

	{
		title: '编辑',
		key: 'operation',
		scopedSlots: { customRender: 'operation' },
	},
]

const innerColumns = [
	{
		title: '文档名称',
		dataIndex: 'fileName',
		scopedSlots: { customRender: 'fileName' },
	},
	{ title: '备注', dataIndex: 'remark', key: 'remark' },
	{ title: '创建人', dataIndex: 'createBy', key: 'createBy' },
	{
		title: '创建时间',
		dataIndex: 'createAt',
		scopedSlots: { customRender: 'createAt' },
	},
]
let announcementFormId = 1

export default {
	data() {
		return {
			userInfo: null,
			activePublicitiesKey: 0,
			publicities: [],
			makeUpVoteVisible: false,
			uploadVoteModalVisible: false,
			spinningLoading: false,
			signingSituationManageVisible: false,
			announcementForm: this.$form.createForm(this, {
				name: 'announcementForm',
			}),
			additionalInformationVisible: false,
			additionalInformationFormConfig: null, // 模版文件中，有一些需要人填的字段，
			votingFormMapping: {
				1: '同意/反对/弃权',
				2: '同意/反对',
			},
			CONFERENCE_ANNOUNCEMENTHisVisible: false,
			remarkVisible: false,
			spinning: false,
			columns,
			innerColumns,
			aMapModelVisible: false,
			otherFilesData: [],
			routes: [
				{
					path: '/conferenceWorkbench/list',
					breadcrumbName: '会议工作台',
				},
				{
					path: '/conferenceWorkbench/detail',
					breadcrumbName: '会议详情',
				},
			],
			aMapModelFileList: [],
			nodes: [],
			nodeKey2Name: {},
			flowId: null,
			flowDetail: {},
			remarkFormConfig: {
				formLayout: 'horizontal',
				col: 24,
				fixedLabelWidth: '80px',
				columns: [
					{
						rows: 8,
						label: '备注',
						props: 'remark',
						type: 'textarea',
					},
					{
						col: 24,
						props: 'fileList',
						type: 'oss-upload',
						label: '相关文件',
						maxUpload: 10,
						listType: 'picture-card',
						rules: [],
					},
				],
			},
			remarkFormEditingRecord: null,
		}
	},
	async created() {
		try {
			const localUserInfo = localStorage.getItem('userInfo')
			this.userInfo = JSON.parse(localUserInfo)
			console.log('asdadas', this.userInfo);
		} catch (error) {
			console.log(error)
		}

		const {
			params: { id },
		} = this.$route
		this.flowId = id
		let nodeKey2Name = {}
		try {
			// 获取流程node  拼接数据，方便后面使用
			this.nodes = await getFlowTypeNode({ type: 1 })
			this.nodes.forEach((item) => {
				nodeKey2Name[item.flowNo] = item
			})
			this.nodeKey2Name = nodeKey2Name

			// 获取流程详情
			this.getFlowDetail(id)

			// 获取 相关文件、备注信息
			this.getOtherFilesMethods()
		} catch (error) {
			console.log(error)
		}
	},
	components: {
		PreviewImg,
		SigningSituationManageModal,
		UploadVoteModal,
		MakeUpVote,
	},
	computed: {
		relatedDocuments() {
			let relatedDocuments = this.flowDetail.relatedDocuments
			if (
				!relatedDocuments ||
				Object.keys(relatedDocuments).length === 0
			) {
				return null
			}
			return relatedDocuments
		},
		currentNode() {
			const { completedNode } = this.flowDetail
			if (!completedNode) {
				return null
			}
			return completedNode[completedNode.length - 1]
		},
	},
	methods: {
		moment,
		formateParticipants(participants) {
			console.log('participants', participants)
			if (!participants) {
				return '-'
			}
			let res = []
			participants.forEach((item) => {
				res.push(item.label)
			})
			return res.join('、')
		},
		async getOtherFilesMethods() {
			// 获取 相关文件、备注信息
			const otherFilesRes = await getOtherFiles(this.flowId)
			let otherFilesData = []
			for (const key in otherFilesRes) {
				const element = otherFilesRes[key]
				otherFilesData.push({
					key,
					flowName: this.nodeKey2Name[key].flowName,
					logEntry: element,
				})
			}
			this.otherFilesData = otherFilesData
		},
		bigScreenVotingHandle(url) {
			const res = this.judgmentTime('VOTING_COUNTING')
			if (res) {
				this.$router.push(url)
			}
		},
		bigScreenVotingHandle1(url) {
			this.$router.push(url)
		},
		uploadVoteHandle() {
			if (this.currentNode !== 'VOTING_COUNTING') {
				this.$message.warning('未到唱票、计票节点')
				return
			}
			const res = this.judgmentTime('VOTING_COUNTING')
			if (res) {
				this.uploadVoteModalVisible = true
			}
			// FIX ME
			// this.uploadVoteModalVisible = true
		},
		signingSituationManageHandle() {
			this.signingSituationManageVisible = true
		},
		checkAnnouncementForm(fileCode, flowNo) {
			this.announcementForm.validateFieldsAndScroll((errors, values) => {
				if (errors) {
					this.$message.error('请输入必填项!')
					console.log('err', errors)
					return
				}
				this.showAdditionalInformationModal(fileCode, flowNo, values)
			})
		},
		removeVotingTimePlace(k) {
			const { announcementForm } = this
			// can use data-binding to get
			const keys = announcementForm.getFieldValue('keys')
			// We need at least one passenger
			if (keys.length === 1) {
				return
			}

			// can use data-binding to set
			announcementForm.setFieldsValue({
				keys: keys.filter((key) => key !== k),
			})
		},
		addVotingTimePlace() {
			const { announcementForm } = this
			// can use data-binding to get
			const keys = announcementForm.getFieldValue('keys')
			const nextKeys = keys.concat(announcementFormId++)
			// can use data-binding to set
			// important! notify announcementForm to detect changes
			announcementForm.setFieldsValue({
				keys: nextKeys,
			})
		},
		getSubTitle(flowNo) {
			let relatedDocuments = this.flowDetail.relatedDocuments
			if (!relatedDocuments) {
				return '暂未生成'
			}
			const relatedDocument = relatedDocuments[flowNo]
			if (!relatedDocument) {
				return '暂未生成'
			}
			let relatedDocumentKey = null
			switch (flowNo) {
				case 'CONFERENCE_ANNOUNCEMENT':
					relatedDocumentKey = 'CONFERENCE_ANNOUNCEMENT'
					break
				case 'MEETING_ANNOUNCEMENT':
					relatedDocumentKey = 'MEETING_ANNOUNCEMENT'
					break
				case 'SERVE_A_VOTE':
					relatedDocumentKey = 'VOTE'
					break
				case 'RECYCLING_VOTES':
					relatedDocumentKey = 'TICKET_INSPECTION_TEAM'
					break
				case 'ANNOUNCEMENT_OF_MEETING_RESULTS':
					relatedDocumentKey = 'RESULTS_ANNOUNCEMENT'
					break
				default:
					break
			}

			let time = relatedDocument[relatedDocumentKey]
				? relatedDocument[relatedDocumentKey].createdTime
				: null

			return time
				? moment(time).format('YYYY-MM-DD HH:mm:ss')
				: '暂未生成'
		},
		showCONFERENCE_ANNOUNCEMENTHis() {
			this.CONFERENCE_ANNOUNCEMENTHisVisible = true
		},
		editCONFERENCE_ANNOUNCEMENT() {
			this.$router.push(`/conferenceWorkbench/add?flowId=${this.flowId}`)
		},
		editRemark(record) {
			this.remarkVisible = true
			this.remarkFormEditingRecord = record
		},
		zmodalRemarkHandleOk() {
			if (!this.remarkFormEditingRecord) {
				return
			}
			const { key } = this.remarkFormEditingRecord
			this.$refs['modal-form']
				.zformValidateFieldsAndScroll()
				.then((values) => {
					updateOtherFiles(this.flowId, { fileCode: key, ...values })
						.then((res) => {
							this.getOtherFilesMethods()
							this.zmodalRemarkHandleCancel()
							this.$message.success('更新成功！')
						})
						.catch((err) => {
							this.$message.warning('更新失败！')
						})
				})
		},
		zmodalRemarkHandleCancel() {
			this.remarkVisible = false
			this.remarkFormEditingRecord = false
		},
		isDisabledBtn(flowNo, fileCode) {
			const { relatedDocuments } = this
			return (
				!relatedDocuments ||
				!relatedDocuments[flowNo] ||
				!relatedDocuments[flowNo][fileCode]
			)
		},
		downRelatedDocuments(flowNo, fileCode) {
			const { relatedDocuments } = this
			if (
				!relatedDocuments ||
				!relatedDocuments[flowNo] ||
				!relatedDocuments[flowNo][fileCode]
			) {
				return
			}
			window.open(relatedDocuments[flowNo][fileCode].fileUrl)
		},
		async getFlowDetail(id) {
			try {
				const flowDetail = await getDetail(id)
				this.flowDetail = flowDetail || {}

				if (this.currentNode === 'MEETING_ANNOUNCEMENT') {
					// 会议公告阶段，显示表单
					this.announcementForm.getFieldDecorator('keys', {
						initialValue: [0],
						preserve: true,
					})
				}
			} catch (error) {
				console.log(error)
			}
		},
		showAdditionalInformationModal(fileCode, flowNo, others) {
			if (fileCode === 'CONFERENCE_ANNOUNCEMENT') {
				this.additionalInformationFormConfig = {
					fileCode,
					flowNo,
					others,
					formLayout: 'horizontal',
					col: '24',
					fixedLabelWidth: '80px',
					columns: [
						{
							label: '反映建议截至天数',
							props: 'days',
							type: 'input',
						},
						{
							label: '反映意见投递地址',
							props: 'address',
							type: 'input',
						},
						{
							label: '自定义陈述',
							props: 'customStatement',
							type: 'textarea',
							rows: 5,
						},
						{
							label: '抄送街道',
							props: 'CCStreet',
							type: 'input',
						},
						{
							label: '抄送社区',
							props: 'CCCommunity',
							type: 'input',
						},
						{
							label: '附件二维码',
							props: 'attachmentQRCode',
							type: 'oss-upload',
							listType: 'picture-card',
							maxUpload: 4,
						},
					],
				}
			}
			if (fileCode === 'MEETING_ANNOUNCEMENT') {
				this.additionalInformationFormConfig = {
					fileCode,
					flowNo,
					others,
					formLayout: 'horizontal',
					col: '24',
					fixedLabelWidth: '80px',
					columns: [
						{
							label: '业主⼤会会议表决规则',
							props: 'meetingVotingRules',
							type: 'textarea',
							rows: 5,
						},
						{
							label: '抄送街道',
							props: 'CCStreet',
							type: 'input',
						},
						{
							label: '抄送社区',
							props: 'CCCommunity',
							type: 'input',
						},
						{
							label: '附件二维码',
							props: 'attachmentQRCode',
							type: 'oss-upload',
							listType: 'picture-card',
							maxUpload: 4,
						},
					],
				}
			}

			if (fileCode === 'NON_PERSONAL_SERVICE') {
				this.additionalInformationFormConfig = {
					fileCode,
					flowNo,
					others,
					formLayout: 'horizontal',
					col: '24',
					fixedLabelWidth: '80px',
					columns: [
						{
							label: '"其他"条件视为',
							props: 'otherRegarded',
							type: 'input',
						},
						{
							label: '抄送街道',
							props: 'CCStreet',
							type: 'input',
						},
						{
							label: '抄送社区',
							props: 'CCCommunity',
							type: 'input',
						},
					],
				}
			}

			if (fileCode === 'TICKET_INSPECTION_TEAM') {
				this.additionalInformationFormConfig = {
					fileCode,
					flowNo,
					others,
					formLayout: 'horizontal',
					col: '24',
					fixedLabelWidth: '80px',
					columns: [
						{
							label: '公告内容',
							props: 'announcementContent',
							type: 'textarea',
							rows: 5,
						},
						{
							label: '计票人',
							props: 'jpTeller',
							type: 'input',
						},
						{
							label: '唱票人',
							props: 'cpTeller',
							type: 'input',
						},
						{
							label: '监票人',
							props: 'scrutineer',
							type: 'input',
						},
						{
							label: '其他见证人和单位',
							props: 'otherWitnesses',
							type: 'input',
						},
						{
							label: '抄送街道',
							props: 'CCStreet',
							type: 'input',
						},
						{
							label: '抄送社区',
							props: 'CCCommunity',
							type: 'input',
						},
					],
				}
			}

			if (fileCode === 'RESULTS_ANNOUNCEMENT') {
				this.additionalInformationFormConfig = {
					fileCode,
					flowNo,
					others,
					formLayout: 'horizontal',
					col: '24',
					fixedLabelWidth: '80px',
					columns: [
						{
							label: '抄送街道',
							props: 'CCStreet',
							type: 'input',
						},
						{
							label: '抄送社区',
							props: 'CCCommunity',
							type: 'input',
						},
					],
				}
			}

			this.additionalInformationVisible = true
		},
		additionalInformationHandleOk() {
			this.$refs['additionalInformationFormRes']
				.zformValidateFieldsAndScroll()
				.then((values) => {
					const {
						fileCode,
						flowNo,
						others,
					} = this.additionalInformationFormConfig
					this.generatePDF(fileCode, flowNo, {
						...values,
						...(others || {}),
					})
					this.additionalInformationVisible = false
					this.additionalInformationFormConfig = null
				})
				.catch((err) => {
					console.error('err:', err)
				})
		},
		judgmentTime(flowNo) {
			// 1. 公示 7天 以后 才可以公告
			if (flowNo === 'MEETING_ANNOUNCEMENT') {
				let relatedDocuments = this.flowDetail.relatedDocuments
				if (!relatedDocuments) {
					this.$message.warning('请先生成公示！')
					return false
				}
				const relatedDocument =
					relatedDocuments['CONFERENCE_ANNOUNCEMENT']
				if (!relatedDocument) {
					this.$message.warning('请先生成公示！')
					return false
				}

				let time = relatedDocument['CONFERENCE_ANNOUNCEMENT']
					? relatedDocument['CONFERENCE_ANNOUNCEMENT'].createdTime
					: null
				if (!time) {
					this.$message.warning('请先生成公示！')
					return false
				}
				const allowDayTime = moment(time).add(7, 'd') //  公示 7天 以后 才可以公告
				let res = moment().isAfter(allowDayTime, 'second') // 判断当前时间是否在 创建公示 7 天以后
				if (!res) {
					this.$message.warning(
						`请在生成公示7天(${allowDayTime.format(
							'YYYY-MM-DD HH:mm:ss'
						)})后，创建公告！`,
						15
					)
					// FIX ME
					if (this.userInfo && this.userInfo._id === '606bbfb67f7cd900143837c5') {
						return true
					}
					return false
					// return true
				}
			}

			// 2. 公告+ 表决票样张 15天后 唱票、计票
			if (flowNo === 'VOTING_COUNTING') {
				let relatedDocuments = this.flowDetail.relatedDocuments
				if (!relatedDocuments) {
					this.$message.warning('请先生成公告！')
					return false
				}
				const relatedDocument = relatedDocuments['MEETING_ANNOUNCEMENT']
				if (!relatedDocument) {
					this.$message.warning('请先生成公告！')
					return false
				}

				let time = relatedDocument['MEETING_ANNOUNCEMENT']
					? relatedDocument['MEETING_ANNOUNCEMENT']
							.additionalInformation.announcementStartTime
					: null
				if (!time) {
					this.$message.warning('请先生成公告！')
					return false
				}
				const allowDayTime = moment(time).add(15, 'd') //  公示 7天 以后 才可以公告
				let res = moment().isAfter(allowDayTime, 'second') // 判断当前时间是否在 创建公示 7 天以后
				if (!res) {
					this.$message.warning(
						`请在公告开始15天(${allowDayTime.format(
							'YYYY-MM-DD HH:mm:ss'
						)})后，唱票、计票！`,
						15
					)
					// FIX ME
					if (this.userInfo && this.userInfo._id === '606bbfb67f7cd900143837c5') {
						return true
					}
					return false
					// return true
				}
			}
			return true
		},
		async completedNodeHandle(flowNo) {
			this.spinningLoading = true

			// const { relatedDocuments } = this.flowDetail
			// const { publicity } = relatedDocuments[flowNo]
			// if (!publicity) {
			// 	this.$message.error('暂未上传公示公告！')
			// 	this.spinningLoading = false
			// 	// return
			// }
			// 如果当前点击的按钮  是已完成按钮的下一个节点  才会触发完成操作
			const completedNode = this.flowDetail.completedNode
			let lastOneNode = completedNode[completedNode.length - 1];
			if (flowNo !== lastOneNode) {
				this.spinningLoading = false;
				return
			}
			

			try {
				await completedNodeFn(this.flowId)
				this.getFlowDetail(this.flowId)
			} catch (error) {
				console.log('error', error)
				this.spinningLoading = false
				return
			}
			this.spinningLoading = false
		},
		async generatePDF(fileCode, flowNo, additionalInformation) {
			this.spinning = true
			const judgmentTimeRes = this.judgmentTime(flowNo)
			if (!judgmentTimeRes) {
				this.spinning = false
				return
			}

			if (!fileCode) {
				this.spinning = false
				return
			}

			updateRelatedDocuments(this.flowId, fileCode, additionalInformation)
				.then((res) => {
					this.spinning = false

					const fileUrl = res.fileUrl
					if (!fileUrl) {
						this.$message.error('生成失败！')
						return
					}
					this.getFlowDetail(this.flowId)
					window.open(fileUrl, '_blank')
				})
				.catch((err) => {
					this.spinning = false

					console.log(err)
					this.$message.error('生成失败！')
				})
		},
		backHandle() {
			this.$router.replace('/conferenceWorkbench/list')
		},
		previousMapHandle() {
			if (this.activePublicitiesKey === 0) {
				this.activePublicitiesKey = this.publicities.length - 1
				return
			}
			this.activePublicitiesKey -= 1
		},
		nextMapHandle() {
			if (this.activePublicitiesKey >= this.publicities.length - 1) {
				this.activePublicitiesKey = 0
				return
			}
			this.activePublicitiesKey += 1

		},
		async showAMapModel(flowNo) {
			const { relatedDocuments } = this.flowDetail
			const { publicity } = relatedDocuments[flowNo]
			this.publicities = []
			this.activePublicitiesKey = 0
			if (!publicity || publicity.length === 0) {
				this.$message.error('暂未上传公示公告！')
				return
			}
			this.aMapModelVisible = true
			await this.$nextTick()

			this.publicities = publicity
			publicity.forEach((item, index) => {
				AMap.convertFrom(
					item.fileInfo.latitudeLongitude,
					'gps',
					function (status, result) {
						if (result.info === 'ok') {
							let lnglats = result.locations // Array.<LngLat>
							let map = new AMap.Map('container_' + index, {
								resizeEnable: true, //是否监控地图容器尺寸变化
								zoom: 15, //初始化地图层级
								center: [lnglats[0].R, lnglats[0].Q], //初始化地图中心点
							})
							let marker = new AMap.Marker({
								position: [lnglats[0].R, lnglats[0].Q], //初始化地图中心点
							})
							map.add(marker) //添加到地图
						}
					}
				)
			})
		},
	},
}
</script>

<style lang="scss">
.tips-text {
	line-height: 16px;
	display: inline-block;
	color: #e6a82e;
}
.spin-content {
	border: 1px solid #91d5ff;
	background-color: #e6f7ff;
	padding: 30px;
}
.cw-detail-content {
	.title-content {
	}
	.bread-crumbs {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.ant-steps-finish-icon {
		vertical-align: 0.05em;
	}
	.steps-content {
		overflow-x: auto;
		.ant-steps-item-container {
			width: 250px;
		}
		.sc-description {
			margin-top: 20px;
			width: 190px;
		}
		.step-item-sub-item {
			height: 40px;
			overflow: hidden;
		}
	}
	.other-content {
		.action-btn {
			text-align: right;
			margin-bottom: 8px;
		}
	}
}

.self-publicities-block {
	display: flex;
	align-items: center;
	.self-map-container {
		width: 100%;
		height: 300px;
	}
	.aMapModel-img-content {
		margin-top: 16px;
	}
	.pre-next-block{
		font-size: 22px;
    	cursor: pointer;
		width: 50px;
		text-align: center;
	}
}
.custom-slick-arrow {
	z-index: 9999 !important;
	background: red !important;
}
.slick-dots {
	li {
		background: #2140d9;
	}
}


</style>
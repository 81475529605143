<template>
	<div class="property-manager-page">
		<div v-if="dataList && dataList.length === 0">
			<a-empty>
				<span slot="description">
					暂无物业服务企业信息
					<a href="javascript:void(0)" @click="handleAdd">现在添加</a>
				</span>
			</a-empty>
		</div>
		<div v-if="dataList && dataList.length > 0">
			<div class="ywh-desc">
				<a-row>
					<a-col :span="4">
						<div class="ywh-desc-header">
							<div class="title">合同主体</div>
						</div>
						<div class="ywh-desc-content">
							<div class="desc-item">
								<span class="label">甲方：</span>
								<span>{{
									dataList[0].contractSubject.partyA
								}}</span>
							</div>
							<div class="desc-item">
								<span class="label">业主委员会主任：</span>
								<span>{{
									dataList[0].contractSubject.UOCCommittee
								}}</span>
							</div>
							<div class="desc-item">
								<span class="label">乙方：</span>
								<span>{{
									dataList[0].contractSubject.partyB
								}}</span>
							</div>
							<div class="desc-item">
								<span class="label">法定代表人：</span>
								<span>{{
									dataList[0].contractSubject.corporation
								}}</span>
							</div>
							<div class="desc-item">
								<span class="label">物业名称：</span>
								<span>{{
									dataList[0].contractSubject.propertyName
								}}</span>
							</div>
							<div class="desc-item">
								<span class="label">签订日期：</span>
								<span>{{
									dataList[0].contractSubject.signingDate
								}}</span>
							</div>
							<div class="mt40 status">
								状态：<a href="javascript:void(0)">已归档</a>
							</div>
						</div>
					</a-col>
					<a-col :span="4">
						<div class="ywh-desc-header">
							<div class="title">合同期限</div>
						</div>
						<div class="ywh-desc-content" >
							<div class="desc-item">
								<span class="label">合同年限：</span>
								<span>{{
									dataList && dataList[0].contractPeriod.year
								}}</span>
							</div>
							<div class="desc-item" style="height: auto">
								<span class="label">合同期限范围：</span>
								<p>
									{{
										dataList[0].contractPeriod.termRange && dataList[0].contractPeriod.termRange[0]
									}}至{{
										dataList[0].contractPeriod.termRange && dataList[0].contractPeriod.termRange[1]
									}}
								</p>
							</div>
						</div>
					</a-col>
					<a-col :span="9">
						<div class="ywh-desc-header">
							<div class="title">物业管理费用</div>
						</div>
						<div class="ywh-desc-content">
							<div class="desc-item">
								<span class="label">高层一楼：</span>
								<span
									>{{
										dataList[0].propertyManagementFees
											.highLevel
									}}元/平方米/月</span
								>
								<span class="label" style="margin-left: 12px"
									>收费面积：</span
								>
								<span
									>{{
										dataList[0].propertyManagementFees
											.highLevelChargeableArea
									}}平方米</span
								>
							</div>
							<div class="desc-item">
								<span class="label">高层二楼及以上：</span>
								<span
									>{{
										dataList[0].propertyManagementFees
											.highLevel2
									}}元/平方米/月</span
								>
								<span class="label" style="margin-left: 12px"
									>收费面积：</span
								>
								<span
									>{{
										dataList[0].propertyManagementFees
											.highLevelChargeableArea2
									}}平方米</span
								>
							</div>
							<div class="desc-item">
								<span class="label">多层：</span>
								<span
									>{{
										dataList[0].propertyManagementFees
											.multilayer
									}}元/平方米/月</span
								>
								<span class="label" style="margin-left: 12px"
									>收费面积：</span
								>
								<span
									>{{
										dataList[0].propertyManagementFees
											.multilayerChargeableArea
									}}平方米</span
								>
							</div>
							<div class="desc-item">
								<span class="label">联排地上：</span>
								<span
									>{{
										dataList[0].propertyManagementFees
											.townhouse
									}}元/平方米/月</span
								>
								<span class="label" style="margin-left: 12px"
									>收费面积：</span
								>
								<span
									>{{
										dataList[0].propertyManagementFees
											.townhouseChargeableArea
									}}平方米</span
								>
							</div>
							<div class="desc-item">
								<span class="label">联排地下：</span>
								<span
									>{{
										dataList[0].propertyManagementFees
											.townhouse2
									}}元/平方米/月</span
								>
								<span class="label" style="margin-left: 12px"
									>收费面积：</span
								>
								<span
									>{{
										dataList[0].propertyManagementFees
											.townhouseChargeableArea2
									}}平方米</span
								>
							</div>
							<div class="desc-item">
								<span class="label">独栋别墅地上：</span>
								<span
									>{{
										dataList[0].propertyManagementFees
											.singleFamily
									}}元/平方米/月</span
								>
								<span class="label" style="margin-left: 12px"
									>收费面积：</span
								>
								<span
									>{{
										dataList[0].propertyManagementFees
											.singleFamilyChargeableArea
									}}平方米</span
								>
							</div>
							<div class="desc-item">
								<span class="label">独栋别墅地下：</span>
								<span
									>{{
										dataList[0].propertyManagementFees
											.singleFamily2
									}}元/平方米/月</span
								>
								<span class="label" style="margin-left: 12px"
									>收费面积：</span
								>
								<span
									>{{
										dataList[0].propertyManagementFees
											.singleFamilyChargeableArea2
									}}平方米</span
								>
							</div>
							<div class="desc-item">
								<span class="label">办公楼：</span>
								<span
									>{{
										dataList[0].propertyManagementFees
											.officeBuilding
									}}元/平方米/月</span
								>
								<span class="label" style="margin-left: 12px"
									>收费面积：</span
								>
								<span
									>{{
										dataList[0].propertyManagementFees
											.officeBuildingChargeableArea
									}}平方米</span
								>
							</div>
							<div class="desc-item">
								<span class="label">商业物业：</span>
								<span
									>{{
										dataList[0].propertyManagementFees
											.commercialProperty
									}}元/平方米/月</span
								>
								<span class="label" style="margin-left: 12px"
									>收费面积：</span
								>
								<span
									>{{
										dataList[0].propertyManagementFees
											.commercialPropertyChargeableArea
									}}平方米</span
								>
							</div>
							<div class="desc-item">
								<span class="label">其他：</span>
								<span
									>{{
										dataList[0].propertyManagementFees
											.other
									}}元/平方米/月</span
								>
								<span class="label" style="margin-left: 12px"
									>收费面积：</span
								>
								<span
									>{{
										dataList[0].propertyManagementFees
											.otherChargeableArea
									}}平方米</span
								>
							</div>
							<div class="desc-item">
								<span class="label"
									>本物业管理区域每年物业管理费总收入约：</span
								>
								<span
									>{{
										dataList[0].propertyManagementFees
											.totalRevenue
									}}元</span
								>
							</div>
							<div class="desc-item">
								<span class="label">总可收费面积：</span>
								<span
									>{{
										dataList[0].propertyManagementFees
											.totalArea
									}}平方米</span
								>
							</div>
						</div>
					</a-col>
					<a-col :span="4">
						<div class="ywh-desc-header">
							<div class="title">物业服务企业信息</div>
						</div>
						<div class="ywh-desc-content">
							<div class="desc-item">
								<span class="label">物业服务企业名称：</span>
								<span>{{
									dataList[0]
										.propertyManagementRelatedLicenses
										.companyName
								}}</span>
							</div>
							<div class="desc-item">
								<span class="label">企业办公地址：</span>
								<span>{{
									dataList[0]
										.propertyManagementRelatedLicenses
										.companyAddress
								}}</span>
							</div>
							<div class="desc-item">
								<span class="label">企业联系电话：</span>
								<span>{{
									dataList[0]
										.propertyManagementRelatedLicenses
										.companyTel
								}}</span>
							</div>
						</div>
					</a-col>

					<a-col :span="12">
						<div class="ywh-desc-header">
							<div class="title">物业服务企业信相关证照</div>
						</div>
						<div class="ywh-desc-content">
							<div class="desc-item">
								<div class="img-content">
									<div>
										<span class="label">营业执照：</span>
										<br />
										<a
											:href="
												dataList[0]
													.propertyManagementRelatedLicenses
													.businessLicense[0] && 
												dataList[0]
													.propertyManagementRelatedLicenses
													.businessLicense[0].url
											"
											target="_blank"
										>
											<img
												:src="
													dataList[0]
														.propertyManagementRelatedLicenses
														.businessLicense[0] && 
													dataList[0]
														.propertyManagementRelatedLicenses
														.businessLicense[0].url
												"
											/>
										</a>
									</div>
									<div>
										<span class="label">资质证书：</span>
										<br />
										<a
											:href="
												dataList[0]
													.propertyManagementRelatedLicenses
													.certifications[0] &&
												dataList[0]
													.propertyManagementRelatedLicenses
													.certifications[0].url
											"
											target="_blank"
										>
											<img
												:src="
													dataList[0]
														.propertyManagementRelatedLicenses
														.certifications[0] &&
													dataList[0]
														.propertyManagementRelatedLicenses
														.certifications[0].url
												"
											/>
										</a>
									</div>
									<div>
										<span class="label"
											>物业经理证照：</span
										>
										<br />
										<a
											:href="
												dataList[0]
													.propertyManagementRelatedLicenses
													.propertyManagerLicense[0] && 
												dataList[0]
													.propertyManagementRelatedLicenses
													.propertyManagerLicense[0]
													.url
											"
											target="_blank"
										>
											<img
												:src="
													dataList[0]
														.propertyManagementRelatedLicenses
														.propertyManagerLicense[0] && 
													dataList[0]
														.propertyManagementRelatedLicenses
														.propertyManagerLicense[0]
														.url
												"
											/>
										</a>
									</div>
								</div>
							</div>
						</div>
					</a-col>
					<a-col :span="12">
						<div class="ywh-desc-header">
							<div class="title">物业服务合同信息 PDF</div>
						</div>
						<div class="ywh-desc-content">
							<div class="desc-item">
								<div class="img-content">
									<div v-for="(item, index) of dataList[0].propertyServiceContractPDF" :key="index">
										<br />
										<a
											:href="
												item.url
											"
											target="_blank"
										>
											<img
												src="https://huhuiyun.oss-cn-shanghai.aliyuncs.com/static/ico_pdf.svg"
											/>
										</a>
									</div>
								</div>
							</div>
						</div>
					</a-col>

					<a-col :span="24">
						<div class="tl-right">
							<a-button
								type="primary"
								class="ywh-btn"
								@click="handleEdit(dataList[0])"
								>更新合同信息</a-button
							>
						</div>
					</a-col>
				</a-row>
			</div>
			<ztable2
				:detailColumns="contract_detail_columns"
				:columns="contract_table_columns"
				:dataSource="dataList"
				:pagination="false"
			>
				<span slot="relatedFiles" slot-scope="text, record">
					<a
						target="_blank"
						:href="record.relatedFiles.OAFile[0] && record.relatedFiles.OAFile[0].url"
						>{{ record.relatedFiles.OAFile[0] && record.relatedFiles.OAFile[0].name }}</a
					>
					<br />
					<a
						target="_blank"
						:href="record.relatedFiles.bidRecordingFile[0] && record.relatedFiles.bidRecordingFile[0].url"
						>{{ record.relatedFiles.bidRecordingFile[0] && record.relatedFiles.bidRecordingFile[0].name }}</a
					>
				</span>
				<span slot="action" slot-scope="text, record">
					<div class="editable-row-operations">
						<a
							href="javascript:void(0)"
							@click="handleEdit(record, true)"
							>查看详情
						</a>
					</div>
				</span>
			</ztable2>
		</div>

		<a-modal
			class="ywh-modal"
			:title="modalTitle"
			:visible="modalVisible"
			:confirm-loading="modalLoading"
			@ok="handleModalOk"
			@cancel="handleModalCancel"
			ok-text="确认"
			cancel-text="取消"
			:width="1000"
			:footer="showModalBtn"
		>
			<zform
				:formConfig="contract_form_config"
				:formData="{}"
				ref="modal-form"
			>
			</zform>
		</a-modal>
	</div>
</template>

<script>
import tableMixin from '@/mixin/table.list.js'
import mixin from './mixin.js'
import { getPropertyContractList } from '@/api/community/property'
export default {
	data() {
		return {
			dataList: [],
		}
	},
	mixins: [tableMixin, mixin],
	methods: {
		getList() {
			getPropertyContractList().then((res) => {
				this.dataList = res.list.sort((a,b) => b.status - a.status)
			})
		},
		// 弹框提交接口api和params处理
		submitRequestHandle(formValues) {
			console.log('formValues', formValues)
			let relatedFiles = {
				bidRecordingFile: formValues.bidRecordingFile,
				OAFile: formValues.OAFile,
			}
			delete formValues.bidRecordingFile
			delete formValues.OAFile

			let propertyManagementRelatedLicenses = {
				companyName: formValues.companyName,
				companyTel: formValues.companyTel,
				companyAddress: formValues.companyAddress,
				businessLicense: formValues.businessLicense,
				certifications: formValues.certifications,
				propertyManagerLicense: formValues.propertyManagerLicense,
			}
			delete formValues.companyName
			delete formValues.companyTel
			delete formValues.companyAddress
			delete formValues.businessLicense
			delete formValues.certifications
			delete formValues.propertyManagerLicense

			this.editId = null

			return {
				// 保存和更新接口
				postApi: `/api/PSContract`,
				// 表单外的提交参数
				params: {
					relatedFiles,
					propertyManagementRelatedLicenses,
				},
			}
		},
		// 列表删除api处理
		deleteRequestHandle(recordId) {
			return {
				// 删除接口
				deleteApi: `/api/PSContract`,
			}
		},
		// 编辑之前钩子函数，返回新的record
		beforeEdit(record) {
			record.bidRecordingFile = record.relatedFiles.bidRecordingFile
			record.OAFile = record.relatedFiles.OAFile

			record.companyName =
				record.propertyManagementRelatedLicenses.companyName
			record.companyTel =
				record.propertyManagementRelatedLicenses.companyTel
			record.companyAddress =
				record.propertyManagementRelatedLicenses.companyAddress
			record.businessLicense =
				record.propertyManagementRelatedLicenses.businessLicense
			record.certifications =
				record.propertyManagementRelatedLicenses.certifications
			record.propertyManagerLicense =
				record.propertyManagementRelatedLicenses.propertyManagerLicense

			console.log(record)
			return record
		},
	},
	created() {
		// 获取页面数据
		this.getList()
	},
	components: {},
}
</script>

<style lang="scss" scoped></style>

export default {
  data() {
    return {
      manager_detail_columns: [
        {
          props: "status",
          type: "link",
          mapping: [
            {
              text: "已归档",
              value: 1,
            },
            {
              text: "过期",
              value: 0,
            },
          ],
        },
      ],
      manager_table_columns: [
        {
          dataIndex: "companyName",
          title: "物业服务企业名称",
        },
        {
          dataIndex: "companyTel",
          title: "企业联系电话",
        },
        {
          dataIndex: "companyAddress",
          title: "企业办公地址",
        },
        {
          dataIndex: "status",
          title: "状态",
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 130,
        },
      ],
      form_config: {
        col: 24,
        formLayout: "vertical",
        textAlign: "left",
        columns: [
          {
            type: "input",
            props: "companyName",
            label: "物业服务企业名称",
            placeholder: "请输入",
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            type: "input",
            props: "companyTel",
            label: "企业联系电话",
            placeholder: "请输入",
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            type: "input",
            props: "companyAddress",
            label: "企业办公地址",
            placeholder: "请输入",
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            props: "businessLicense",
            type: "oss-upload",
            label: "营业执照",
            maxUpload: 1,
            listType: "picture-card",
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            props: "certifications",
            type: "oss-upload",
            label: "资质证书",
            maxUpload: 20,
            listType: "picture-card",
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            props: "propertyManagerLicense",
            type: "oss-upload",
            label: "物业经理证照",
            maxUpload: 20,
            listType: "picture-card",
            rules: [
              {
                required: true,
              },
            ],
          },
        ],
      },
      contract_detail_columns: [
        {
          type: "group",
          dataIndex: "name",
          subColumns: [
            {
              type: "text",
              props: "contractSubject.propertyName",
            },
          ],
        },
        {
          type: "group",
          dataIndex: "signingDate",
          subColumns: [
            {
              type: "text",
              props: "contractSubject.signingDate",
            },
          ],
        },
        {
          type: "group",
          dataIndex: "termRange",
          subColumns: [
            {
              type: "text",
              props: "contractPeriod.termRange",
              splitFlagChar: " 至 ",
            },
          ],
        },
        {
          type: "group",
          dataIndex: "years",
          subColumns: [
            {
              type: "text",
              props: "contractPeriod.year",
            },
          ],
        },
        {
          type: "group",
          dataIndex: "type",
          subColumns: [
            {
              type: "text",
              props: "serviceFeeSettlementMethod.type",
              mapping: [
                {
                  text: "包干制",
                  value: 1,
                },
                {
                  text: "酬金制",
                  value: 2,
                },
                {
                  text: "自行管理",
                  value: 3,
                },
              ],
            },
          ],
        },
        {
          props: "status",
          type: "text",
          mapping: [
            {
              text: "任期",
              value: 1,
            },
            {
              text: "过期",
              value: 0,
            },
          ],
        },
      ],
      contract_table_columns: [
        {
          dataIndex: "name",
          title: "物业名称",
        },
        {
          dataIndex: "signingDate",
          title: "签订日期",
        },
        {
          dataIndex: "termRange",
          title: "签订期限范围",
          width: 250,
        },
        {
          dataIndex: "years",
          title: "合同年限",
        },
        {
          dataIndex: "relatedFiles",
          title: "相关联文件",
          width: 230,
        },
        {
          dataIndex: "status",
          title: "状态",
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 130,
        },
      ],
      contract_form_config: {
        col: 8,
        formLayout: "vertical",
        textAlign: "left",
        columns: [
          {
            col: 24,
            type: "divider",
            lintype: "horizontal",
            orientation: "left",
            size: "small",
            text: "物业服务合同信息PDF",
          },
          {
            props: "propertyServiceContractPDF",
            type: "oss-upload",
            label: "物业服务合同信息PDF",
            maxUpload: 10,
            col: 24,
            listType: "picture-card",
            rules: [],
          },
          {
            col: 24,
            type: "divider",
            lintype: "horizontal",
            orientation: "left",
            size: "small",
            text: "合同主体",
          },
          {
            type: "input",
            props: "contractSubject.partyA",
            label: "甲方",
            col: 12,
            placeholder: "请输入",
            rules: [],
          },
          {
            type: "input",
            col: 12,
            props: "contractSubject.UOCCommittee",
            label: "业主委员会主任",
            placeholder: "请输入",
            rules: [],
          },
          {
            type: "input",
            props: "contractSubject.partyB",
            label: "乙方",
            col: 12,
            placeholder: "请输入",
            rules: [],
          },
          {
            type: "input",
            props: "contractSubject.corporation",
            label: "法定代表人",
            col: 12,
            placeholder: "请输入",
            rules: [],
          },
          {
            type: "input",
            props: "contractSubject.propertyName",
            label: "物业名称",
            placeholder: "请输入",
            rules: [],
          },
          {
            type: "datepicker",
            datepickerType: "date-picker",
            format: "YYYY-MM-DD",
            props: "contractSubject.signingDate",
            label: "签订日期",
            placeholder: "请选择",
            rules: [],
          },
          {
            col: 24,
            type: "divider",
            lintype: "horizontal",
            orientation: "left",
            size: "small",
            text: "合同期限",
          },
          {
            type: "input",
            props: "contractPeriod.year",
            label: "合同年限",
            placeholder: "请输入",
            rules: [],
          },
          {
            type: "datepicker",
            props: "contractPeriod.termRange",
            label: "合同期限范围",
            placeholder: ["开始日期", "结束日期"],
            datepickerType: "range-picker",
            rules: [],
          },
          {
            col: 24,
            type: "divider",
            lintype: "horizontal",
            orientation: "left",
            size: "small",
            text: "物业管理费用",
          },
          {
            type: "input",
            props: "propertyManagementFees.highLevel",
            label: "高层一楼",
            placeholder: "请输入",
            addonAfter: '元/平方米/月',
            col: 12,
            rules: [],
          },
          {
            type: "input",
            props: "propertyManagementFees.highLevelChargeableArea",
            label: "可收费面积",
            addonAfter: '平方米',
            addonAfter: '平方米',
            placeholder: "请输入",
            col: 12,
            rules: [],
          },
          {
            type: "input",
            props: "propertyManagementFees.highLevel2",
            label: "高层二楼及以上",
            placeholder: "请输入",
            addonAfter: '元/平方米/月',
            col: 12,
            rules: [],
          },
          {
            type: "input",
            props: "propertyManagementFees.highLevelChargeableArea2",
            label: "可收费面积",
            addonAfter: '平方米',
            addonAfter: '平方米',
            placeholder: "请输入",
            col: 12,
            rules: [],
          },
          {
            type: "input",
            props: "propertyManagementFees.multilayer",
            label: "多层",
            col: 12,
            addonAfter: '元/平方米/月',
            placeholder: "请输入",
            rules: [],
          },
          {
            type: "input",
            props: "propertyManagementFees.multilayerChargeableArea",
            label: "可收费面积",
            addonAfter: '平方米',
            placeholder: "请输入",
            col: 12,
            rules: [],
          },
          {
            type: "input",
            props: "propertyManagementFees.townhouse",
            label: "联排地上",
            placeholder: "请输入",
            addonAfter: '元/平方米/月',
            col: 12,
            rules: [],
          },
          {
            type: "input",
            props: "propertyManagementFees.townhouseChargeableArea",
            label: "可收费面积",
            addonAfter: '平方米',
            placeholder: "请输入",
            col: 12,
            rules: [],
          },
          {
            type: "input",
            props: "propertyManagementFees.townhouse2",
            label: "联排地下",
            placeholder: "请输入",
            addonAfter: '元/平方米/月',
            col: 12,
            rules: [],
          },
          {
            type: "input",
            props: "propertyManagementFees.townhouseChargeableArea2",
            label: "可收费面积",
            addonAfter: '平方米',
            placeholder: "请输入",
            col: 12,
            rules: [],
          },
          {
            type: "input",
            props: "propertyManagementFees.singleFamily",
            label: "独栋别墅地上",
            col: 12,
            placeholder: "请输入",
            addonAfter: '元/平方米/月',
            rules: [],
          },
          {
            type: "input",
            props: "propertyManagementFees.singleFamilyChargeableArea",
            label: "可收费面积",
            addonAfter: '平方米',
            placeholder: "请输入",
            col: 12,
            rules: [],
          },
          {
            type: "input",
            props: "propertyManagementFees.singleFamily2",
            label: "独栋别墅地下",
            col: 12,
            placeholder: "请输入",
            addonAfter: '元/平方米/月',
            rules: [],
          },
          {
            type: "input",
            props: "propertyManagementFees.singleFamilyChargeableArea2",
            label: "可收费面积",
            addonAfter: '平方米',
            placeholder: "请输入",
            col: 12,
            rules: [],
          },
          {
            type: "input",
            props: "propertyManagementFees.officeBuilding",
            label: "办公楼",
            addonAfter: '元/平方米/月',
            col: 12,
            placeholder: "请输入",
            rules: [],
          },
          {
            type: "input",
            props: "propertyManagementFees.officeBuildingChargeableArea",
            label: "可收费面积",
            addonAfter: '平方米',
            placeholder: "请输入",
            col: 12,
            rules: [],
          },
          {
            type: "input",
            props: "propertyManagementFees.commercialProperty",
            label: "商业物业",
            addonAfter: '元/平方米/月',
            placeholder: "请输入",
            rules: [],
            col: 12
          },
          {
            type: "input",
            props: "propertyManagementFees.commercialPropertyChargeableArea",
            label: "可收费面积",
            addonAfter: '平方米',
            placeholder: "请输入",
            col: 12,
            rules: [],
          },
          {
            type: "input",
            props: "propertyManagementFees.other",
            label: "其他",
            addonAfter: '元/平方米/月',
            placeholder: "请输入",
            col: 12,
            rules: [],
          },
          {
            type: "input",
            props: "propertyManagementFees.otherChargeableArea",
            label: "可收费面积",
            addonAfter: '平方米',
            placeholder: "请输入",
            col: 12,
            rules: [],
          },
          {
            type: "input",
            props: "propertyManagementFees.totalRevenue",
            label: "本物业管理区域每年物业管理费总收入约",
            placeholder: "请输入",
            addonAfter: '元',
            rules: [],
          },
          {
            type: "input",
            props: "propertyManagementFees.totalArea",
            label: "总可收费面积",
            addonAfter: '平方米',
            placeholder: "请输入",
            rules: [],
          },
          {
            col: 24,
            type: "divider",
            lintype: "horizontal",
            orientation: "left",
            size: "small",
            text: "物业服务企业相关证照",
          },
          {
            type: "input",
            props: "companyName",
            label: "物业服务企业名称",
            placeholder: "请输入",
            rules: [],
          },
          {
            type: "input",
            props: "companyTel",
            label: "企业联系电话",
            placeholder: "请输入",
            rules: [],
          },
          {
            type: "input",
            props: "companyAddress",
            label: "企业办公地址",
            placeholder: "请输入",
            rules: [],
          },
          {
            props: "businessLicense",
            type: "oss-upload",
            label: "营业执照",
            maxUpload: 1,
            listType: "picture-card",
            rules: [],
          },
          {
            props: "certifications",
            type: "oss-upload",
            label: "资质证书",
            maxUpload: 1,
            listType: "picture-card",
            rules: [],
          },
          {
            props: "propertyManagerLicense",
            type: "oss-upload",
            label: "物业经理证照",
            maxUpload: 1,
            listType: "picture-card",
            rules: [],
          },
          {
            col: 24,
            type: "divider",
            lintype: "horizontal",
            orientation: "left",
            size: "small",
            text: "相关文件",
          },
          {
            col: 8,
            props: "bidRecordingFile",
            type: "oss-upload",
            label: "物业管理项目中标备案表",
            maxUpload: 1,
            listType: "picture-card",
            rules: [],
          },
          {
            col: 8,
            props: "OAFile",
            type: "oss-upload",
            label: "相关业主大会公告",
            maxUpload: 1,
            listType: "picture-card",
            rules: [],
          },
        ],
      },
      related_contract_detail_columns: [
        {
          type: "group",
          dataIndex: "name",
          subColumns: [
            {
              type: "text",
              props: "contractSubject.propertyName",
            },
          ],
        },
        {
          type: "group",
          dataIndex: "signingDate",
          subColumns: [
            {
              type: "text",
              props: "contractSubject.signingDate",
            },
          ],
        },
        {
          type: "group",
          dataIndex: "termRange",
          subColumns: [
            {
              type: "text",
              props: "contractPeriod.termRange",
              splitFlagChar: " 至 ",
            },
          ],
        },
        {
          type: "group",
          dataIndex: "years",
          subColumns: [
            {
              type: "text",
              props: "contractPeriod.year",
            },
          ],
        },
        {
          type: "group",
          dataIndex: "type",
          subColumns: [
            {
              type: "text",
              props: "serviceFeeSettlementMethod.type",
              mapping: [
                {
                  text: "包干制",
                  value: 1,
                },
                {
                  text: "酬金制",
                  value: 2,
                },
                {
                  text: "自行管理",
                  value: 3,
                },
              ],
            },
          ],
        },
        {
          props: "status",
          type: "text",
          mapping: [
            {
              text: "任期",
              value: 1,
            },
            {
              text: "过期",
              value: 0,
            },
          ],
        },
      ],
      related_contract_table_columns: [
        {
          dataIndex: "name",
          title: "合同名称",
        },
        {
          dataIndex: "signingDate",
          title: "签订日期",
          width: 150,
        },
        {
          dataIndex: "termRange",
          title: "签订期限范围",
          width: 150,

        },
        {
          dataIndex: "years",
          title: "合同年限",
        },
        {
          dataIndex: "amount",
          title: "合同金额（元/年）",
          width: 150,

        },
        {
          dataIndex: "relatedFiles",
          title: "相关联文件",
          width: 250,

        },
        {
          title: "操作",
          dataIndex: "action",
          width: 130,
        },
      ],
      related_contract_form_config: {
        col: 8,
        formLayout: "vertical",
        textAlign: "left",
        columns: [
          {
            col: 24,
            type: "divider",
            lintype: "horizontal",
            orientation: "left",
            size: "small",
            text: "物业相关事务合同PDF",
          },
          {
            props: "propertyRelatedContractPDF",
            type: "oss-upload",
            label: "物业相关事务合同PDF",
            maxUpload: 10,
            col: 24,
            listType: "picture-card",
            rules: [],
          },
          {
            col: 24,
            type: "divider",
            lintype: "horizontal",
            orientation: "left",
            size: "small",
            text: "合同主体",
          },
          {
            type: "input",
            props: "contractSubject.partyA",
            label: "甲方",
            col: 12,
            placeholder: "请输入",
            
          },
          {
            type: "input",
            props: "contractSubject.partyACorporation",
            col: 12,
            label: "法定代表人",
            placeholder: "请输入",
            
          },
          {
            type: "input",
            props: "contractSubject.partyB",
            label: "乙方",
            col: 12,
            placeholder: "请输入",
            
          },
          {
            type: "input",
            props: "contractSubject.partyBCorporation",
            col: 12,
            label: "法定代表人",
            placeholder: "请输入",
            
          },
          {
            type: "input",
            props: "contractSubject.partyC",
            col: 12,
            label: "丙方",
            placeholder: "请输入",
            
          },
          {
            type: "input",
            props: "contractSubject.partyCCorporation",
            col: 12,
            label: "法定代表人",
            placeholder: "请输入",
            
          },
          {
            type: "input",
            props: "contractSubject.propertyName",
            label: "合同名称",
            placeholder: "请输入",
            
          },
          {
            type: "datepicker",
            datepickerType: "date-picker",
            format: "YYYY-MM-DD",
            props: "contractSubject.signingDate",
            label: "签订日期",
            placeholder: "请选择",
            
          },

          {
            col: 24,
            type: "divider",
            lintype: "horizontal",
            orientation: "left",
            size: "small",
            text: "合同期限",
          },
          {
            type: "input",
            props: "contractPeriod.year",
            label: "合同年限",
            placeholder: "请输入",
            
          },
          {
            type: "datepicker",
            props: "contractPeriod.termRange",
            label: "合同期限范围",
            placeholder: ["开始日期", "结束日期"],
            datepickerType: "range-picker",
            
          },

          {
            col: 24,
            type: "divider",
            lintype: "horizontal",
            orientation: "left",
            size: "small",
            text: "合同金额",
          },

          {
            type: "input-number",
            props: "amount",
            label: "金额",
            placeholder: "请输入",
            addonAfter: '元/年',
            
          },
          {
            col: 24,
            type: "divider",
            lintype: "horizontal",
            orientation: "left",
            size: "small",
            text: "相关文件",
          },
          {
            col: 8,
            props: "bidRecordingFile",
            type: "oss-upload",
            label: "业主委员会会议纪要",
            maxUpload: 1,
            listType: "picture-card",
            
          },
          {
            col: 8,
            props: "OAFile",
            type: "oss-upload",
            label: "业主大会公告",
            maxUpload: 1,
            listType: "picture-card",
            
          },

        ],
      },



      prContract_detail_columns: [],
      prContract_table_columns: [
        {
          dataIndex: "name",
          title: "合同名称",
        },
        {
          dataIndex: "amount",
          title: "合同金额",
        },
        {
          dataIndex: "period",
          title: "合同期限(年)",
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 130,
        },
      ],
      prContract_form_config: {
        col: 24,
        formLayout: "vertical",
        textAlign: "left",
        columns: [
          {
            type: "input",
            props: "name",
            label: "合同名称",
            placeholder: "请输入",
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            col: 12,
            type: "input-number",
            props: "amount",
            label: "合同金额",
            placeholder: "请输入",
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            col: 12,
            type: "input-number",
            props: "period",
            label: "合同期限(年)",
            placeholder: "请输入",
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            col: 24,
            props: "fileUrl",
            type: "oss-upload",
            label: "业主大会、业主委员会合同登记清单",
            maxUpload: 1,
            listType: "picture-card",
            rules: [
              {
                required: true,
              },
            ],
          },
        ],
      },
    };
  },
};

<template>
	<div>
		<div class="panel floor-page">
			<div class="community-details">
				<a-descriptions :column="5">
					<a-descriptions-item label="小区名称">
						{{ uocDetails.communityName || '-' }}
					</a-descriptions-item>
					<a-descriptions-item label="坐落地址" :span="2">
						{{ (uocDetails.communityLocation || ['-']).join(' ') }}
					</a-descriptions-item>
					<a-descriptions-item label="四至位置">
						{{ uocDetails.fourPosition || '-' }}
					</a-descriptions-item>
					<a-descriptions-item label="建设单位">
						{{ uocDetails.constructionUnit || '-' }}
					</a-descriptions-item>
				</a-descriptions>
				<a-button
					@click="changeDetailHandle"
					type="primary"
					style="margin-top: -8px"
					>修改</a-button
				>
			</div>
			<a-tabs type="card" class="ywh-card">
				<a-tab-pane key="1" tab="建筑规划参数信息">
					<div class="ywh-card-content">
						<BuildingPlanningParameter></BuildingPlanningParameter>
					</div>
				</a-tab-pane>
				<a-tab-pane key="2" tab="共用设施设备参数信息">
					<div class="ywh-card-content">
						<SharedFacilityEquipmentParameter></SharedFacilityEquipmentParameter>
					</div>
				</a-tab-pane>
				<a-tab-pane key="3" tab="产业信息">
					<div class="ywh-card-content">
						<div class="ywh-desc mb24" v-if="listRes.areaTotal">
							<div class="ywh-desc-header">
								<div class="title">
									大楼建筑面积总计：{{
										listRes.areaTotal
									}}平方米
								</div>
							</div>
							<a-row>
								<a-col
									:span="8"
									v-for="item in listRes.areaTypeTotal"
									:key="item.key"
								>
									<div class="ywh-desc-content">
										<div class="desc-item">
											<span class="label"
												>{{
													item.type
												}}类建筑面积总计：</span
											>
											<span>{{ item.total }}平方米</span>
										</div>
									</div>
								</a-col>
							</a-row>
						</div>
						<div class="tl-right mb24">
							<a-button
								type="primary"
								class="ywh-btn"
								@click="handleAdd"
								>导入</a-button
							>
						</div>

						<ztable2
							:scroll="{ x: 1300 }"
							:detailColumns="bigFloor_detail_columns"
							:columns="bigFloor_table_columns"
							:dataSource="dataSource"
							:pagination="pagination"
							@change="tableChangeHandle"
						>
							<div slot="phone" slot-scope="text, record">
								<span
									class="s-phone-img"
									@click="showPhoneHandle(record)"
								>
									{{
										checkPhoneObj &&
										checkPhoneObj._id === record._id
											? checkPhoneObj.phone
											: text
									}}
									<img
										v-if="
											checkPhoneObj &&
											checkPhoneObj._id === record._id
										"
										src="https://huhuiyun.oss-cn-shanghai.aliyuncs.com/static/eye%20%281%29.svg"
									/>
									<img
										v-else
										src="https://huhuiyun.oss-cn-shanghai.aliyuncs.com/static/eye.svg"
									/>
								</span>
							</div>

							<div slot="action" slot-scope="text, record">
								<a @click="editBigFloorDetail(record)">编辑</a>
							</div>
						</ztable2>
					</div>
				</a-tab-pane>
				<a-tab-pane key="4" tab="业主委员会办公资产信息">
					<div class="ywh-card-content">
						<UOCOfficeAsset></UOCOfficeAsset>
					</div>
				</a-tab-pane>
			</a-tabs>
		</div>

		<!-- 导入文件弹框 -->
		<a-modal
			class="ywh-modal bigFloor-model"
			width="600px"
			:title="modalTitle"
			:visible="modalVisible"
			:confirm-loading="modalLoading"
			@ok="handleModalOk"
			@cancel="handleModalCancel"
			ok-text="确认"
			cancel-text="取消"
		>
			<h6>下载大楼信息模版文件</h6>
			<p class="space-between">
				<span>请严格按照给定的模版格式填写，否则会导致解析失败。</span>
				<a href="https://huhuiyun.oss-cn-shanghai.aliyuncs.com/template/%E5%B0%8F%E5%8C%BA%E5%A4%A7%E6%A5%BC%E4%BF%A1%E6%81%AF%E6%A8%A1%E7%89%88.xlsx" target="_blank">下载模版</a>
			</p>
			<p class="mb24">
				*注意：上传后，将覆盖原始数据，每次只保留最新上传的数据。<br>
				<br>
				导入产业信息后，自动注册业主账号，业主凭手机号+验证码即可登录、参与投票。<br>
				产业信息会关联业主大会“参会人员”，请确保数据准确。<br><br>
				采用MD5加密算法，保证您的数据安全，防止泄漏。
			</p>
			<zform
				:formConfig="bigFloor_form_columns"
				:formData="{}"
				ref="modal-form"
			></zform>
		</a-modal>

		<!-- 修改小区基础信息 -->
		<a-modal
			class="ywh-modal bigFloor-model"
			title="修改小区基础信息"
			:visible="detailsModalVisible"
			:confirm-loading="modalLoading"
			@ok="handleChangeDetailModalOk"
			@cancel="handleChangeDetailModalCancel"
			ok-text="确认"
			cancel-text="取消"
		>
			<a-form
				:form="form"
				:label-col="{ span: 5 }"
				:wrapper-col="{ span: 19 }"
			>
				<a-form-item label="小区名称">
					<a-input
						v-decorator="[
							'communityName',
							{
								rules: [
									{
										required: true,
										message: '请输入必填项！',
									},
								],
							},
						]"
					/>
				</a-form-item>

				<a-form-item label="小区位置">
					<a-cascader
						placeholder="请选择"
						:options="cascaderOptions"
						change-on-select
						:fieldNames="{
							label: 'name',
							value: 'name',
							children: 'districts',
						}"
						v-decorator="[
							'communityLocation',
							{
								rules: [
									{
										required: true,
										message: '请输入必填项！',
									},
								],
							},
						]"
					/>
				</a-form-item>
				<a-form-item label="四至位置">
					<a-input
						v-decorator="[
							'fourPosition',
							{
								rules: [],
							},
						]"
					/>
				</a-form-item>
				<a-form-item label="建设单位">
					<a-input
						v-decorator="[
							'constructionUnit',
							{
								rules: [],
							},
						]"
					/>
				</a-form-item>
			</a-form>
		</a-modal>

		<!-- 修改单条产业信息 -->
		<a-modal
			class="ywh-modal bigFloor-model"
			title="修改产业信息"
			:visible="editBigFloorVisible"
			:confirm-loading="editBigFloorLoading"
			@ok="handleEditBigFloorOk"
			@cancel="handleEditBigFloorCancel"
			ok-text="确认"
			cancel-text="取消"
		>
			<zform
				:formConfig="edit_bigFloor_form_columns"
				:formData="{}"
				ref="editBigFloor-modal-form"
			></zform>
		</a-modal>
	</div>
</template>

<script>
import mixin from './mixin.js'
import tableMixin from '@/mixin/table.list.js'
import UOCOfficeAsset from './UOCOfficeAsset'
import BuildingPlanningParameter from './buildingPlanningParameter'
import SharedFacilityEquipmentParameter from './sharedFacilityEquipmentParameter'

import { getStreetList } from '@/api/user/register'
import {
	putUOCDetails,
	getUOCDetails,
	showPhone,
	editBigFloorDetail,
} from '@/api/community/UOC'

export default {
	name: '',
	mixins: [tableMixin, mixin],
	data() {
		return {
			form: this.$form.createForm(this, { name: 'coordinated' }),
			bigFloor_data_source: [],
			detailsModalVisible: false,
			cascaderOptions: [],
			uocDetails: {},
			checkPhoneObj: null,
			editBigFloorVisible: false,
			editBigFloorRecord: null,
			editBigFloorLoading: false,
		}
	},
	methods: {
		async handleEditBigFloorOk() {
			this.editBigFloorLoading = true
			try {
				const values = await this.$refs[
					'editBigFloor-modal-form'
				].zformValidateFieldsAndScroll()
				const { _id } = this.editBigFloorRecord
				await editBigFloorDetail(_id, { ...values })
				this.handleEditBigFloorCancel()
				this.getList()
			} catch (error) {
				console.log(error)
			}
			this.editBigFloorVisible = false
			this.editBigFloorLoading = false

		},
		handleEditBigFloorCancel() {
			this.$refs['editBigFloor-modal-form'].resetFields()
			this.editBigFloorVisible = false
		},
		async editBigFloorDetail(record) {
			console.log('record', record);
			this.editBigFloorRecord = record
			this.editBigFloorVisible = true

			let phoneRes = await showPhone(record._id)
			record.phone = phoneRes.phone
			this.$nextTick(() => {
				this.$refs['editBigFloor-modal-form'].updateForm({ ...record })
			})
		},
		showPhoneHandle(record) {
			console.log('res', record)
			if (this.checkPhoneObj && this.checkPhoneObj._id === record._id) {
				this.checkPhoneObj = null
				return
			}
			showPhone(record._id)
				.then((res) => {
					this.checkPhoneObj = { _id: record._id, ...res }
					console.log('res', res)
				})
				.catch((err) => {
					this.checkPhoneObj = null
					console.log('err')
				})
		},
		// 查询列表接口api处理
		getListRequestHandle() {
			return {
				// 查询接口
				getApi: `/api/communityInformation/building/list`,
				usePagination: true,
			}
		},
		// 弹框提交接口api和params处理
		submitRequestHandle(formValues) {
			return {
				// 保存和更新接口
				postApi: `/api/communityInformation/building`,
				// 表单外的提交参数
				params: {},
			}
		},
		handleChangeDetailModalOk() {
			this.form.validateFields(async (err, values) => {
				if (err) {
					console.log(err)
					return
				}
				console.log(values)
				try {
					await putUOCDetails({
						communityLocation: values.communityLocation,
						communityName: values.communityName,
						constructionUnit: values.constructionUnit,
						fourPosition: values.fourPosition,
					})
					this.$message.success('修改成功！')
					this.handleChangeDetailModalCancel()
					getUOCDetails().then((res) => {
						this.uocDetails = res.details || {}
					})
				} catch (error) {
					this.$message.error('修改失败！')
					console.log(error)
				}
			})
		},
		handleChangeDetailModalCancel() {
			this.detailsModalVisible = false
		},
		changeDetailHandle() {
			this.detailsModalVisible = true
			this.$nextTick(() => {
				this.form.setFieldsValue(this.uocDetails)
			})
		},
	},
	components: {
		UOCOfficeAsset,
		BuildingPlanningParameter,
		SharedFacilityEquipmentParameter,
	},

	mounted() {
		this.getList()
		getStreetList().then((res) => {
			this.cascaderOptions = res.list
		})
		getUOCDetails().then((res) => {
			this.uocDetails = res.details || {}
		})
	},
}
</script>
<style lang="scss">
.community-details {
	background: white;
	margin-bottom: 16px;
	padding: 16px;
	padding-bottom: 0px;
	display: flex;
}

.mb32 {
	margin-bottom: 32px;
}
.mt40 {
	margin-top: 40px;
}
.floor-page {
	.ywh-desc .ywh-desc-header {
		height: 32px;
	}
	.ywh-desc .ywh-desc-content .desc-item {
		margin-bottom: 8px;
	}
}
.bigFloor-model {
	p {
		margin-bottom: 0;
		color: #999;
	}
	p.mb24 {
		margin-bottom: 24px;
	}
}

.s-phone-img {
	cursor: pointer;
	img {
		width: 18px;
	}
}
</style>

<template>
	<div>
		<div class="gen-block">
			<a-page-header title="唱票统计" :breadcrumb="{ props: { routes } }">
				<template slot="extra">
					<a-button type="primary" @click="backHandle">返回</a-button>
				</template>
			</a-page-header>
		</div>
		<div class="gen-block">
			<h4>表决结果</h4><span>已根据会议公告规则自动累加 计入多数、废票</span>
							
			<a-table :columns="columns" :dataSource="dataSource" :pagination="false" :scroll="{ x: 1300 }">
				<div slot="action" slot-scope="text, record">
					<a @click="setCotingMattersHandle(record, 1)">通过</a><br />
					<a @click="setCotingMattersHandle(record, 0)"> 不通过</a>
				</div>
			</a-table>

			<h4 style="margin-top: 50px">原始表决票</h4><span>未计算 计入多数、废票</span>
			<a-table :columns="columns" :dataSource="originDataSource" :pagination="false" :scroll="{ x: 1300 }">
				<div slot="action" slot-scope="text, record">
					<a @click="setCotingMattersHandle(record, 1)">通过</a><br />
					<a @click="setCotingMattersHandle(record, 0)"> 不通过</a>
				</div>
			</a-table>
		</div>
	</div>
</template>

<script>
import {
	getFlowVotingStatistics,
	getDetail,
	updateCotingMattersResult,
} from '@/api/flow'
export default {
	data() {
		return {
			routes: [
				{
					path: '/conferenceWorkbench/list',
					breadcrumbName: '会议工作台',
				},
				{
					path: '/conferenceWorkbench/detail',
					breadcrumbName: '会议详情',
				},
				{
					breadcrumbName: '唱票统计',
				},
			],
			dataSource: [],
			originDataSource: [],
			columns: [
				{
					title: '表决事项',
					dataIndex: 'voteInfoName',
					customRender: (text, row) => {
						const {
							voteInfo: { index },
							_rowSpan,
						} = row
						return {
							children: (
								<span href="javascript:;">{index}</span>
							),
							attrs: {
								rowSpan: _rowSpan,
							},
						}
					},
				},
				{
					title: '计数',
					dataIndex: 'type',
					customRender: (text, row, index) => {
						const map = {
							people: '人数',
							area: '专有部分建筑物⾯积',
						}
						return map[text]
					},
				},
				{
					title: '参与表决',
					dataIndex: 'plusNum',
				},
				{
					title: '已表决的同意票',
					width: 130,
					dataIndex: 'count_1',
				},
				{
					title: '已表决的反对票',
					width: 130,
					dataIndex: 'count_2',
				},
				{
					title: '已表决弃权票',
					width: 130,
					dataIndex: 'count_3',
				},
				{
					title: '已表决的计入多数票',
					width: 160,
					dataIndex: 'count_5',
				},
				{
					title: '⽆效票',
					dataIndex: 'count_4',
				},
				
				{
					title: '同意总数',
					dataIndex: 'agreedTotal',
				},
				{
					title: '同意百分⽐(%)',
					width: 130,
					dataIndex: 'agreePercentage',
				},
				{
					title: '表决结果',
					dataIndex: 'result',
					customRender: (text, row, index) => {
						const map = {
							1: '通过',
							0: '不通过',
						}
						return map[text] || '未审核'
					},
				},
				{
					title: '操作',
					key: 'action',
					fixed: 'right',
					scopedSlots: { customRender: 'action' },
				},
			],
			flowId: null,
		}
	},
	created() {
		const {
			params: { id },
		} = this.$route
		this.flowId = id
		this.getFlowDetail(id)
		this.getTableData()
	},
	mounted() {},
	methods: {
		setCotingMattersHandle(record, result) {
			updateCotingMattersResult(this.flowId, {
				voteIndex: record.voteIndex,
				result,
			})
				.then((res) => {
					this.$message.success('更新成功！')
					this.getTableData()
				})
				.catch((res) => {
					this.$message.error('更新失败！')
				})
		},
		async getFlowDetail(id) {
			try {
				const flowDetail = await getDetail(id)
				this.flowDetail = flowDetail || {}
			} catch (error) {
				console.log(error)
			}
		},
		getTableData() {
			getFlowVotingStatistics(this.flowId, {}).then((res) => {
				console.log('res', res)
				let roorRow = null
				let preDataSource = res.list
				let originDataSource = res.originRes

				for (let i = 0; i < preDataSource.length; i++) {
					const item = preDataSource[i]
					if (i === 0) {
						roorRow = item
						roorRow._rowSpan = 1
						continue
					}

					if (roorRow.voteIndex === item.voteIndex) {
						roorRow._rowSpan += 1
						item._rowSpan = 0
					} else {
						roorRow = item
						roorRow._rowSpan = 1
					}
				}

				for (let i = 0; i < originDataSource.length; i++) {
					const item = originDataSource[i]
					if (i === 0) {
						roorRow = item
						roorRow._rowSpan = 1
						continue
					}

					if (roorRow.voteIndex === item.voteIndex) {
						roorRow._rowSpan += 1
						item._rowSpan = 0
					} else {
						roorRow = item
						roorRow._rowSpan = 1
					}
				}

				this.dataSource = preDataSource
				this.originDataSource = originDataSource
			})
		},
		backHandle() {
			this.$router.go(-1)
		},
	},
}
</script>

<style lang="less" scoped>
</style>
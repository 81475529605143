/*
 * @Author: Yin Xiang Zheng
 * @LastEditors: Yin Xiang Zheng
 * @LastEditTime: 2020-11-14 23:48:09
 */
import request from '@/api/request'

export function createNews(data) {
    return request({
        url: `/api/announcement`,
        method: 'post',
        data
    });
}

export function list(params) {
    return request({
        url: `/api/announcement/adminList`,
        method: 'get',
        params
    });
}


export function updateNews(id, data) {
    return request({
        url: `/api/announcement/${id}`,
        method: 'put',
        data
    });
}

export function getDetail(id) {
    return request({
        url: `/api/announcement/detail/${id}`,
        method: 'get',
    });
}
<template>
  <div class="content-panel">
    <div class="top">
      <a-steps :current="current" class="steps">
        <a-step title="创建会议"></a-step>
        <a-step title="完成"></a-step>
      </a-steps>
    </div>
    <div class="content">
      <div class="step_1" v-if="current === 0">
        <zform
          :formConfig="form_config"
          :formData="{}"
          ref="modal-form"
          :action="actionHandle"
        >
          <div slot="selectGroup">
            <a-select
              style="width: 100%"
              v-model="selected"
              mode="multiple"
              labelInValue
            >
              <a-select-opt-group v-for="item in groupOptions" :key="item._id">
                <span slot="label">{{ item.name }}</span>
                <a-select-option
                  v-for="subItem in item.members"
                  :key="subItem._id"
                  :value="subItem._id"
                >
                  {{ subItem.name }}
                </a-select-option>
              </a-select-opt-group>
            </a-select>
          </div>
        </zform>
      </div>
      <div class="step_2" v-if="current === 1">
        <div class="success">
          <a-icon
            type="check-circle"
            theme="twoTone"
            two-tone-color="#18A75D"
            style="font-size:60px;"
          />
          <p>创建成功</p>
          <span>
            我们已将会议时间、地点等信息发送给参会人
          </span>
        </div>
        <div class="info" v-if="formData">
          <div>
            <span class="label">会议标题：{{ formData.title || "-" }}</span>
          </div>
          <div>
            <span class="label">会议人员：</span>
            <zdetail2
              :column="specialInvitation"
              :dataSource="formData"
            ></zdetail2>
          </div>
          <div>
            <span class="label">会议时间：</span>
            <p>
              {{ formData.meetingStartTime }} ~
              {{ formData.meetingEndTime }}
            </p>
          </div>
        </div>
        <div class="btns">
          <a-button style="margin-right:20px;" @click="handleCancel">
            取消
          </a-button>
          <a-button type="primary" @click="againCreate">
            再次创建
          </a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "./mixin.js";
import { getUOCUsers } from "@/api/community/UOC";
import request from "@/api/request";
export default {
  data() {
    return {
      current: 0,
      formData: null,
      groupOptions: [],
      selected: [],
      specialInvitation: {
        type: "group",
        props: "specialInvitation",
        subColumns: [
          {
            type: "text",
            props: "username",
            splitFlagChar: ",",
          },
        ],
      },
    };
  },
  mixins: [mixin],
  methods: {
    actionHandle({ column }) {
      if (column.props === "save") {
        this.$refs["modal-form"]
          .zformValidateFieldsAndScroll()
          .then((formData) => {
            formData.participants = formData.participants.map((item) => {
              return {
                username: item.label,
                _id: item.key,
              };
            });
            formData.specialInvitation = this.selected.map((item) => {
              return {
                username: item.label,
                _id: item.key,
              };
            });
            formData.meetingStartTime = formData.rangePicker[0];
            formData.meetingEndTime = formData.rangePicker[1];
            delete formData.rangePicker;
            request({
              url: "/api/dailyWork/uocMeeting",
              method: "post",
              data: { ...formData },
            }).then((res) => {
              this.formData = formData;
              this.current = 1;
            });
          });
      } else if (column.props === "cancel") {
        this.handleCancel();
      }
    },
    handleCancel() {
      this.$router.push("/dailywork/meeting");
    },
    againCreate() {
      this.formData = null;
      this.current = 0;
      this.selected = [];
      setTimeout(() => {
        this.$refs["modal-form"].resetFields();
      });
    },
  },
  created() {
    // 获取业委会成员
    getUOCUsers().then((res) => {
      console.log(res.list);
      let options = res.list.map((item) => {
        return {
          text: item.username,
          value: item._id,
        };
      });
      this.form_config.columns.map((item) => {
        if (item.props === "participants") {
          item.options = options;
        }
      });
      this.detail_columns.map((item) => {
        if (item.props === "participants") {
          item.mapping = options;
        }
      });
    });
    //
    request({
      url: "/api/tsAgency/list/1",
      method: "get",
    }).then((res) => {
      this.groupOptions = res.list;
      // let options = res.list.map((item) => {
      //   return {
      //     text: item.name,
      //     value: item._id,
      //   };
      // });
      // this.form_config.columns.map((item) => {
      //   if (item.props === "specialInvitation") {
      //     item.options = options;
      //   }
      // });
      // this.detail_columns.map((item) => {
      //   if (item.props === "specialInvitation") {
      //     item.mapping = options;
      //   }
      // });
    });
  },
  components: {},
};
</script>

<style lang="scss" scoped>
.top {
  border-bottom: 1px solid #eee;
  padding: 8px 0 32px;
  margin-bottom: 32px;
  .steps {
    width: 820px;
    margin: 0 auto;
  }
}
.content {
  .step_1 {
    width: 600px;
    margin: auto;
  }
  .step_2 {
    width: 600px;
    margin: 50px auto 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .success {
      text-align: center;
      > p {
        height: 31px;
        font-size: 24px;
        line-height: 31px;
        margin: 24px 0 8px;
      }
      > span {
        color: #999;
      }
    }
    .info {
      margin-top: 32px;
      padding-left: 156px;
      font-size: 16px;
      > div {
        margin-bottom: 5px;
        .label {
          color: #666;
        }
        display: flex;
      }
    }
    .btns {
      margin-top: 32px;
      text-align: center;
    }
  }
}
</style>

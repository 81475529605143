export default {
  data() {
    return {
      UOC_table_columns: [
        {
          dataIndex: "username",
          title: "姓名",
        },
        {
          dataIndex: "sex",
          title: "性别",
        },
        {
          dataIndex: "dateOfBirth",
          title: "出生年月",
        },
        {
          dataIndex: "position",
          title: "职务",
        },
        {
          dataIndex: "phone",
          title: "电话",
        },
        {
          dataIndex: "employmentSituation",
          title: "就职情况及职务",
        },
        {
          dataIndex: "politicalStatus",
          title: "政治面貌",
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 130,
        },
      ],
      UOC_detail_columns: [
        {
          props: "position",
          type: "text",
          mapping: [],
        },
      ],
      UOC_form_config: {
        col: 12,
        formLayout: "vertical",
        textAlign: "left",
        columns: [
          {
            props: "excelUrl",
            type: "oss-upload",
            label: "业委会人员信息",
            maxUpload: 1,
            listType: "picture-card",
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            col: 24,
            props: "changeLogFile",
            type: "oss-upload",
            label: "本届业主委员会基本情况",
            maxUpload: 1,
            listType: "picture-card",
            rules: [
              {
                required: true,
              },
            ],
          },
        ],
      },
      ownersGroupTableColumns: [
        {
          dataIndex: "username",
          title: "姓名",
        },
        {
          dataIndex: "sex",
          title: "性别",
        },
        {
          dataIndex: "geographicRange",
          title: "区域范围",
        },
        {
          dataIndex: "group",
          title: "组别",
        },
        {
          dataIndex: "dateOfBirth",
          title: "出生年月",
        },
        {
          dataIndex: "phone",
          title: "电话",
        },
        {
          dataIndex: "employmentSituation",
          title: "就职情况及职务",
          width: 130,
        },
        {
          dataIndex: "politicalStatus",
          title: "政治面貌",
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 130,
        },
      ],
      ownersGroupDetailColumns: [],
      ownersGroupFormConfig: {
        col: 12,
        formLayout: "vertical",
        textAlign: "left",
        columns: [
          {
            props: "excelUrl",
            type: "oss-upload",
            label: "业主小组人员信息",
            maxUpload: 1,
            listType: "picture-card",
            rules: [
              {
                required: true,
              },
            ],
          },
        ],
      },

      record_table_columns: [
        {
          dataIndex: "committeeName",
          title: "业主委员会名称",
        },
        {
          dataIndex: "termOffice",
          title: "任期时间",
        },
        {
          dataIndex: "status",
          title: "状态",
        },
        {
          dataIndex: "class",
          title: "届别",
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 130,
        },
      ],
      record_detail_columns: [
        {
          props: "status",
          type: "text",
          mapping: [
            {
              text: "任期",
              value: 1,
            },
            {
              text: "过期",
              value: 0,
            },
          ],
        },
      ],
      record_form_config: {
        col: 12,
        formLayout: "vertical",
        textAlign: "left",
        columns: [
          {
            type: "input",
            props: "code",
            label: "业主大会代码",
            placeholder: "请输入",
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            type: "input",
            props: "assemblyName",
            label: "业主大会名称",
            placeholder: "请输入",
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            type: "input",
            props: "committeeName",
            label: "业主委员会名称",
            placeholder: "请输入",
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            type: "input",
            props: "committeeAddress",
            label: "业主委员会地址",
            placeholder: "请输入",
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            type: "input-number",
            props: "class",
            label: "届别",
            placeholder: "请输入",
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            type: "datepicker",
            props: "termOffice",
            label: "任期",
            placeholder: ["开始日期", "结束日期"],
            datepickerType: "range-picker",
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            col: 24,
            props: "fileUrl",
            type: "oss-upload",
            label: "上传备案证明",
            maxUpload: 1,
            listType: "picture-card",
            rules: [
              {
                required: true,
              },
            ],
          },
        ],
      },
    };
  },
};

<template>
	<div>
		<a-tabs type="card" class="ywh-card" @change="handleTabChange">
			<a-tab-pane key="1" tab="物业服务合同信息">
				<div class="ywh-card-content">
					<ps-contract v-if="activeKey === '1'"></ps-contract>
				</div>
			</a-tab-pane>
			<a-tab-pane key="2" tab="物业相关事务合同">
				<div class="ywh-card-content">
					<pr-contract v-if="activeKey === '2'"></pr-contract>
				</div>
			</a-tab-pane>
		</a-tabs>
	</div>
</template>

<script>
import manager from './manager'
import psContract from './psContract'
import prContract from './prContract'
export default {
	name: '',
	data() {
		return {
			activeKey: '1',
		}
	},
	methods: {
		handleTabChange(activeKey) {
			this.activeKey = activeKey
		},
	},
	created() {},
	components: {
		manager,
		psContract,
		prContract,
	},
}
</script>
<style lang="scss" scoped></style>

<!--
 * @Author: Yin Xiang Zheng
 * @LastEditors: Yin Xiang Zheng
 * @LastEditTime: 2021-03-08 18:01:09
-->

<template>
	<div>
		<!-- 表决票签收照片管理 -->
		<a-modal
			title="表决票签收照片管理"
			:visible="signingSituationManageVisible"
			@ok="signingSituationManageVisible = false"
			@cancel="signingSituationManageVisible = false"
			:destroyOnClose="true"
			width="80%"
		>
			<a-form layout="inline" :form="signingSituationForm">
				<a-form-item label="产业地址">
					<a-input v-decorator="['address']" placeholder="产业地址">
					</a-input>
				</a-form-item>
				<a-form-item label="业主小组">
					<a-input
						v-decorator="['ownersGroup']"
						placeholder="业主小组"
					>
					</a-input>
				</a-form-item>
				<a-form-item label="签收状态">
					<a-select
						v-decorator="['signingSituation']"
						style="width: 120px"
					>
						<a-select-option :value="0"> 签收 </a-select-option>
						<a-select-option :value="1"> 邮寄 </a-select-option>
						<a-select-option :value="2"> 报箱 </a-select-option>
						<a-select-option :value="3"> 其他 </a-select-option>
						<a-select-option :value="4"> 未签收 </a-select-option>
					</a-select>
				</a-form-item>

				<a-form-item>
					<a-button
						type=""
						style="margin-left: 8px"
						@click="resetHandle"
					>
						重置
					</a-button>
					<a-button
						type="primary"
						style="margin-left: 8px"
						@click="selHandle"
					>
						查询
					</a-button>
					<a-dropdown>
						<a-menu
							slot="overlay"
							@click="batchUpdateSigningSituationHandle"
						>
							<a-menu-item :key="0"
								>设为{{ SigningSituationStatusMap[0] }}
							</a-menu-item>
							<a-menu-item :key="1"
								>设为{{ SigningSituationStatusMap[1] }}
							</a-menu-item>
							<a-menu-item :key="2"
								>设为{{ SigningSituationStatusMap[2] }}
							</a-menu-item>
							<a-menu-item :key="3"
								>设为{{ SigningSituationStatusMap[3] }}
							</a-menu-item>
							<a-menu-divider />
							<a-menu-item :key="4"
								>设为{{ SigningSituationStatusMap[4] }}
							</a-menu-item>
						</a-menu>
						<a-button type="primary" style="margin-left: 8px">
							设置状态 <a-icon type="down" />
						</a-button>
					</a-dropdown>
				</a-form-item>
			</a-form>

			<ztable2
				style="margin-top:16px"
				:detailColumns="detail_columns"
				:columns="table_columns"
				:dataSource="dataSource"
				:pagination="pagination"
				@change="tableChangeHandle"
				:row-selection="{
					selectedRowKeys: selectedRowKeys,
					onChange: onSelectChange,
				}"
			>
			</ztable2>
			<div class="aMapModel-img-content">
				<!-- <img
					v-for="(item, index) of aMapModelFileList"
					:src="item"
					:key="index"
				/> -->
				<PreviewImg :fileList="aMapModelFileList"></PreviewImg>
			</div>
		</a-modal>
	</div>
</template>

<script>
import PreviewImg from '@/components/previewImg.vue'
import { getFlowVoteList, updateFlowVoteSigningSituation } from '@/api/flow'
import { SigningSituationStatusMap } from '@/utils/variable'
import tableMixin from '@/mixin/table.list.js'


const columns = [
	{
		title: '业主小组',
		dataIndex: 'buildingInformation.ownersGroup',
	},
	{
		title: '产业地址',
		dataIndex: 'buildingInformation.address',
	},
	{
		title: '表决票票号',
		dataIndex: 'serialNumber',
	},
	{
		title: '表决票签收状态',
		dataIndex: 'signingSituation',
		key: 'name',
		scopedSlots: { customRender: 'signingSituation' },
	},
]
export default {
	data() {
		return {
			table_columns: columns,
			detail_columns: [],
			SigningSituationStatusMap,
			signingSituationManageVisible: false,
			signingSituationForm: this.$form.createForm(this, {
				name: 'signingSituationForm',
			}),
			data: [],
			selectedRowKeys: [], // Check here to configure the default column
			selectedRow: [],
			loading: false,
			aMapModelFileList: []
		}
	},
	props: ['value', 'flowId', 'flowDetail'],
	mixins: [tableMixin],
	watch: {
		value(newval) {
			this.signingSituationManageVisible = newval
		},
		signingSituationManageVisible(newval) {
			if (newval) {
				this.aMapModelFileList = []
				this.getTableData()
			}
			this.$emit('input', newval)
		},
	},
	components: {PreviewImg},
	computed: {},
	methods: {
		getListRequestHandle() {
			return {
				// 查询接口
				getApi: `/api/flow/${this.flowId}/vote`,
				usePagination: true,
			}
		},
		selHandle() {
			this.getTableData()
		},
		resetHandle() {
			this.params = {
				pageNum: 1,
				pageSize: 10,
			}
			this.signingSituationForm.resetFields()
			this.getTableData()
		},
		tableChangeHandleCB() {
			this.selectedRowKeys = []
			this.selectedRow = []
		},
		getListCB() {
			this.selectedRowKeys = []
			this.selectedRow = []
		},
		getTableData() {
			let val = this.signingSituationForm.getFieldsValue()
			this.loading = true
			const { address, ownersGroup, signingSituation } = val
			this.params.address = address
			this.params.ownersGroup = ownersGroup
			this.params.signingSituation =
				SigningSituationStatusMap[signingSituation]
			this.getList()

			const { relatedDocuments } = this.flowDetail
			const { publicity } = relatedDocuments['SERVE_A_VOTE']
			if (!publicity || publicity.length === 0) {
				this.$message.error('暂未上传公示公告！')
				return
			}
			let fileList = []
			publicity.forEach(item => {
				const { fileList:fileInfoList } = item.fileInfo
				fileList.push(fileInfoList)
			})
			this.aMapModelFileList = fileList
			return
			getFlowVoteList(this.flowId, val)
				.then((res) => {
					console.log('res', res)
					this.data = res.list
					this.loading = false
					this.selectedRowKeys = []
					this.selectedRow = []
				})
				.catch((err) => {
					console.log('err', err)
					this.loading = false
				})
		},
		batchUpdateSigningSituationHandle(e, b) {
			const { selectedRow } = this

			if (!selectedRow ||selectedRow.length === 0) {
				this.$message.warning('请至少选择一张表决票！')
				return
			}
			const val = e.key
			let signingSituationRadioVal = {}
			for (let i = 0; i < selectedRow.length; i++) {
				const element = selectedRow[i]
				signingSituationRadioVal[element._id] = val
			}
			console.log('signingSituationRadioVal', signingSituationRadioVal)
			this.loading = true
			updateFlowVoteSigningSituation(this.flowId, {
				signingSituationRadioVal,
			})
				.then((res) => {
					this.resetHandle()
				})
				.catch((err) => {
					this.loading = false
				})
		},
		tableChange(pagination) {
			console.log(pagination)
			this.selectedRowKeys = []
			this.selectedRow = []
		},
		onSelectChange(selectedRowKeys, selectedRow, b) {
			console.log(selectedRow)
			this.selectedRowKeys = selectedRowKeys
			this.selectedRow = selectedRow
		},
	},
}
</script>
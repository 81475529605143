import moment from "moment";
export default {
  data() {
    return {
      table_columns: [
        {
          dataIndex: "description",
          title: "接待描述",
        },
        {
          dataIndex: "uocUser",
          title: "接待人",
        },
        {
          dataIndex: "visitors",
          title: "来访人",
        },
        {
          dataIndex: "time",
          title: "时间",
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 220,
        },
      ],
      detail_columns: [
        {
          type: "group",
          props: "uocUser",
          subColumns: [
            {
              type: "text",
              props: "username",
            },
          ],
        },
        {
          dataIndex: "time",
          type: "text",
          renderContent: ({ value, dataSource }) => {
            return `${moment(dataSource.receptionStartTime).format(
              "YYYY-MM-DD HH:mm"
            )} - ${moment(dataSource.receptionEndTime).format(
              "YYYY-MM-DD HH:mm"
            )}`;
          },
        },
      ],

      form_config: {
        col: 24,
        formLayout: "vertical",
        textAlign: "left",
        columns: [
          {
            type: "input",
            props: "description",
            label: "接待描述",
            placeholder: "请输入",
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            label: "会议时间",
            type: "datepicker",
            props: "rangePicker",
            placeholder: ["接待开始时间", "接待结束时间"],
            datepickerType: "range-picker",
            format: "YYYY-MM-DD HH:mm",
            showTime: true,
            rules: [
              {
                required: true,
              },
            ],
          },
          /*{
            label: "接待开始时间",
            type: "datepicker",
            props: "receptionStartTime",
            datepickerType: "date-picker",
            placeholder: "请选择",
            format: "YYYY-MM-DD HH:mm:ss",
            allowClear: false,
            showTimeDefaultValue: {
              format: "HH:mm:ss",
            },
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            label: "接待结束时间",
            type: "datepicker",
            props: "receptionEndTime",
            datepickerType: "date-picker",
            placeholder: "请选择",
            format: "YYYY-MM-DD HH:mm:ss",
            allowClear: false,
            showTimeDefaultValue: {
              format: "HH:mm:ss",
            },
            rules: [
              {
                required: true,
              },
            ],
          },*/
          {
            type: "select",
            props: "uocUser",
            label: "接待人",
            placeholder: "请选择",
            options: [],
            labelInValue: true,
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            type: "input",
            props: "visitors",
            label: "来访人",
            placeholder: "请输入",
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            col: 8,
            props: "onDutyFile",
            type: "oss-upload",
            label: "值班接待记录",
            maxUpload: 1,
            listType: "picture-card",
          },
          {
            col: 8,
            props: "meetingFile",
            type: "oss-upload",
            label: "业主座谈会会议记录",
            maxUpload: 1,
            listType: "picture-card",
          },
          {
            col: 8,
            props: "correspondenceFile",
            type: "oss-upload",
            label: "往来函件记录",
            maxUpload: 1,
            listType: "picture-card",
          },
          {
            col: 8,
            props: "ownersCheckFile",
            type: "oss-upload",
            label: "业主查阅登记表",
            maxUpload: 1,
            listType: "picture-card",
          },
          {
            col: 8,
            props: "propertyFeedbackFile",
            type: "oss-upload",
            label: "向物业企业反馈侍改进的问题",
            maxUpload: 1,
            listType: "picture-card",
          },
          {
            col: 8,
            props: "ownersFeedbackFile",
            type: "oss-upload",
            label: "向业主反馈诉求的解决情况",
            maxUpload: 1,
            listType: "picture-card",
          },
        ],
      },
      duty_form_config: {
        col: 24,
        // formLayout: "vertical",
        textAlign: "right",
        columns: [
          {
            props: "dutyList",
            type: "oss-upload",
            label: "值班接待记录",
            maxUpload: 1,
            listType: "picture-card",
            rules: [
              {
                required: true,
              },
            ],
          },
        ],
      },
    };
  },
};

import moment from "moment";
export default {
  data() {
    return {
      table_columns: [
        {
          dataIndex: "month",
          title: "月份",
        },
        {
          dataIndex: "numbering",
          title: "项目编号",
        },
        {
          dataIndex: "projectName",
          title: "项目名称",
        },
        {
          dataIndex: "type",
          title: "类型",
        },
        {
          dataIndex: "amount",
          title: "使用金额",
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 120,
        },
      ],
      detail_columns: [
        {
          props: "type",
          type: "text",
          mapping: [
            {
              text: "收入",
              value: 1,
            },
            {
              text: "支出",
              value: 2,
            },
          ],
        },
      ],
      form_config: {
        col: 12,
        formLayout: "vertical",
        textAlign: "left",
        columns: [
          {
            label: "月份",
            type: "datepicker",
            props: "month",
            datepickerType: "month-picker",
            placeholder: "选择月份",
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            type: "select",
            props: "type",
            label: "类型",
            options: [
              {
                text: "收入",
                value: 1,
              },
              {
                text: "支出",
                value: 2,
              },
            ],
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            type: "input",
            props: "projectName",
            label: "项目名称",
            placeholder: "请输入",

            rules: [
              {
                required: true,
              },
            ],
          },
          {
            type: "input-number",
            props: "amount",
            label: "金额",
            placeholder: "请输入",
            width: "100%",
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            props: "financialStatements",
            type: "oss-upload",
            label: "财务报表",
            maxUpload: 1,
            listType: "picture-card",
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            type: "input",
            props: "relatedPublicBenefits",
            label: "关联公共收益",
            placeholder: "请输入",

            rules: [
              {
                required: true,
              },
            ],
          },

          {
            type: "textarea",
            props: "remark",
            label: "备注",
            col: 24,
            placeholder: "请输入",
            rows: 5,
            rules: [
              {
                required: true,
              },
            ],
          },
        ],
      },
      modalColumns: [
        {
          col: 24,
          type: "input-number",
          props: "lastYearBalance",
          label: "上年度结余",
          placeholder: "请输入",
          width: "100%",
          rules: [
            {
              required: true,
            },
          ],
        },
        {
          props: "financialStatements",
          type: "oss-upload",
          label: "财务报表",
          maxUpload: 1,
          listType: "picture-card",
          rules: [
            {
              required: true,
            },
          ],
        },
      ],
    };
  },
};

/*
 * @Author: Yin Xiang Zheng
 * @LastEditors: Yin Xiang Zheng
 * @LastEditTime: 2021-03-08 17:14:05
 */
/*
 * @Author: Yin Xiang Zheng
 * @LastEditors: Yin Xiang Zheng
 * @LastEditTime: 2021-03-08 17:13:45
 */
// 通用下载方法
export const SigningSituationStatusMap = {
    0: '签收',
    1: '邮寄',
    2: '报箱',
    3: '其他',
    4: '未签收',
}
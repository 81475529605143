<template>
  <div class="tsAgency-page">
    <a-tabs
      type="card"
      class="ywh-card"
      :activeKey="tabType"
      @change="handleTabChange"
    >
      <a-tab-pane
        v-for="item in tsAgencyTypes"
        :key="item.index"
        :tab="item.name"
      >
        <div class="ywh-card-content">
          <a-button type="primary" class="ywh-btn mb32" @click="handleAdd"
            >新增</a-button
          >
          <ztable2
            :detailColumns="agency_detail_columns"
            :columns="agency_table_columns"
            :dataSource="item.data"
            @change="tableChangeHandle"
            :pagination="false"
          >
            <span slot="action" slot-scope="text, record">
              <div class="editable-row-operations">
                <a href="javascript:void(0)" @click="handleEdit(record)"
                  >编辑</a
                >
                <a href="javascript:void(0)" @click="handleDelete(record._id)"
                  >删除</a
                >
              </div>
            </span>
          </ztable2>
        </div>
      </a-tab-pane>
    </a-tabs>

    <a-modal
      class="ywh-modal"
      :title="agencyModalTitle"
      :visible="agencyModalVisible"
      :confirm-loading="agencyModalLoading"
      @ok="agencyModalOk"
      @cancel="agencyModalCancel"
      ok-text="确认"
      cancel-text="取消"
    >
      <zform
        :formConfig="agency_form_config"
        :formData="{}"
        ref="agency-modal-form"
      ></zform>
    </a-modal>
  </div>
</template>

<script>
import {
  getTsAgency,
  getTsAgencyTypes,
  getTsAgencyByType,
  createTsAgency,
  updateTsAgency,
  deleteTsAgency,
  getTsAgencyMembers,
} from "@/api/community/tsAgency";
import mixin from "./mixin.js";
export default {
  data() {
    return {
      tsAgencyTypes: [],
      agencyModalTitle: "新增",
      agencyModalVisible: false,
      agencyModalLoading: false,
      tabType: null,
      editId: null,
    };
  },
  mixins: [mixin],
  methods: {
    agencyModalOk() {
      let { tabType } = this;
      this.$refs["agency-modal-form"]
        .zformValidateFieldsAndScroll()
        .then((res) => {
          console.log(res);
          this.agencyModalVisible = true;
          if (!this.editId) {
            createTsAgency({ ...res, type: tabType })
              .then((res) => {
                console.log(res);
                this.$message.success("添加成功");
                this.agencyModalVisible = false;
                this.getTsAgency();
              })
              .finally((res) => {
                this.agencyModalVisible = false;
              });
          } else {
            updateTsAgency(this.editId, { ...res, type: tabType })
              .then((res) => {
                this.$message.success("添加成功");
                this.agencyModalVisible = false;
                this.getTsAgency();
              })
              .finally((res) => {
                this.agencyModalVisible = false;
              });
          }
        });
    },

    agencyModalCancel() {
      this.agencyModalVisible = false;
    },
    handleTabChange(activeKey) {
      this.tabType = activeKey;
      this.tsAgencyTypes.map((item) => {
        if (item.index === activeKey && !item.data) {
          this.getTsAgency();
        }
      });
    },
    getTsAgency() {
      let { tabType } = this;
      getTsAgencyByType(tabType).then((res) => {
        // this.tsAgencyTypes[tabIndex].data = res.list;
        this.setTsAgencyData(res.list);
      });
    },
    setTsAgencyData(data) {
      let { tabType } = this;
      this.tsAgencyTypes.map((item, index) => {
        if (item.index === tabType) {
          //   item.data = data;
          this.$set(this.tsAgencyTypes[index], "data", data);
        }
      });
    },
    handleAdd() {
      this.editId = null;
      this.agencyModalVisible = true;
      setTimeout(() => {
        this.$refs["agency-modal-form"].resetFields();
      });
    },

    handleEdit(record) {
      let { tabType } = this;
      let { address, name, _id } = record;
      this.agencyModalVisible = true;
      setTimeout(() => {
        this.$refs["agency-modal-form"].updateForm({ address, name }, true);
      });
      this.editId = _id;
    },
    handleDelete(_id) {
      let _this = this;
      this.$confirm({
        title: "确定删除该项目?",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          deleteTsAgency(_id).then((res) => {
            _this.$message.success("删除成功");
            _this.getTsAgency();
          });
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    },
    openUserPop(record) {
      getTsAgencyMembers(record._id);
    },
    tableAction({ props, dataSource }) {
      if (props == "name") {
        this.$router.push({
          path: "/community/tsAgency/members",
          query: {
            _id: dataSource._id,
          },
        });
      }
    },
  },
  created() {
    getTsAgencyTypes().then((res) => {
      let tabType = localStorage.getItem("tsAgencyType");
      this.tsAgencyTypes = res.list;
      this.tabType = tabType ? parseInt(tabType) : res.list[0].index;
      this.getTsAgency(this.tabType);
    });
  },
  components: {},
  beforeRouteLeave(to, from, next) {
    let { tabType } = this;
    if (to.name === "tsAgencyMembers") {
      localStorage.setItem("tsAgencyType", tabType);
    }
    next();
  },
};
</script>

<style lang="scss" scoped>
.mb32 {
  margin-bottom: 32px;
}
</style>

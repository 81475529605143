<template>
  <div class="tsAgency-members-page">
    <a-button type="primary" class="ywh-btn mb32 mr16" @click="handleAdd"
      >新增</a-button
    >
    <a-button class="ywh-btn mb32" @click="handleBack"
      ><a-icon type="arrow-left" style="vertical-align:0px" />返回</a-button
    >
    <ztable2
      :detailColumns="detail_columns"
      :columns="table_columns"
      :dataSource="dataSource"
      @change="tableChangeHandle"
      :pagination="false"
    >
      <span slot="action" slot-scope="text, record">
        <div class="editable-row-operations">
          <a href="javascript:void(0)" @click="handleEdit(record)">编辑</a>
          <a href="javascript:void(0)" @click="handleDelete(record._id)"
            >删除</a
          >
        </div>
      </span>
    </ztable2>

    <a-modal
      class="ywh-modal"
      :title="modalTitle"
      :visible="modalVisible"
      :confirm-loading="modalLoading"
      @ok="handleModalOk"
      @cancel="handleModalCancel"
      ok-text="确认"
      cancel-text="取消"
    >
      <zform :formConfig="form_config" :formData="{}" ref="modal-form"></zform>
    </a-modal>
  </div>
</template>

<script>
import tableMixin from "@/mixin/table.list.js";
import mixin from "./mixin.js";
export default {
  data() {
    return {};
  },
  mixins: [tableMixin, mixin],
  methods: {
    // 查询列表接口api处理
    getListRequestHandle() {
      let _id = this.$route.query._id;
      return {
        // 查询接口
        getApi: `/api/tsAgency/${_id}/members`,
      };
    },
    // 弹框提交接口api和params处理
    submitRequestHandle() {
      let _id = this.$route.query._id;
      return {
        // 保存和更新接口
        postApi: `/api/tsAgency/${_id}/members`,
        putApi: `/api/tsAgency/${_id}/members/${this.editId}`,
        // 表单外的提交参数
        params: {},
      };
    },
    // 列表删除api处理
    deleteRequestHandle(recordId) {
      let _id = this.$route.query._id;
      return {
        // 删除接口
        deleteApi: `/api/tsAgency/${_id}/members/${recordId}`,
      };
    },
    handleBack() {
      this.$router.go(-1);
    },
  },
  created() {
    // 获取页面数据
    this.getList();
  },
  components: {},
};
</script>

<style lang="scss" scoped></style>

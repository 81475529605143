<template>
	<div class="dashbord-funds-surplus">
		<a-row>
			<a-col :span="6">
				<div
					class="container"
					id="wx"
					style="width: 100%; height: 344px"
				></div>
			</a-col>
			<a-col :span="6">
				<div
					class="container"
					id="wy"
					style="width: 100%; height: 344px"
				></div>
			</a-col>
			<a-col :span="6">
				<div
					class="container"
					id="gg"
					style="width: 100%; height: 344px"
				></div>
			</a-col>
			<a-col :span="6">
				<div
					class="container"
					id="bg"
					style="width: 100%; height: 344px"
				></div>
			</a-col>
		</a-row>
	</div>
</template>

<script>
// 引入 ECharts 主模块
var echarts = require('echarts/lib/echarts')
// 引入柱状图
require('echarts/lib/chart/pie')
// 引入提示框和标题组件
require('echarts/lib/component/tooltip')
require('echarts/lib/component/title')
require('echarts/lib/component/legend')
// let theme = require('@/echarts/theme/ywh.line.json')
import theme from '@/echarts/theme/ywh.line.json'
echarts.registerTheme('ywhLine', theme)
export default {
	name: '',
	props: {
		data: {
			type: Array,
		},
	},
	data() {
		return {}
	},
	computed: {},
	methods: {},
	mounted() {
		// 基于准备好的dom，初始化echarts实例
		var myChart_wx = echarts.init(document.getElementById('wx'), 'ywhLine')
		// 绘制图表
		myChart_wx.setOption({
			title: {
				text: '维修资金剩余',
				left: 'center',
				textStyle: {
					fontWeight: 'normal',
					fontSize: 14,
				},
			},
			legend: {
				data: ['支出', '剩余'],
				bottom: 10,
			},
			tooltip: {
				formatter: '{a} <br/>{b}: {c} ({d}%)',
			},
			color: ['#2243E6', '#D82B2B'],
			series: [
				{
					name: '访问来源',
					type: 'pie',
					radius: ['50%', '70%'],
					avoidLabelOverlap: false,
					label: {
						show: false,
						position: 'center',
					},
					emphasis: {
						label: {
							show: true,
							fontSize: '30',
							fontWeight: 'bold',
						},
					},
					labelLine: {
						show: false,
					},
					data: [
						{ value: 700, name: '支出' },
						{ value: 200, name: '剩余' },
					],
				},
			],
		})

		// 基于准备好的dom，初始化echarts实例
		var myChart_wy = echarts.init(document.getElementById('wy'), 'ywhLine')
		// 绘制图表
		myChart_wy.setOption({
			title: {
				text: '物业费剩余',
				left: 'center',
				textStyle: {
					fontWeight: 'normal',
					fontSize: 14,
				},
			},
			legend: {
				data: ['支出', '剩余'],
				bottom: 10,
			},
			tooltip: {
				trigger: 'item',
				formatter: '{a} <br/>{b}: {c} ({d}%)',
			},
			color: ['#2243E6', '#D82B2B'],
			series: [
				{
					name: '访问来源',
					type: 'pie',
					radius: ['50%', '70%'],
					avoidLabelOverlap: false,
					label: {
						show: false,
						position: 'center',
					},
					emphasis: {
						label: {
							show: true,
							fontSize: '30',
							fontWeight: 'bold',
						},
					},
					labelLine: {
						show: false,
					},
					data: [
						{ value: 535, name: '支出' },
						{ value: 310, name: '剩余' },
					],
				},
			],
		})

		// 基于准备好的dom，初始化echarts实例
		var myChart_gg = echarts.init(document.getElementById('gg'), 'ywhLine')
		// 绘制图表
		myChart_gg.setOption({
			title: {
				text: '维修资金剩余',
				left: 'center',
				textStyle: {
					fontWeight: 'normal',
					fontSize: 14,
				},
			},
			legend: {
				data: ['支出', '剩余'],
				bottom: 10,
			},
			tooltip: {
				trigger: 'item',
				formatter: '{a} <br/>{b}: {c} ({d}%)',
			},
			color: ['#2243E6', '#D82B2B'],
			series: [
				{
					name: '访问来源',
					type: 'pie',
					radius: ['50%', '70%'],
					avoidLabelOverlap: false,
					label: {
						show: false,
						position: 'center',
					},
					emphasis: {
						label: {
							show: true,
							fontSize: '30',
							fontWeight: 'bold',
						},
					},
					labelLine: {
						show: false,
					},
					data: [
						{ value: 125, name: '支出' },
						{ value: 310, name: '剩余' },
					],
				},
			],
		})

		// 基于准备好的dom，初始化echarts实例
		var myChart_bg = echarts.init(document.getElementById('bg'), 'ywhLine')
		// 绘制图表
		myChart_bg.setOption({
			title: {
				text: '维修资金剩余',
				left: 'center',
				textStyle: {
					fontWeight: 'normal',
					fontSize: 14,
				},
			},
			legend: {
				data: ['支出', '剩余'],
				bottom: 10,
			},
			tooltip: {
				trigger: 'item',
				formatter: '{a} <br/>{b}: {c} ({d}%)',
			},
			color: ['#2243E6', '#D82B2B'],
			series: [
				{
					name: '访问来源',
					type: 'pie',
					radius: ['50%', '70%'],
					avoidLabelOverlap: false,
					label: {
						show: false,
						position: 'center',
					},
					emphasis: {
						label: {
							show: true,
							fontSize: '30',
							fontWeight: 'bold',
						},
					},
					labelLine: {
						show: false,
					},
					data: [
						{ value: 335, name: '支出' },
						{ value: 310, name: '剩余' },
					],
				},
			],
		})
	},
}
</script>
<style lang="scss">
.dashbord-funds-surplus {
	.ant-tabs-bar {
		margin-bottom: 0;
	}

	.container {
		background: #fff;
		padding: 32px 24px;
	}
}
</style>
<template>
  <div class="tsAgency-members-page  content-panel">
    <a-button type="primary" class="ywh-btn mb32" @click="handleAdd"
      >新建培训</a-button
    >
    <searchForm
      :columns="searchFormColumns"
      @search="searchHandle"
    ></searchForm>
    <ztable2
      :detailColumns="detail_columns"
      :columns="table_columns"
      :dataSource="dataSource"
      @change="tableChangeHandle"
    >
      <span slot="action" slot-scope="text, record">
        <div class="editable-row-operations">
          <a href="javascript:void(0)" @click="handleEdit(record)">编辑</a>
          <a href="javascript:void(0)" @click="handleDelete(record._id)"
            >删除</a
          >
        </div>
      </span>
    </ztable2>

    <a-modal
      class="ywh-modal"
      :title="modalTitle"
      :visible="modalVisible"
      :confirm-loading="modalLoading"
      @ok="handleModalOk"
      @cancel="handleModalCancel"
      ok-text="确认"
      cancel-text="取消"
    >
      <zform :formConfig="form_config" :formData="{}" ref="modal-form"></zform>
    </a-modal>
  </div>
</template>

<script>
import tableMixin from "@/mixin/table.list.js";
import mixin from "./mixin.js";
export default {
  data() {
    return {
      searchFormColumns: [
        {
          props: "title",
          widgetType: "input",
          label: "培训主题",
          placeholder: "请输入",
        },
        {
          props: "type",
          widgetType: "select",
          label: "培训类型",
          placeholder: "请选择",
          options: [
            {
              label: "内部学习",
              key: 1,
            },
            {
              label: "街镇业务培训",
              key: 2,
            },
            {
              label: "市区街镇专项业务培训",
              key: 3,
            },
          ],
          style: {
            width: "200px",
          },
        },
      ],
    };
  },
  mixins: [tableMixin, mixin],
  methods: {
    // 查询列表接口api处理
    getListRequestHandle() {
      return {
        // 查询接口
        getApi: `/api/dailyWork/businessLearning/list`,
        usePagination: true,
      };
    },
    // 弹框提交接口api和params处理
    submitRequestHandle(formValue) {
      formValue.startTime = formValue.rangePicker[0];
      formValue.endTime = formValue.rangePicker[1];
      delete formValue.rangePicker;
      return {
        // 保存和更新接口
        postApi: `/api/dailyWork/businessLearning`,
        putApi: `/api/dailyWork/businessLearning/${this.editId}`,
        // 表单外的提交参数
        params: {},
      };
    },
    // 列表删除api处理
    deleteRequestHandle(recordId) {
      return {
        // 删除接口
        deleteApi: `/api/dailyWork/businessLearning/${recordId}`,
      };
    },
    beforeEdit(record) {
      record.rangePicker = [record.startTime, record.endTime];
      return record;
    },
  },
  created() {
    // 获取页面数据
    this.getList();
  },
  components: {},
};
</script>

<style lang="scss" scoped></style>

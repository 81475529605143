var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"home",staticClass:"home"},[_c('div',{staticClass:"edit-container"},[_c('div',{attrs:{"id":"editor"}}),_c('div',{staticClass:"ancillary-information"},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 5 },"wrapper-col":{ span: 12 }}},[_c('a-row',[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
									'title',
									{
										rules: [
											{
												required: true,
												message: '必填项',
											} ],
									} ]),expression:"[\n\t\t\t\t\t\t\t\t\t'title',\n\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\t\tmessage: '必填项',\n\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t]"}],attrs:{"placeholder":"请输入公告标题","maxLength":120}})],1)],1),_c('a-col',{attrs:{"span":2}},[_c('a-form-item',[_c('uploadFile',{directives:[{name:"decorator",rawName:"v-decorator",value:([
									'cover',
									{
										getValueFromEvent: _vm.uploadNormFile,
										rules: [
											{
												required: true,
												message: '必填项',
											} ],
									} ]),expression:"[\n\t\t\t\t\t\t\t\t\t'cover',\n\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\tgetValueFromEvent: uploadNormFile,\n\t\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\t\tmessage: '必填项',\n\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t]"}],attrs:{"item":{
									props: 'cover',
									uploadText: '长x宽(1440x1440)',
									maxUpload: 1,
								},"form":_vm.form}})],1)],1),_c('a-col',{attrs:{"span":18}},[_c('a-form-item',[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
									'introduction',
									{
										rules: [
											{
												required: true,
												message: '必填项',
											} ],
									} ]),expression:"[\n\t\t\t\t\t\t\t\t\t'introduction',\n\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\t\tmessage: '必填项',\n\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t]"}],attrs:{"placeholder":"请公告摘要","maxLength":120,"auto-size":{ minRows: 4, maxRows: 6 }}})],1)],1)],1)],1)],1)]),_c('div',{staticClass:"action-bar"},[_c('span',{staticClass:"current-word-count"},[_vm._v("当前字数: "+_vm._s(_vm.textLength))]),_c('div',[_c('a-button',{attrs:{"type":""},on:{"click":function($event){return _vm.saveBtn(0)}}},[_vm._v("保存")]),_c('a-button',{staticStyle:{"margin-left":"12px"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.saveBtn(1)}}},[_vm._v("保存并发布")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="tsAgency-members-page">
    <div class="ywh-small-card">
      <div class="item">
        <div class="title">接待记录[个]</div>
        <div class="content">
          <div class="data">{{ listRes.count || 0 }}</div>
        </div>
      </div>
      <div class="item">
        <div class="title">平均接待时间[分钟]</div>
        <div class="content">
          <div class="data">
            {{ listRes.timeTotal ? parseInt(listRes.timeTotal / 60) : 0 }}
          </div>
        </div>
      </div>
      <div class="item">
        <div class="title">本月接待数[个]</div>
        <div class="content">
          <div class="data">{{ listRes.monthCount || 0 }}</div>
        </div>
      </div>
      <div class="item">
        <div class="title">业委会值班表</div>
        <div class="content">
          <a href="javascript:void(0)" @click="openDutyModel">点击查看</a>
        </div>
      </div>
    </div>
    <div class=" content-panel">
      <a-button type="primary" class="ywh-btn mb32" @click="handleAdd"
        >发起接待</a-button
      >
      <searchForm
        :columns="searchFormColumns"
        @search="searchHandle"
      ></searchForm>
      <ztable2
        :detailColumns="detail_columns"
        :columns="table_columns"
        :dataSource="dataSource"
        @change="tableChangeHandle"
        :pagination="pagination"
      >
        <span slot="action" slot-scope="text, record">
          <div class="editable-row-operations">
            <a href="javascript:void(0)" @click="handleEdit(record, true)"
              >查看详情</a
            >
            <a
              href="javascript:void(0)"
              @click="handleEdit(record, true, 'upload')"
              >上传文件</a
            >
            <a href="javascript:void(0)" @click="handleDelete(record._id)"
              >删除</a
            >
          </div>
        </span>
      </ztable2>
    </div>

    <a-modal
      class="ywh-modal"
      :title="modalTitle"
      :visible="modalVisible"
      :confirm-loading="modalLoading"
      @ok="handleModalOk"
      @cancel="handleModalCancel"
      ok-text="确认"
      cancel-text="取消"
      width="800px"
      :footer="showModalBtn"
    >
      <zform :formConfig="form_config" :formData="{}" ref="modal-form"></zform>
    </a-modal>

    <a-modal
      class="ywh-modal"
      :title="modalTitle"
      :visible="dutyModalVisible"
      :confirm-loading="dutyModalLoading"
      @ok="dutyModalOk"
      @cancel="dutyModalCancel"
      ok-text="确认"
      cancel-text="取消"
      width="800px"
      :footer="showModalBtn"
    >
      <zform
        :formConfig="duty_form_config"
        :formData="{}"
        ref="duty-modal-form"
      ></zform>
    </a-modal>
  </div>
</template>

<script>
import tableMixin from "@/mixin/table.list.js";
import mixin from "./mixin.js";
import { getUOCUsers, getDutyList, putDutyList } from "@/api/community/UOC";
export default {
  data() {
    return {
      dutyModalVisible: false,
      dutyModalLoading: false,
      searchFormColumns: [
        {
          props: "description",
          widgetType: "input",
          label: "接待描述",
          placeholder: "请输入",
        },
        {
          props: "visitors",
          widgetType: "input",
          label: "来访人",
          placeholder: "请输入",
        },
        {
          props: "uocUser",
          widgetType: "select",
          options: [],
          label: "接待人",
          placeholder: "请输入",
        },
      ],
    };
  },
  mixins: [tableMixin, mixin],
  methods: {
    // 查询列表接口api处理
    getListRequestHandle() {
      return {
        // 查询接口
        getApi: `/api/dailyWork/regularReception/list`,
        usePagination: true,
      };
    },
    // 弹框提交接口api和params处理
    submitRequestHandle(formValue) {
      formValue.uocUser.username = formValue.uocUser.label;
      formValue.uocUser._id = formValue.uocUser.key;
      delete formValue.uocUser.key;
      delete formValue.uocUser.label;

      formValue.receptionStartTime = formValue.rangePicker[0];
      formValue.receptionEndTime = formValue.rangePicker[1];
      delete formValue.rangePicker;

      return {
        // 保存和更新接口
        postApi: `/api/dailyWork/regularReception`,
        putApi: `/api/dailyWork/regularReception/${this.editId}`,
        // 表单外的提交参数
        params: {},
      };
    },
    // 列表删除api处理
    deleteRequestHandle(recordId) {
      let _id = this.$route.query._id;
      return {
        // 删除接口
        deleteApi: `/api/dailyWork/regularReception/${recordId}`,
      };
    },
    beforeEdit(record) {
      record.uocUser.key = record.uocUser._id;
      record.uocUser.username = record.uocUser.label;
      delete record.uocUser._id;
      delete record.uocUser.label;

      record.rangePicker = [record.receptionStartTime, record.receptionEndTime];
      return record;
    },
    afterEdit(record, type) {
      if (type === "upload") {
        this.showModalBtn = undefined;
        this.form_config.columns.map((item) => {
          if (item.type === "oss-upload") {
            item.isDisabled = false;
          } else {
            item.isDisabled = true;
          }
        });
      }
    },
    openDutyModel() {
      this.dutyModalVisible = true;
      getDutyList().then((res) => {
        console.log(res);
        this.$refs["duty-modal-form"].updateForm({ ...res }, true);
      });
    },
    dutyModalOk() {
      this.$refs["duty-modal-form"]
        .zformValidateFieldsAndScroll()
        .then((values) => {
          this.dutyModalVisible = true;
          putDutyList(values)
            .then((res) => {
              this.$message.success("添加成功");
              this.dutyModalVisible = false;
            })
            .finally((res) => {
              this.dutyModalVisible = false;
            });
        });
    },
    dutyModalCancel() {
      this.dutyModalVisible = false;
    },
  },
  created() {
    // 获取页面数据
    this.getList();
    getUOCUsers().then((res) => {
      let options = res.list.map((item) => {
        return {
          text: item.username,
          value: item._id,
        };
      });
      let searchOptions = res.list.map((item) => {
        return {
          label: item.username,
          key: item._id,
        };
      });
      this.form_config.columns.map((item) => {
        if (item.props === "uocUser") {
          item.options = options;
        }
      });
      this.detail_columns.map((item) => {
        if (item.props === "uocUser") {
          item.mapping = options;
        }
      });
      this.searchFormColumns.map((item) => {
        if (item.props === "uocUser") {
          item.options = searchOptions;
        }
      });
    });
  },
  components: {},
};
</script>

<style lang="scss" scoped></style>

<template>
	<div class="dashbord-funds-use">
		<a-tabs type="card">
			<a-tab-pane key="1" tab="维修资金">
				<div
					class="container"
					id="fu_wx"
					style="width: 100%; height: 514px"
				></div>
			</a-tab-pane>
			<!-- <a-tab-pane key="2" tab="物业费收支">
				<div class="container">
					<div
						class="container"
						id="fu_wyf"
						style="width: 100%; height: 514px"
					></div>
				</div>
			</a-tab-pane> -->
		</a-tabs>
	</div>
</template>

<script>
// 引入 ECharts 主模块
var echarts = require('echarts/lib/echarts')
// 引入柱状图
require('echarts/lib/chart/line')
// 引入提示框和标题组件
require('echarts/lib/component/tooltip')
require('echarts/lib/component/title')
require('echarts/lib/component/legend')
// let theme = require('@/echarts/theme/ywh.line.json')
import theme from '@/echarts/theme/ywh.line.json'
echarts.registerTheme('ywhLine', theme)

export default {
	name: '',
	props: {
		data: {
			type: Array,
		},
	},
	data() {
		return {}
	},
	computed: {},
	methods: {},
	mounted() {
		// 基于准备好的dom，初始化echarts实例
		var myChart_fu_wx = echarts.init(
			document.getElementById('fu_wx'),
			'ywhLine'
		)
		// 绘制图表
		myChart_fu_wx.setOption({
			tooltip: {
				trigger: 'axis',
			},
			legend: {
				data: ['资金支出', '资金收入'],
			},
			grid: {
				left: '3%',
				right: '4%',
				bottom: '3%',
				containLabel: true,
			},
			toolbox: {
				feature: {
					saveAsImage: {},
				},
			},
			xAxis: {
				type: 'category',
				boundaryGap: false,
				data: ['10:00', '10:30', '11:00', '11:30', '12:00', '12:30'],
			},
			yAxis: {
				type: 'value',
			},
			series: [
				{
					name: '资金支出',
					type: 'line',
					stack: '总量',
					data: [120, 132, 101, 134, 90, 230],
				},
				{
					name: '资金收入',
					type: 'line',
					stack: '总量',
					data: [220, 182, 191, 234, 290, 330],
				},
			],
		})

		// 基于准备好的dom，初始化echarts实例
		var myChart_fu_wyf = echarts.init(
			document.getElementById('fu_wyf'),
			'ywhLine'
		)
		// 绘制图表
		myChart_fu_wyf.setOption({
			tooltip: {
				trigger: 'axis',
			},
			legend: {
				data: ['资金支出', '资金收入'],
			},
			grid: {
				left: '3%',
				right: '4%',
				bottom: '3%',
				containLabel: true,
			},
			toolbox: {
				feature: {
					saveAsImage: {},
				},
			},
			xAxis: {
				type: 'category',
				boundaryGap: false,
				data: ['10:00', '10:30', '11:00', '11:30', '12:00', '12:30'],
			},
			yAxis: {
				type: 'value',
			},
			series: [
				{
					name: '资金支出',
					type: 'line',
					stack: '总量',
					data: [120, 132, 101, 134, 90, 230],
				},
				{
					name: '资金收入',
					type: 'line',
					stack: '总量',
					data: [220, 182, 191, 234, 290, 330],
				},
			],
		})
	},
}
</script>
<style lang="scss">
.dashbord-funds-use {
	.ant-tabs-bar {
		margin-bottom: 0;
	}

	.container {
		background: #fff;
		padding: 32px 24px;
	}
}
</style>
<template>
  <div class="property-manager-page">
    <a-tabs default-active-key="1" @change="handleTabChange">
      <a-tab-pane key="1" tab="物业服务企业信息">
        <div v-if="dataList && !dataList[0].companyName">
          <a-empty>
            <span slot="description">
              暂无物业服务企业信息
              <a href="javascript:void(0)" @click="handleAdd">现在添加</a>
              <br/>
              档案管理需要，可选填
            </span>
          </a-empty>
        </div>
        <div v-if="dataList && dataList[0].companyName">
          <div class="ywh-desc mb32">
            <a-row>
              <a-col :span="5">
                <div class="ywh-desc-header">
                  <div class="title"></div>
                </div>
                <div class="ywh-desc-content">
                  <div class="desc-item">
                    <span class="label">物业服务企业名称：</span>
                    <span>{{ dataList[0].companyName }}</span>
                  </div>
                  <div class="desc-item">
                    <span class="label">企业办公地址：</span>
                    <span>{{ dataList[0].companyAddress }}</span>
                  </div>
                  <div class="mt40 status">
                    状态：<a href="javascript:void(0)">已归档</a>
                  </div>
                </div>
              </a-col>
              <a-col :span="5">
                <div class="ywh-desc-header"></div>
                <div class="ywh-desc-content">
                  <div class="desc-item">
                    <span class="label">企业联系电话：</span>
                    <span>{{ dataList[0].companyTel }}</span>
                  </div>
                </div>
              </a-col>
              <a-col :span="3" v-if="dataList[0].businessLicense[0]">
                <div class="ywh-desc-header">
                  <div class="title">营业执照</div>
                </div>
                <div class="ywh-desc-content">
                  <img
                    :src="dataList[0].businessLicense[0].url"
                    @click="$download(dataList[0].businessLicense[0].url)"
                    width="90%"
                    height="240"
                  />
                </div>
              </a-col>
              <a-col :span="3" v-if="dataList[0].certifications[0]">
                <div class="ywh-desc-header">
                  <div class="title">资质证书</div>
                </div>
                <div class="ywh-desc-content">
                  <img
                    :src="dataList[0].certifications[0].url"
                    @click="$download(dataList[0].certifications[0].url)"
                    width="90%"
                    height="240"
                  />
                </div>
              </a-col>
              <a-col :span="3" v-if="dataList[0].propertyManagerLicense[0]">
                <div class="ywh-desc-header">
                  <div class="title">物业经理证照</div>
                </div>
                <div class="ywh-desc-content">
                  <img
                    :src="dataList[0].propertyManagerLicense[0].url"
                    @click="
                      $download(dataList[0].propertyManagerLicense[0].url)
                    "
                    width="90%"
                    height="240"
                  />
                </div>
              </a-col>
              <a-col :span="3">
                <div class="tl-right">
                  <a-button
                    type="primary"
                    class="ywh-btn"
                    @click="handleEdit(dataList[0])"
                    >修改备案信息</a-button
                  >
                </div>
              </a-col>
            </a-row>
          </div>
          <!-- <searchForm
            :columns="searchFormColumns"
            @search="searchHandle"
          ></searchForm> -->
          <ztable2
            :detailColumns="manager_detail_columns"
            :columns="manager_table_columns"
            :dataSource="dataList"
            :pagination="false"
          >
            <span slot="action" slot-scope="text, record">
              <div class="editable-row-operations">
                <a href="javascript:void(0)" @click="handleEdit(record, true)"
                  >查看详情
                </a>
              </div>
            </span>
          </ztable2>
        </div>
      </a-tab-pane>
      <a-tab-pane key="2" tab="其他物业服务人信息">
        <div class="other-tabs" v-if="dataList && dataList[0]">
          <div>
            <div class="title">顾问服务合同</div>
            <div class="content" v-if="dataList[0].consultancyService[0]">
              <img
                width="100%"
                height="100%"
                :src="dataList[0].consultancyService[0].url"
              />
            </div>
            <div class="content" v-else>
              <a-empty
                ><span slot="description">暂无顾问服务合同</span></a-empty
              >
            </div>
          </div>
          <a-button
            type="primary"
            class="ywh-btn"
            @click="updateContract('consultancyService')"
            >更新合同信息</a-button
          >
        </div>
        <div class="other-tabs" v-if="dataList && dataList[0]">
          <div>
            <div class="title">自行管理方案</div>
            <div class="content" v-if="dataList[0].selfManagement[0]">
              <img
                width="100%"
                height="100%"
                :src="dataList[0].selfManagement[0].url"
              />
            </div>
            <div class="content" v-else>
              <a-empty
                ><span slot="description">暂无自行管理方案</span></a-empty
              >
            </div>
          </div>
          <a-button
            type="primary"
            class="ywh-btn"
            @click="updateContract('selfManagement')"
            >更新合同信息</a-button
          >
        </div>

        <!-- <div class="ywh-desc" style="padding-top: 40px">
          <a-row>
            <a-col :span="6">
              <div class="ywh-desc-header">
                <div class="title">顾问服务合同</div>
                <div
                  v-if="dataList[0].consultancyService[0]"
                  style="width:250px;height:250px;"
                >
                  <img
                    width="100%"
                    height="100%"
                    :src="dataList[0].consultancyService[0].url"
                  />
                </div>
              </div>
            </a-col>
            <a-col :span="6">
              <a-button
                type="primary"
                class="ywh-btn"
                @click="updateContract('consultancyService')"
                >更新合同信息</a-button
              >
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="6">
              <div class="ywh-desc-header">
                <div class="title">自行管理方案</div>
                <div v-if="dataList[0].selfManagement[0]">
                  <img width="90%" :src="dataList[0].selfManagement[0].url" />
                </div>
              </div>
            </a-col>
            <a-col :span="6">
              <a-button
                type="primary"
                class="ywh-btn"
                @click="updateContract('selfManagement')"
                >更新合同信息</a-button
              >
            </a-col>
          </a-row>
        </div> -->
        <a-form :form="form" v-show="false" ref="form">
          <a-form-item>
            <uploadFile
              v-decorator="['consultancyService', {}]"
              :item="{
                props: 'consultancyService',
                maxUpload: 1,
              }"
              :form="form"
              ref="consultancyService"
              @changeHandle="upladHandle"
              v-show="false"
            ></uploadFile>
          </a-form-item>
          <a-form-item>
            <uploadFile
              v-decorator="['selfManagement', {}]"
              :item="{
                props: 'selfManagement',
                maxUpload: 1,
              }"
              :form="form"
              ref="selfManagement"
              @changeHandle="upladHandle"
              v-show="false"
            ></uploadFile>
          </a-form-item>
        </a-form>
      </a-tab-pane>
    </a-tabs>

    <a-modal
      class="ywh-modal"
      :title="modalTitle"
      :visible="modalVisible"
      :confirm-loading="modalLoading"
      @ok="handleModalOk"
      @cancel="handleModalCancel"
      ok-text="确认"
      cancel-text="取消"
      :footer="showModalBtn"
    >
      <zform :formConfig="form_config" :formData="{}" ref="modal-form"></zform>
    </a-modal>
  </div>
</template>

<script>
import tableMixin from "@/mixin/table.list.js";
import mixin from "./mixin.js";
import { getPropertyManagerList } from "@/api/community/property";
import uploadFile from "@/components/zform/components/zossUpload.vue";
import request from "@/api/request";
export default {
  data() {
    return {
      dataList: null,
      activeTab: "1",
      params: {},
      searchFormColumns: [
        {
          props: "companyName",
          widgetType: "input",
          label: "物业服务企业名称",
          placeholder: "请输入",
        },
        {
          props: "status",
          widgetType: "select",
          label: "会议状态",
          placeholder: "请选择",
          options: [
            {
              label: "已归档",
              key: 1,
            },
            {
              label: "过期",
              key: 0,
            },
          ],
          style: {
            width: "200px",
          },
        },
      ],
    };
  },
  mixins: [tableMixin, mixin],
  methods: {
    getList() {
      let { params } = this;
      getPropertyManagerList({ type: this.activeTab, ...params }).then(
        (res) => {
          this.dataList = res.list;
        }
      );
    },
    // 弹框提交接口api和params处理
    submitRequestHandle(formValues) {
      this.editId = null;
      return {
        // 保存和更新接口
        postApi: `/api/propertyManager`,
        // 表单外的提交参数
        params: {
          type: 1,
        },
      };
    },
    // 列表删除api处理
    deleteRequestHandle(recordId) {
      let _id = this.$route.query._id;
      return {
        // 删除接口
        deleteApi: `/api/tsAgency/${_id}/members/${recordId}`,
      };
    },
    updateContract(type) {
      this.$refs.form.form.resetFields();
      this.$refs[type].triggerUpload();
    },
    upladHandle(result, item) {
      console.log(result, item);
      if (result[0].status !== "done") {
        return;
      }
      let params = {
        type: 2,
      };

      params[item.props] = result;
      request({
        url: "/api/propertyManager",
        method: "post",
        data: params,
      }).then((res) => {
        this.$message.success("更新成功");
        this.getList();
      });
    },
    handleTabChange(value) {
      this.activeTab = value;
      this.getList();
    },
    searchHandle(values) {
      this.params = {};
      for (const key in values) {
        if (values[key] !== undefined) {
          this.params[key] = values[key];
        }
      }
      this.getList();
    },
  },
  created() {
    // 获取页面数据
    this.getList();
    this.form = this.$form.createForm(this);
  },
  components: {
    uploadFile,
  },
};
</script>

<style lang="scss" scoped>
.other-tabs {
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
  > div {
    width: 250px;
  }
  width: 400px;
  .title {
    font-weight: bold;
    font-size: 16px;
    height: 52px;
    line-height: 52px;
  }
  .ywh-btn {
    margin-top: 52px;
  }
}
</style>

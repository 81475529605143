/*
 * @Author: Yin Xiang Zheng
 * @LastEditors: Yin Xiang Zheng
 * @LastEditTime: 2020-11-14 23:58:35
 */
import request from './request';

export function newsList(params) {
    return request({
        url: `/api/news/list`,
        method: 'get',
        params
    });
}


export function getDetail(id) {
    return request({
        url: `/api/news/detail/${id}`,
        method: 'get',
    });
}
<!--
 * @Author: Yin Xiang Zheng
 * @LastEditors: Yin Xiang Zheng
 * @LastEditTime: 2021-03-09 15:44:26
-->

<template>
	<div>
		<!-- 上传表决票 -->
		<a-modal
			title="上传表决票"
			:visible="visible"
			@ok="visible = false"
			@cancel="visible = false"
			:destroyOnClose="true"
			width="80%"
		>
			<div>
				<a-form layout="inline" :form="signingSituationForm">
					<a-form-item label="状态">
						<a-select
							v-decorator="['status', { initialValue: 1 }]"
							style="width: 120px"
						>
							<a-select-option :value="0">
								未上传
							</a-select-option>
							<a-select-option :value="1">
								已上传
							</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item>
						<a-button
							type=""
							style="margin-left: 8px"
							@click="resetHandle"
						>
							重置
						</a-button>
						<a-button
							type="primary"
							style="margin-left: 8px"
							@click="selHandle"
						>
							查询
						</a-button>
						<a-button
							style="margin-left: 8px"
							type="primary"
							icon="upload"
							:loading="uploadLoading"
							@click="uploadHandle"
							>上传图片</a-button
						>
						<span>
							{{ listLengthText }}
						</span>
					</a-form-item>
				</a-form>
			</div>
			<a-table
				style="margin-top: 16px"
				:pagination="false"
				:columns="columns"
				:data-source="data"
			>
				<span slot="filePath" slot-scope="text, record">
					{{
						record.uploadInfo
							? getFileName(record.uploadInfo.filePath)
							: '-'
					}}
				</span>
				<span slot="createAt" slot-scope="text, record">
					{{
						record.uploadInfo
							? moment(record.uploadInfo.createAt).format(
									'YYYY-MM-DD HH:mm:ss'
							  )
							: '-'
					}}
				</span>
				<div slot="action" slot-scope="text, record">
					<a @click="previewImg(record)">查看图片</a>
				</div>
			</a-table>
		</a-modal>
		<input
			id="s-file"
			@change="fileChange"
			name="file"
			type="file"
			multiple
			accept="image/*"
			style="display: none"
		/>
	</div>
</template>

<script>
import {
	getFlowVoteList,
	uploadFlowVoteInfoBatch,
	getFlowVoteUploadInfo,
} from '@/api/flow'
import { SigningSituationStatusMap } from '@/utils/variable'
import UpladFile from '@/components/zform/utils/uploadToAliOss.js'
import moment from 'moment'

const columns = [
	{
		title: '产业地址',
		with: 140,
		dataIndex: 'buildingInformation.address',
	},
	{
		title: '表决票号',
		with: 140,
		dataIndex: 'serialNumber',
	},
	{
		title: '文件名',
		dataIndex: 'filePath',
		key: 'filePath',
		scopedSlots: { customRender: 'filePath' },
	},
	{
		title: '上传人',
		dataIndex: 'uploadInfo.uploadByName',
	},
	{
		title: '上传时间',
		key: 'createAt',
		scopedSlots: { customRender: 'createAt' },
	},
	{
		title: '上传状态',
		dataIndex: 'uploadInfoStatus',
	},
	{
		title: '操作',
		with: 130,
		key: 'action',
		scopedSlots: { customRender: 'action' },
	},
]
export default {
	data() {
		return {
			uploadLoading: false,
			signingSituationForm: this.$form.createForm(this, {
				name: 'signingSituationForm',
			}),
			columns,
			SigningSituationStatusMap,
			visible: false,
			data: [],
			selectedRowKeys: [], // Check here to configure the default column
			selectedRow: [],
			loading: false,
		}
	},
	props: ['value', 'flowId'],
	watch: {
		value(newval) {
			this.visible = newval
		},
		visible(newval) {
			if (newval) {
				this.getTableData()
			}
			this.$emit('input', newval)
		},
	},
	computed: {
		listLengthText() {
			let val = this.signingSituationForm.getFieldsValue()
			this.loading = true
			let { status } = val
			status === undefined ? (status = 1) : ''
			return `${status === 1 ? '已上传' : '未上传'}: ${
				this.data.length
			}张`
		},
	},
	methods: {
		moment,
		previewImg(record) {
			const { uploadInfo } = record
			if (!uploadInfo) {
				return
			}
			const { filePath } = uploadInfo
			window.open(filePath)
		},
		uploadHandle() {
			document.getElementById('s-file').click()
		},
		async fileChange(e) {
			this.uploadLoading = true
			const files = e.target.files
			let uploadedFiles = [],
				uploadedFailureFile = []

			if (files.length >= 50) {
				this.$message.warning('最多同时上传50张图片，请重新选择！')
				this.uploadLoading = false
				return
			}
			for (const file of files) {
				try {
					const { url } = await UpladFile(file)
					uploadedFiles.push(url)
				} catch (error) {
					const { name } = file
					console.log('error', error)
					uploadedFailureFile.push(name)
				}
			}
			if (uploadedFailureFile && uploadedFailureFile.length > 0) {
				this.waringUploadImgHandler(
					'以下图片上传失败！',
					uploadedFailureFile
				)
			}
			uploadFlowVoteInfoBatch(this.flowId, {
				uploadedFiles,
			})
				.then((res) => {
					this.uploadLoading = false
					this.getTableData()
					const { detectFailure } = res
					if (detectFailure && detectFailure.length > 0) {
						this.waringUploadImgHandler(
							'以下图片解析二维码失败！',
							detectFailure
						)
					}
				})
				.catch((err) => {
					console.log(err)
					this.uploadLoading = false
					this.getTableData()
				})
		},
		getFileName(params) {
			const host = 'https://huhuiyun.oss-cn-shanghai.aliyuncs.com/static/'
			if (!params || params.indexOf(host) === -1) {
				return params
			}
			return params.split(
				'https://huhuiyun.oss-cn-shanghai.aliyuncs.com/static/'
			)[1]
		},
		waringUploadImgHandler(title, detectFailure) {
			const _this = this
			this.$notification.warning({
				duration: 0,
				message: title,
				description: (
					<div>
						{detectFailure.map((item) => {
							return (
								<div>
									<a href={item}>
										{_this.getFileName(item) || item}
									</a>
								</div>
							)
						})}
					</div>
				),
			})
		},
		selHandle() {
			this.getTableData()
		},
		resetHandle() {
			this.params = {
				pageNum: 1,
				pageSize: 10,
			}
			this.signingSituationForm.resetFields()
			this.getTableData()
		},
		getTableData() {
			let val = this.signingSituationForm.getFieldsValue()
			this.loading = true
			let { status } = val
			status === undefined ? (status = 1) : ''
			getFlowVoteUploadInfo(this.flowId, {
				isUpload: status === 1 ? true : null,
			})
				.then((res) => {
					this.loading = false
					this.data = res.list.map((item) => {
						item.uploadInfoStatus =
							status === 1 ? '已上传' : '未上传'
						return item
					})
				})
				.catch((err) => {
					this.loading = false
					console.log('err', err)
				})
		},
	},
}
</script>
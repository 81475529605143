import request from '@/api/request'
// 获取街道列表
export function getStreetList() {
    return request({
        url: '/api/street/list',
        method: 'get'
    })
}
// 用户注册
export function registered(data) {
    return request({
        url: '/api/auth/registered',
        method: 'post',
        data
    })
}
export default {
  data() {
    return {
      table_columns: [
        {
          dataIndex: "type",
          title: "培训类型",
        },
        {
          dataIndex: "title",
          title: "培训主题",
        },
        {
          dataIndex: "remark",
          title: "备注",
          width: 600,
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 120,
        },
      ],
      detail_columns: [
        {
          props: "title",
          type: "text",
        },
        {
          props: "type",
          type: "text",
          mapping: [
            {
              text: "内部学习",
              value: 1,
            },
            {
              text: "街镇业务培训",
              value: 2,
            },
            {
              text: "市区街镇专项业务培训",
              value: 3,
            },
          ],
        },
        {
          type: "text",
          props: "remark",
          lineClamp: 1,
          width: "580px",
          popoverContent: (dataSource) => {
            return {
              content: <div>{dataSource.remark}</div>,
            };
          },
        },
      ],
      form_config: {
        col: 24,
        formLayout: "vertical",
        textAlign: "left",
        columns: [
          {
            type: "select",
            props: "type",
            label: "培训类型",
            placeholder: "请选择",
            options: [
              {
                text: "内部学习",
                value: 1,
              },
              {
                text: "街镇业务培训",
                value: 2,
              },
              {
                text: "市区街镇专项业务培训",
                value: 3,
              },
            ],
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            type: "input",
            props: "title",
            label: "培训主题",
            placeholder: "请输入",
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            label: "日期",
            type: "datepicker",
            props: "rangePicker",
            placeholder: ["开始时间", "结束时间"],
            datepickerType: "range-picker",
            format: "YYYY-MM-DD HH:mm",
            showTime: true,
            rules: [
              {
                required: true,
              },
            ],
          },
          /*{
            label: "开始时间",
            type: "datepicker",
            props: "startTime",
            datepickerType: "date-picker",
            placeholder: "请选择",
            format: "YYYY-MM-DD HH:mm:ss",
            allowClear: false,
            showTimeDefaultValue: {
              format: "HH:mm:ss",
            },
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            label: "结束时间",
            type: "datepicker",
            props: "endTime",
            datepickerType: "date-picker",
            placeholder: "请选择",
            format: "YYYY-MM-DD HH:mm:ss",
            allowClear: false,
            showTimeDefaultValue: {
              format: "HH:mm:ss",
            },
            rules: [
              {
                required: true,
              },
            ],
          },*/
          {
            type: "textarea",
            props: "remark",
            label: "备注",
            placeholder: "请输入",
            rows: 4,
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            col: 12,
            props: "recordFiles",
            type: "oss-upload",
            label: "培训记录",
            maxUpload: 1,
            listType: "picture-card",
          },
          {
            col: 12,
            props: "otherFiles",
            type: "oss-upload",
            label: "其他文件",
            maxUpload: 1,
            listType: "picture-card",
          },
        ],
      },
    };
  },
};

import moment from "moment";
export default {
  data() {
    return {
      table_columns: [
        {
          dataIndex: "numbering",
          title: "项目编号",
        },
        {
          dataIndex: "projectName",
          title: "项目名称",
        },
        {
          dataIndex: "createdAt",
          title: "发起时间",
        },
        {
          dataIndex: "amount",
          title: "使用金额",
        },
        {
          dataIndex: "type",
          title: "类型",
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 120,
        },
      ],
      detail_columns: [
        {
          props: "type",
          type: "text",

          mapping: [
            {
              text: "收入",
              value: 1,
            },
            {
              text: "支出",
              value: 2,
            },
          ],
        },
        {
          props: "createdAt",
          type: "time",
          format: "YYYY-MM-DD HH:mm",
        },
      ],

      form_config: {
        col: 12,
        formLayout: "vertical",
        textAlign: "left",
        expression: [
          {
            rule: `props.type == 1`,
            hideProps: [
              "maintenanceContract",
              "engineeringScheme",
              "constructionContract",
              "evaluationDocument",
              "authorizedAmountSpecification",
              "UOCMeetingSpecification",
              "appraisalSheet",
              "emergencyRepairSpecification",
              "dealer",
            ],
            showProps: [
              "specificationCertificate",
              "MFRecord",
              "MFSemiAnnualStatement",
            ],
          },
          {
            rule: `props.type == 2`,
            showProps: [
              "maintenanceContract",
              "engineeringScheme",
              "constructionContract",
              "evaluationDocument",
              "authorizedAmountSpecification",
              "MFRecord",
              "MFSemiAnnualStatement",
              "UOCMeetingSpecification",
              "appraisalSheet",
              "emergencyRepairSpecification",
              "dealer",
            ],
            hideProps: ["specificationCertificate"],
          },
          {
            rule: `props.authorizedAmountSpecification==1`,
            showProps: ["meetingPublicAnnouncement"],
          },
          {
            rule: `props.authorizedAmountSpecification==2`,
            hideProps: ["meetingPublicAnnouncement"],
          },
          {
            rule: `props.emergencyRepairSpecification==1`,
            showProps: ["appraisalSheet"],
          },
          {
            rule: `props.emergencyRepairSpecification==2`,
            hideProps: ["appraisalSheet"],
          },
        ],
        columns: [
          {
            type: "select",
            props: "type",
            label: "类型",
            defaultValue: 1,
            options: [
              {
                text: "收入",
                value: 1,
              },
              {
                text: "支出",
                value: 2,
              },
            ],
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            type: "input",
            props: "projectName",
            label: "项目名称",
            placeholder: "请输入",

            rules: [
              {
                required: true,
              },
            ],
          },
          {
            type: "input-number",
            props: "amount",
            label: "使用金额",
            placeholder: "请输入",
            width: "100%",
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            type: "select",
            props: "uocMeeting",
            label: "会议记录",
            placeholder: "请选择",
            options: [],
            rules: [
              {
                required: true,
              },
            ],
          },

          {
            col: 10,
            props: "specificationCertificate",
            type: "oss-upload",
            label: "符合业主大会决议同意的工程是否程序规范证明",
            maxUpload: 1,
            listType: "picture-card",
            rules: [
              {
                required: true,
              },
            ],
            isHidden: true,
          },
          {
            col: 8,
            props: "MFSemiAnnualStatement",
            type: "oss-upload",
            label: "维修资金系统半年度结算单",
            maxUpload: 1,
            listType: "picture-card",
            rules: [
              {
                required: true,
              },
            ],
            isHidden: true,
          },
          {
            col: 6,
            props: "MFRecord",
            type: "oss-upload",
            label: "维修资金使用记录",
            maxUpload: 1,
            listType: "picture-card",
            rules: [
              {
                required: true,
              },
            ],
            isHidden: true,
          },

          // 支出
          {
            col: 8,
            props: "engineeringScheme",
            type: "oss-upload",
            label: "工程方案",
            maxUpload: 1,
            listType: "picture-card",
            rules: [
              {
                required: true,
              },
            ],
            isHidden: true,
          },
          {
            col: 8,
            props: "maintenanceContract",
            type: "oss-upload",
            label: "维保合同",
            maxUpload: 1,
            listType: "picture-card",
            rules: [
              {
                required: true,
              },
            ],
            isHidden: true,
          },
          {
            col: 6,
            props: "constructionContract",
            type: "oss-upload",
            label: "施工合同",
            maxUpload: 1,
            listType: "picture-card",
            rules: [
              {
                required: true,
              },
            ],
            isHidden: true,
          },
          {
            col: 8,
            type: "input",
            props: "dealer",
            label: "交易商户",
            placeholder: "请输入",

            rules: [
              {
                required: true,
              },
            ],
          },
          {
            col: 12,
            props: "evaluationDocument",
            type: "oss-upload",
            label: "审价文件（不是必填 五万以上【含】必须要上传）",
            maxUpload: 1,
            listType: "picture-card",
            rules: [
              {
                required: true,
              },
            ],
            isHidden: true,
          },
          {
            col: 6,
            props: "MFRecord",
            type: "oss-upload",
            label: "维修资金使用记录",
            maxUpload: 1,
            listType: "picture-card",
            rules: [
              {
                required: true,
              },
            ],
            isHidden: true,
          },
          {
            col: 6,
            props: "MFSemiAnnualStatement",
            type: "oss-upload",
            label: "维修资金系统半年度结算单",
            maxUpload: 1,
            listType: "picture-card",
            rules: [
              {
                required: true,
              },
            ],
            isHidden: true,
          },
          {
            type: "radioGroup",
            label: "符合业主大会决议同意的工程是否程序规范",
            title: "partDisabled",
            props: "UOCMeetingSpecification",
            options: [
              { label: "是", value: 1 },
              { label: "否", value: 2 },
            ],
            defaultValue: 2,
            isHidden: true,
          },
          {
            col: 24,
            type: "radioGroup",
            label: "符合直接授权金额的工程是否程序规范",
            title: "partDisabled",
            props: "authorizedAmountSpecification",
            options: [
              { label: "是", value: 1 },
              { label: "否", value: 2 },
            ],
            defaultValue: 2,
            isHidden: true,
          },
          {
            col: 24,
            props: "meetingPublicAnnouncement",
            type: "oss-upload",
            label: "业主大会表决相关公示公告",
            maxUpload: 1,
            listType: "picture-card",
            rules: [
              {
                required: true,
              },
            ],
            isHidden: true,
          },

          {
            col: 24,
            type: "radioGroup",
            label: "符合紧急维修类型的工程是否程序规范",
            title: "partDisabled",
            props: "emergencyRepairSpecification",
            options: [
              { label: "是", value: 1 },
              { label: "否", value: 2 },
            ],
            defaultValue: 2,
            isHidden: true,
          },
          {
            col: 24,
            props: "appraisalSheet",
            type: "oss-upload",
            label: "相关安检部门鉴定单",
            maxUpload: 1,
            listType: "picture-card",
            rules: [
              {
                required: true,
              },
            ],
            isHidden: true,
          },
          {
            type: "textarea",
            props: "remark",
            label: "备注",
            col: 24,
            placeholder: "请输入",
            rules: [
              {
                required: true,
              },
            ],
          },
          // {
          //   type: "input",
          //   props: "dealer",
          //   label: "交易商户",
          //   placeholder: "请输入",
          //   rules: [
          //     {
          //       required: true,
          //     },
          //   ],
          // },
        ],
      },
      modalColumns: [
        {
          col: 24,
          type: "input-number",
          props: "maintenanceFundTotal",
          label: "维修基金首期总额",
          placeholder: "请输入",
          width: "100%",
          rules: [
            {
              required: true,
            },
          ],
        },
      ],
    };
  },
};

<!--
 * @Author: Yin Xiang Zheng
 * @LastEditors: Yin Xiang Zheng
 * @LastEditTime: 2021-02-05 15:17:29
-->
<!--
 * @Author: Yin Xiang Zheng
 * @LastEditors: Yin Xiang Zheng
 * @LastEditTime: 2021-01-23 20:15:59
-->
<template>
	<div class="tsAgency-members-page content-panel">
		<div class="cw-title">
			<h3>会议工作台</h3>
			<a-button
				type="primary"
				class="ywh-btn"
				@click="$router.push('/conferenceWorkbench/add')"
				>创建会议</a-button
			>
		</div>
		<searchForm :columns="searchFormColumns" @search="searchHandle">
		</searchForm>
		<ztable2
			:scroll="{ x: 1300 }"
			:detailColumns="detail_columns"
			:columns="table_columns"
			:dataSource="dataSource"
			@change="tableChangeHandle"
			:pagination="pagination"
		>
			<span slot="action" slot-scope="text, record">
				<div class="editable-row-operations">
					<a href="javascript:void(0)" @click="goDetailHandle(record)"
						>工作台</a
					>
				</div>
			</span>
		</ztable2>

		<a-modal
			class="ywh-modal"
			:title="modalTitle"
			:visible="modalVisible"
			:confirm-loading="modalLoading"
			@ok="handleModalOk"
			@cancel="handleModalCancel"
			ok-text="确认"
			cancel-text="取消"
			:footer="showModalBtn"
		>
			<zform :formConfig="form_config" :formData="{}" ref="modal-form">
			</zform>
		</a-modal>
	</div>
</template>

<script>
import tableMixin from '@/mixin/table.list.js'
import mixin from './mixin.js'
import request from '@/api/request'
export default {
	data() {
		return {
			params: {
				pageNum: 1,
				pageSize: 10,
				type: 1,
			},
			searchFormColumns: [
				{
					props: 'theme',
					widgetType: 'input',
					label: '会议名称',
					placeholder: '请输入',
				},
			],
		}
	},
	mixins: [tableMixin, mixin],
	methods: {
		goDetailHandle(record) {
			this.$router.push(`/conferenceWorkbench/detail/${record._id}`)
		},
		// 查询列表接口api处理
		getListRequestHandle() {
			return {
				// 查询接口
				getApi: `/api/flow/list`,
				usePagination: true,
			}
		},
		// 弹框提交接口api和params处理
		submitRequestHandle(formValue) {
			formValue.participants = formValue.participants.map((item) => {
				return {
					username: item.label,
					_id: item.key,
				}
			})
			formValue.specialInvitation = formValue.specialInvitation.map(
				(item) => {
					return {
						username: item.label,
						_id: item.key,
					}
				}
			)
			return {
				// 保存和更新接口
				postApi: `/api/dailyWork/uocMeeting`,
				putApi: `/api/dailyWork/uocMeeting/confirm/${this.editId}`,
				// 表单外的提交参数
				params: {},
			}
		},
		// 列表删除api处理
		deleteRequestHandle(recordId) {
			return {
				// 删除接口
				deleteApi: `/api/dailyWork/uocMeeting/${recordId}`,
			}
		},
		beforeEdit(record) {
			console.log(record)
			record.participants = record.participants.map((item) => {
				return {
					label: item.username,
					key: item._id,
				}
			})
			record.specialInvitation = record.specialInvitation.map((item) => {
				return {
					label: item.username,
					key: item._id,
				}
			})
			return record
		},
		afterEdit(record, type) {
			if (type === 'confirm') {
				this.showModalBtn = undefined
				this.form_config.columns.map((item) => {
					if (
						item.props === 'minutes' ||
						item.props === 'otherFiles' ||
						item.props === 'archiveRecord'
					) {
						item.isDisabled = false
					} else {
						item.isDisabled = true
					}
				})
			}
		},
	},
	created() {
		// 获取页面数据
		this.getList()
		// 获取业委会成员
		// getUOCUsers().then((res) => {
		// 	console.log(res.list)
		// 	let options = res.list.map((item) => {
		// 		return {
		// 			text: item.username,
		// 			value: item._id,
		// 		}
		// 	})
		// 	this.form_config.columns.map((item) => {
		// 		if (item.props === 'participants') {
		// 			item.options = options
		// 		}
		// 	})
		// 	this.detail_columns.map((item) => {
		// 		if (item.props === 'participants') {
		// 			item.mapping = options
		// 		}
		// 	})
		// })
		//
		request({
			url: '/api/tsAgency/list/1',
			method: 'get',
		}).then((res) => {
			let options = res.list.map((item) => {
				return {
					text: item.name,
					value: item._id,
				}
			})
			this.form_config.columns.map((item) => {
				if (item.props === 'specialInvitation') {
					item.options = options
				}
			})
			this.detail_columns.map((item) => {
				if (item.props === 'specialInvitation') {
					item.mapping = options
				}
			})
		})
	},
	components: {},
	watch: {
		modalVisible(newVal) {
			if (newVal) {
				if (
					this.record &&
					(this.record.status === 3 || this.record.status === 4)
				) {
					this.form_config.columns.map((item) => {
						if (
							item.props === 'minutes' ||
							item.props === 'otherFiles' ||
							item.props === 'archiveRecord'
						) {
							item.isHidden = false
						}
					})
				} else {
					this.form_config.columns.map((item) => {
						if (
							item.props === 'minutes' ||
							item.props === 'otherFiles' ||
							item.props === 'archiveRecord'
						) {
							item.isHidden = true
						}
					})
				}
			}
		},
	},
}
</script>

<style lang="scss">
.tsAgency-members-page {
	.ant-table-title {
		display: none;
	}
}
.cw-title {
	display: flex;
	justify-content: space-between;
	margin-bottom: 12px;
}
</style>

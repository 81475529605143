<!--
 * @Author: Yin Xiang Zheng
 * @LastEditors: Yin Xiang Zheng
 * @LastEditTime: 2021-02-27 20:12:42
-->
<template>
	<div class="pi-content">
		<div
			v-for="(item, index) of fileList"
			:key="index"
			class="list-picture-card-container"
		>
			<div class="lpcc-list-item">
				<div class="lpcc-list-item-info">
					<span
						><a
							:src="item"
							target="_blank"
							rel="noopener noreferrer"
							class="ant-upload-list-item-thumbnail"
							><img
								:src="item"
								alt="image.png"
								class="ant-upload-list-item-image" /></a
					></span>
				</div>
				<span class="lpcc-list-item-actions"
					><a
						:src="item"
						target="_blank"
						rel="noopener noreferrer"
						title="预览文件"
						@click="handlePreview(item)"
						><i
							aria-label="图标: eye-o"
							class="anticon anticon-eye-o"
							><svg
								viewBox="64 64 896 896"
								data-icon="eye"
								width="1em"
								height="1em"
								fill="currentColor"
								aria-hidden="true"
								focusable="false"
								class=""
							>
								<path
									d="M942.2 486.2C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 0 0 0 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM512 766c-161.3 0-279.4-81.8-362.7-254C232.6 339.8 350.7 258 512 258c161.3 0 279.4 81.8 362.7 254C791.5 684.2 673.4 766 512 766zm-4-430c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm0 288c-61.9 0-112-50.1-112-112s50.1-112 112-112 112 50.1 112 112-50.1 112-112 112z"
								></path></svg></i></a
				></span>
			</div>
		</div>
		<a-modal
			:visible="previewVisible"
			:footer="null"
			width="60%"
			@cancel="handleCancel"
		>
			<img alt="example" style="width: 100%" :src="previewImage" />
		</a-modal>
	</div>
</template>

<script>
export default {
	name: 'PreviewImg',
	data() {
		return {
			previewVisible: false,
			previewImage: null,
		}
	},
	props: ['fileList'],
	methods: {
		handleCancel() {
			this.previewVisible = false
		},
		async handlePreview(file) {
			this.previewImage = file
			this.previewVisible = true
		},
	},
}
</script>

<style lang="less" >
.pi-content {
	display: flex;
}
.list-picture-card-container {
	width: 104px;
	height: 104px;
	margin: 0 8px 8px 0;
	.lpcc-list-item {
		position: relative;
		padding: 8px;
		width: 104px;
		height: 104px;
		border: 1px solid #d9d9d9;
		border-radius: 4px;
		.lpcc-list-item-info {
			position: relative;
			height: 100%;
			overflow: hidden;
			span {
				display: block;
				width: 100%;
				height: 100%;
				a {
					position: static;
					display: block;
					width: 100%;
					height: 100%;
					top: 8px;
					left: 8px;
					font-size: 26px;
					line-height: 54px;
					text-align: center;
					opacity: 0.8;
					img {
						position: static;
						display: block;
						width: 100%;
						height: 100%;
						object-fit: cover;
						overflow: hidden;
					}
				}
			}
		}
	}
	.lpcc-list-item-actions {
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 10;
		white-space: nowrap;
		transform: translate(-50%, -50%);
		opacity: 0;
		transition: all 0.3s;
		a {
			color: white !important;
		}
	}
	&:hover {
		.lpcc-list-item-info {
			background-color: rgba(0, 0, 0, 0.5);
		}
		.lpcc-list-item-actions {
			opacity: 1;
		}
	}
}
</style>
export default {
  data() {
    return {
      bigFloor_table_columns: [
        {
          dataIndex: "index",
          title: "序号",
          width: 70,
        },
        {
          dataIndex: "ownersGroup",
          title: "业主小组",
          width: 100,
        },
        {
          dataIndex: "building",
          title: "幢/号",
          width: 100,
        },
        {
          dataIndex: "unit",
          title: "单元",
          width: 100,
        },
        {
          dataIndex: "address",
          title: "产业地址",
          width: 200,
        },
        {
          dataIndex: "area",
          title: "建筑面积",
          width: 100,
        },
        {
          dataIndex: "type",
          title: "产业类型",
          width: 120,
        },
        {
          dataIndex: "votingRights",
          title: "投票权",
          width: 100,
        },
        {
          dataIndex: "phone",
          title: "手机号",
          width: 180,
        },
        {
          dataIndex: "IDVerification",
          title: "身份证、不动产证验证",
          width: 180,
        },

        {
          dataIndex: "witness",
          title: "见证人（楼组长、业主）",
          width: 200,
        },
        {
          dataIndex: "residentManagement",
          title: "居民管理",
          width: 100,
        },
        {
          dataIndex: "remark",
          title: "备注",
          width: 100,
        },
        {
          dataIndex: "action",
          title: "操作",
          fixed: 'right'
        },
      ],
      bigFloor_detail_columns: [],
      bigFloor_form_columns: {
        col: 24,
        formLayout: "vertical",
        textAlign: "left",
        columns: [
          {
            props: "excelUrl",
            type: "oss-upload",
            label: "小区大楼信息",
            maxUpload: 1,
            listType: "picture-card",
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            props: "houseHoldUrl",
            type: "oss-upload",
            label: "业主大会分户文件",
            maxUpload: 1,
            listType: "picture-card",
            rules: [
              {
                required: true,
              },
            ],
          },
        ],
      },
      edit_bigFloor_form_columns: {
        col: 24,
        formLayout: "vertical",
        textAlign: "left",
        columns: [
          {
            props: "ownersGroup",
            type: "input",
            label: "业主小组",
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            props: "address",
            type: "input",
            label: "产业地址",
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            props: "area",
            type: "input-number",
            label: "建筑面积",
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            props: "type",
            type: "input",
            label: "产业类型",
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            props: "votingRights",
            type: "input",
            label: "投票权",
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            props: "phone",
            type: "input",
            label: "手机号",
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            props: "IDVerification",
            type: "input",
            label: "身份证、不动产证验证",
          },
          {
            props: "witness",
            type: "input",
            label: "见证人（楼组长、业主）",
          },
          {
            props: "residentManagement",
            type: "input",
            label: "居民管理",
          },
          {
            props: "remark",
            type: "input",
            label: "备注",
          },
        ],
      },
      asset_table_columns: [
        {
          dataIndex: "index",
          title: "序号",
        },
        {
          dataIndex: "deviceCode",
          title: "设备编号",
        },
        {
          dataIndex: "deviceName",
          title: "设备名称",
        },
        {
          dataIndex: "specificationModel",
          title: "规格型号",
        },
        {
          dataIndex: "activationTime",
          title: "启用时间",
        },
        {
          dataIndex: "address",
          title: "使用地点",
        },
        {
          dataIndex: "updateTime",
          title: "更新时间",
        },
      ],
      asset_detail_columns: [],
      asset_form_columns: {
        col: 24,
        formLayout: "vertical",
        textAlign: "left",
        columns: [
          {
            props: "excelUrl",
            type: "oss-upload",
            label: "上传设备/设施模版文件",
            maxUpload: 1,
            listType: "picture-card",
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            props: "assetListUrl",
            type: "oss-upload",
            label: "小区资产清单",
            maxUpload: 1,
            listType: "picture-card",
            rules: [
              {
                required: true,
              },
            ],
          },
        ],
      },
      table_columns: [
        {
          dataIndex: "fileList",
          title: "文档名称",
        },
        {
          dataIndex: "createdBy",
          title: "创建人",
        },
        {
          dataIndex: "createdAt",
          title: "创建时间",
        },
        {
          dataIndex: "remark",
          title: "备注",
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 130,
        },
      ],
      detail_columns: [
        { type: "time", props: "createdAt", format: "YYYY-MM-DD" },
      ],
      form_config: {
        col: 24,
        formLayout: "vertical",
        textAlign: "left",
        columns: [
          {
            props: "fileList",
            type: "oss-upload",
            label: "文档",
            maxUpload: 1,
            listType: "picture-card",
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            rows: 8,
            label: '备注',
            props: 'remark',
            type: 'textarea',
          },
        ],
      },
    };
  },
};

<template>
	<div class="dashbord-banner">
		<a-carousel arrows :autoplay="true">
			<div
				slot="prevArrow"
				slot-scope="props"
				class="custom-slick-arrow"
				style="left: 10px;z-index: 9;"
			>
				<a-icon type="left-circle"  />
			</div>
			<div
				slot="nextArrow"
				slot-scope="props"
				class="custom-slick-arrow"
				style="right: 10px"
			>
				<a-icon  type="right-circle" />
			</div>
			<div
				v-for="(item, index) of newsBannerList"
				:key="index"
				@click="goDetail(item._id)"
			>
				<img :src="item.cover" />
			</div>
		</a-carousel>
	</div>
</template>

<script>
import { newsList } from '@/api/news'

export default {
	name: '',
	props: {
		data: {},
	},
	data() {
		return {
			newsBannerList: [],
		}
	},
	computed: {},
	mounted() {
		newsList({ pageNum: 1, pageSize: 999999 }).then((res) => {
			const maxLen = 7
			let list = res.list
			this.newsBannerList = list
			return
			if (list.length >= maxLen) {
				this.newsBannerList = list
				return
			}
			for (let index = 0; index < maxLen - list.length; index++) {
				list.push(res.list[0])
			}

			this.newsBannerList = list
		})
	},
	methods: {
		goDetail(_id) {
			this.$router.push({
				path: '/newsDetail',
				query: { _id },
			})
		},
	},
}
</script>
<style lang="scss">
.ant-carousel {
	.slick-slide {
		text-align: center;
		height: 380px;
		background: #364d79;
		overflow: hidden;
		position: relative;
		border-radius: 8px;
		cursor: pointer;
		div {
			width: 100%;
			height: 100%;
			border-radius: 8px;
		}
		img {
			width: 100%;
			height: 100%;
			border-radius: 8px;
			cursor: pointer;
		}
	}
	.custom-slick-arrow {
		width: 25px;
		height: 25px;
		font-size: 25px;
		color: #fff;
		background-color: rgba(31, 45, 61, 0.11);
		opacity: 0.3;
	}
	.custom-slick-arrow:before {
		display: none;
	}
	.custom-slick-arrow:hover {
		opacity: 0.5;
	}
	.slick-slide h3 {
		color: #fff;
		position: absolute;
		left: 10px;
		bottom: 10px;
		padding: 10px;
		background: rgba(0, 0, 0, 0.2);
		border-radius: 8px;
	}
}
</style>
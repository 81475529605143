import moment from "moment";
export default {
  data() {
    return {
      table_columns: [
        {
          dataIndex: "projectName",
          title: "名称",
        },
        {
          dataIndex: "type",
          title: "类型",
        },
        {
          dataIndex: "createdAt",
          title: "创建时间",
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 120,
        },
      ],
      detail_columns: [
        {
          props: "type",
          type: "text",
          mapping: [
            {
              text: "专项维修资金",
              value: 1,
            },
            {
              text: "公共收益",
              value: 2,
            },
            {
              text: "办公经费",
              value: 3,
            },
            {
              text: "物业管理费",
              value: 4,
            },
          ],
        },
        { type: "time", props: "createdAt", format: "YYYY-MM-DD" },
      ],
      form_config: {
        col: 12,
        formLayout: "vertical",
        textAlign: "left",
        columns: [
          {
            type: "input",
            props: "projectName",
            label: "名称",
            placeholder: "请输入",

            rules: [
              {
                required: true,
              },
            ],
          },
          {
            type: "select",
            props: "type",
            label: "类型",
            options: [
              {
                text: "专项维修资金",
                value: 1,
              },
              {
                text: "公共收益",
                value: 2,
              },
              {
                text: "办公经费",
                value: 3,
              },
              {
                text: "物业管理费",
                value: 4,
              },
            ],
            rules: [
              {
                required: true,
              },
            ],
          },

          {
            props: "auditReport",
            type: "oss-upload",
            label: "审计报告",
            maxUpload: 1,
            listType: "picture-card",
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            props: "publicPictures",
            type: "oss-upload",
            label: "公示图片",
            maxUpload: 1,
            listType: "picture-card",
            rules: [
              {
                required: true,
              },
            ],
          },

          {
            type: "textarea",
            props: "remark",
            label: "备注",
            col: 24,
            placeholder: "请输入",
            rows: 5,
            rules: [
              {
                required: true,
              },
            ],
          },
        ],
      },
    };
  },
};

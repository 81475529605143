/*
 * @Author: Yin Xiang Zheng
 * @LastEditors: Yin Xiang Zheng
 * @LastEditTime: 2021-03-09 16:16:50
 */
import request from "@/api/request";

// 获取流程列表
export function getFlowList(data) {
    return request({
        url: "/api/flow/list",
        method: "get",
        params: data,
    });
}

// 创建流程 type 必填
export function createFlow(data) {
    return request({
        url: "/api/flow",
        method: "post",
        data,
    });
}

// 获取流程详情
export function getDetail(id) {
    return request({
        url: `/api/flow/${id}/detail`,
        method: "get",
    });
}


// 根据类型获取流程节点
export function getFlowTypeNode(params) {
    return request({
        url: "/api/flow/type/node",
        method: "get",
        params,
    });
}

// 获取其他文件列表
export function getOtherFiles(id) {
    return request({
        url: `/api/flow/${id}/otherFiles`,
        method: "get",
    });
}

// 更新 其他文件列表
export function updateOtherFiles(id, data) {
    return request({
        url: `/api/flow/${id}/otherFiles`,
        method: "put",
        data
    });
}



// 
export function updateRelatedInformation(id, dto) {
    return request({
        url: `/api/flow/${id}/relatedInformation`,
        method: "put",
        data: dto
    });

}

// 更新公示文件
export function updateRelatedDocuments(id, fileCode, additionalInformation) {
    return request({
        url: `/api/flow/${id}/relatedDocuments`,
        method: "put",
        data: {
            fileCode,
            additionalInformation
        }
    });
}

// 完成节点
export function completedNodeFn(id) {
    return request({
        url: `/api/flow/completedNode`,
        method: "put",
        data: {
            flowId: id
        }
    });
}


// 获取表决票
export function getFlowVoteList(flowId, query) {
    return request({
        url: `/api/flow/${flowId}/vote`,
        method: "get",
        params: query,
    });
}

// 批量更新 表决票 签收 状态
export function updateFlowVoteSigningSituation(flowId, data) {
    return request({
        url: `/api/flow/${flowId}/vote/signingSituation`,
        method: "put",
        data: data,
    });
}


// 批量上传表决票图片
export function uploadFlowVoteInfoBatch(flowId, data) {
    return request({
        url: `/api/flow/${flowId}/vote/uploadInfoBatch`,
        method: "put",
        data: data,
    });
}

// 获取表决票扫描图片
export function getFlowVoteUploadInfo(flowId, data) {
    return request({
        url: `/api/flow/${flowId}/vote/getUploadInfo`,
        method: "get",
        params: data,
    });
}

// 获取一张表决票扫描图片
export function getOneUploadInfo(flowId, data) {
    return request({
        url: `/api/flow/${flowId}/vote/getOneUploadInfo`,
        method: "get",
        params: data,
    });
}

// 更新表决票回答
export function updateFlowVoteAnswer(flowId, data) {
    return request({
        url: `/api/flow/${flowId}/vote/answer`,
        method: "put",
        data: data,
    });
}


// 获取唱票详情列表
export function getFlowVoteDetails(flowId, data) {
    return request({
        url: `/api/flow/${flowId}/vote/details`,
        method: "get",
        params: data,
    });
}

// 获取唱票统计列表
export function getFlowVotingStatistics(flowId, data) {
    return request({
        url: `/api/flow/${flowId}/vote/statistics`,
        method: "get",
        params: data,
    });
}

// 修改 表决项结果
export function updateCotingMattersResult(flowId, data) {
    return request({
        url: `/api/flow/${flowId}/cotingMattersResult`,
        method: "put",
        data: data,
    });
}


// 批量创建表决票 补打
export function createFlowVotingBatch(flowId, data) {
    return request({
        url: `/api/flow/${flowId}/vote/batch`,
        method: "post",
        data: data,
    });
}



// 获取 产业信息 下的 幢 单元 树形数据
export function getBuildingInformationBuildingAndUnitTree(data) {
    return request({
        url: `/api/communityInformation/buildingAndUnit/tree`,
        method: "get",
    });
}



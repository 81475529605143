export default {
  data() {
    return {
      agency_table_columns: [
        {
          dataIndex: "name",
          title: "名称",
        },
        {
          dataIndex: "address",
          title: "地址",
        },
        {
          dataIndex: "createdName",
          title: "创建人",
        },
        {
          dataIndex: "createdAt",
          title: "创建时间",
        },
        {
          dataIndex: "updateAt",
          title: "更新时间",
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 130,
        },
      ],
      agency_detail_columns: [
        { type: "link", props: "name" },
        { type: "time", props: "createdAt", format: "YYYY-MM-DD" },
        { type: "time", props: "updateAt", format: "YYYY-MM-DD" },
      ],
      agency_form_config: {
        col: 24,
        formLayout: "vertical",
        textAlign: "left",
        columns: [
          {
            type: "input",
            props: "name",
            label: "名称",
            placeholder: "请输入",
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            type: "input",
            props: "address",
            label: "地址",
            placeholder: "请输入",
            rules: [
              {
                required: true,
              },
            ],
          },
        ],
      },
      table_columns: [
        {
          dataIndex: "name",
          title: "姓名",
        },
        {
          dataIndex: "position",
          title: "职务",
        },
        {
          dataIndex: "address",
          title: "地址",
        },
        {
          dataIndex: "tel",
          title: "固定电话",
        },
        {
          dataIndex: "phone",
          title: "手机号",
        },
        {
          dataIndex: "remark",
          title: "备注",
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 130,
        },
      ],
      form_config: {
        col: 24,
        formLayout: "vertical",
        textAlign: "left",
        columns: [
          {
            type: "input",
            props: "name",
            label: "姓名",
            placeholder: "请输入",
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            type: "input",
            props: "position",
            label: "职务",
            placeholder: "请输入",
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            type: "input",
            props: "address",
            label: "地址",
            placeholder: "请输入",
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            type: "input",
            props: "tel",
            label: "固定电话",
            placeholder: "请输入",
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            type: "input",
            props: "phone",
            label: "手机号",
            placeholder: "请输入",
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            type: "textarea",
            props: "remark",
            label: "备注",
            placeholder: "请输入",
          },
        ],
      },
    };
  },
};

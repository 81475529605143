<template>
	<div class="property-manager-page">
		<div v-if="dataList && dataList.length === 0">
			<a-empty>
				<span slot="description">
					暂无物业相关事务合同
					<a href="javascript:void(0)" @click="handleAdd">现在添加</a>
					<br/>
              档案管理需要，可选填
				</span>
			</a-empty>
		</div>
		<div v-if="dataList && dataList.length > 0">
			<div class="ywh-desc">
				<a-row>
					<a-col :span="5">
						<div class="ywh-desc-header">
							<div class="title">合同主体</div>
						</div>
						<div class="ywh-desc-content">
							<div class="desc-item">
								<span class="label">甲方：</span>
								<span>{{
									dataList[0].contractSubject.partyA
								}}</span>
							</div>
							<div class="desc-item">
								<span class="label">法定代表人：</span>
								<span>{{
									dataList[0].contractSubject
										.partyACorporation
								}}</span>
							</div>

							<div class="desc-item">
								<span class="label">乙方：</span>
								<span>{{
									dataList[0].contractSubject.partyB
								}}</span>
							</div>
							<div class="desc-item">
								<span class="label">法定代表人：</span>
								<span>{{
									dataList[0].contractSubject
										.partyBCorporation
								}}</span>
							</div>

							<div class="desc-item">
								<span class="label">丙方：</span>
								<span>{{
									dataList[0].contractSubject.partyC
								}}</span>
							</div>
							<div class="desc-item">
								<span class="label">法定代表人：</span>
								<span>{{
									dataList[0].contractSubject
										.partyCCorporation
								}}</span>
							</div>

							<div class="desc-item">
								<span class="label">合同名称：</span>
								<span>{{
									dataList[0].contractSubject.propertyName
								}}</span>
							</div>
							<div class="desc-item">
								<span class="label">签订日期：</span>
								<span>{{
									dataList[0].contractSubject.signingDate
								}}</span>
							</div>
							<div class="mt40 status">
								状态：<a href="javascript:void(0)">已归档</a>
							</div>
						</div>
					</a-col>
					<a-col :span="5">
						<div class="ywh-desc-header">
							<div class="title">合同期限</div>
						</div>
						<div class="ywh-desc-content">
							<div class="desc-item">
								<span class="label">合同年限：</span>
								<span>{{
									dataList[0].contractPeriod.year
								}}</span>
							</div>
							<div class="desc-item" style="height: auto">
								<span class="label">合同期限范围：</span>
								<p>
									{{
										dataList[0].contractPeriod.termRange && dataList[0].contractPeriod.termRange[0]
									}}至{{
										dataList[0].contractPeriod.termRange && dataList[0].contractPeriod.termRange[1]
									}}
								</p>
							</div>
						</div>
					</a-col>
					<a-col :span="5">
						<div class="ywh-desc-header">
							<div class="title">合同金额</div>
						</div>
						<div class="ywh-desc-content">
							<div class="desc-item">
								<span class="label">金额：</span>
								<span>{{ dataList[0].amount }}元</span>
							</div>
						</div>
					</a-col>
					<a-col :span="24">
						<div class="ywh-desc-header">
							<div class="title">物业相关事务合同 PDF</div>
						</div>
						<div class="ywh-desc-content">
							<div class="desc-item">
								<div class="img-content">
									<div v-for="(item, index) of dataList[0].propertyRelatedContractPDF" :key="index">
										<br />
										<a
											:href="
												item.url
											"
											target="_blank"
										>
											<img
												src="
													https://huhuiyun.oss-cn-shanghai.aliyuncs.com/static/ico_pdf.svg
												"
											/>
										</a>
									</div>
								</div>
							</div>
						</div>
					</a-col>
					<a-col :span="24">
						<div class="tl-left">
							<a-button
								type="primary"
								class="ywh-btn"
								@click="handleEdit(dataList[0])"
								>更新合同信息</a-button
							>
						</div>
					</a-col>
				</a-row>
			</div>
			<ztable2
				:detailColumns="related_contract_detail_columns"
				:columns="related_contract_table_columns"
				:dataSource="dataList"
				:pagination="false"
			>
				<span slot="relatedFiles" slot-scope="text, record">
					<a
						target="_blank"
						:href="record.relatedFiles.OAFile[0] && record.relatedFiles.OAFile[0].url"
						>{{ record.relatedFiles.OAFile[0] && record.relatedFiles.OAFile[0].name }}</a
					>
					<br />
					<a
						target="_blank"
						:href="record.relatedFiles.bidRecordingFile[0] && record.relatedFiles.bidRecordingFile[0].url"
						>{{ record.relatedFiles.bidRecordingFile[0] && record.relatedFiles.bidRecordingFile[0].name }}</a
					>
				</span>
				<span slot="action" slot-scope="text, record">
					<div class="editable-row-operations">
						<a
							href="javascript:void(0)"
							@click="handleEdit(record, true)"
							>查看详情
						</a>
					</div>
				</span>
			</ztable2>
		</div>

		<a-modal
			class="ywh-modal"
			:title="modalTitle"
			:visible="modalVisible"
			:confirm-loading="modalLoading"
			@ok="handleModalOk"
			@cancel="handleModalCancel"
			ok-text="确认"
			cancel-text="取消"
			:width="1000"
			:footer="showModalBtn"
		>
			<zform
				:formConfig="related_contract_form_config"
				:formData="{}"
				ref="modal-form"
			>
			</zform>
		</a-modal>
	</div>
</template>

<script>
import tableMixin from '@/mixin/table.list.js'
import mixin from './mixin.js'
import { getPRContracttList } from '@/api/community/property'
export default {
	data() {
		return {
			dataList: null,
		}
	},
	mixins: [tableMixin, mixin],
	methods: {
		getList() {
			getPRContracttList().then((res) => {
				this.dataList = res.list
			})
		},
		// 弹框提交接口api和params处理
		submitRequestHandle(formValues) {
			console.log('formValues', formValues)
			let relatedFiles = {
				bidRecordingFile: formValues.bidRecordingFile,
				OAFile: formValues.OAFile,
			}
			delete formValues.bidRecordingFile
			delete formValues.OAFile

			this.editId = null

			return {
				// 保存和更新接口
				postApi: `/api/PRContract`,
				// 表单外的提交参数
				params: {
					relatedFiles,
				},
			}
		},
		// 列表删除api处理
		deleteRequestHandle(recordId) {
			return {
				// 删除接口
				deleteApi: `/api/PRContract`,
			}
		},
		// 编辑之前钩子函数，返回新的record
		beforeEdit(record) {
			record.bidRecordingFile = record.relatedFiles.bidRecordingFile
			record.OAFile = record.relatedFiles.OAFile

			return record
		},
	},
	created() {
		// 获取页面数据
		this.getList()
	},
	components: {},
}
</script>

<style lang="scss" scoped></style>

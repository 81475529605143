<!--
 * @Author: Yin Xiang Zheng
 * @LastEditors: Yin Xiang Zheng
 * @LastEditTime: 2020-11-14 23:59:49
-->
<template>
  <div>
    <div class="title">
      <h2>最新资讯</h2>
    </div>
    <banner></banner>
    <div class="title">
      <h2>日常工作提醒</h2>
    </div>
    <task></task>
    <!-- <div class="title">
      <h2>资金使用情况</h2>
    </div>
    <funds-use></funds-use>
    <div class="title">
      <h2>资金剩余情况</h2>
    </div>
    <funds-surplus></funds-surplus> -->
  </div>
</template>

<script>
import banner from "@/components/dashbord/banner";
import task from "@/components/dashbord/task";
import fundsUse from "@/components/dashbord/fundsUse";
import fundsSurplus from "@/components/dashbord/fundsSurplus";

export default {
  name: "",
  data() {
    return {};
  },
  methods: {},
  components: {
    banner,
    task,
    fundsUse,
    fundsSurplus,
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.title {
  line-height: 22px;
  padding: 16px 0;
  h2 {
    font-size: 16px;
  }
}
</style>

<!--
 * @Author: Yin Xiang Zheng
 * @LastEditors: Yin Xiang Zheng
 * @LastEditTime: 2021-03-09 17:54:23
 Big Screen Voting
 大屏唱票
-->
<template>
	<div class="voting-details">
		<div>
			<a-form layout="inline" :form="signingSituationForm">
				<a-form-item label="产业地址">
					<a-input v-decorator="['address']"> </a-input>
				</a-form-item>
				<a-form-item label="业主小组">
					<a-input v-decorator="['ownersGroup']"> </a-input>
				</a-form-item>
				<a-form-item label="表决票号">
					<a-input v-decorator="['serialNumber']"> </a-input>
				</a-form-item>

				<a-form-item>
					<a-button
						type=""
						style="margin-left: 8px"
						@click="resetHandle"
					>
						重置
					</a-button>
					<a-button
						type="primary"
						style="margin-left: 8px"
						@click="selHandle"
					>
						查询
					</a-button>
					<a-button
						type=""
						style="margin-left: 8px"
						@click="$router.go(-1)"
					>
						返回
					</a-button>
				</a-form-item>
			</a-form>
		</div>
		<a-table
			style="margin-top: 16px"
			:pagination="false"
			:columns="columns"
			:data-source="data"
		>
			<div
				slot="answersVoting"
				slot-scope="text, record"
				v-html="formateAnswersVoting(record.answersVoting)"
			></div>
			<div
				slot="answerType"
				slot-scope="text, record"
				v-html="formateAnswerType(record.answerType)"
			></div>
			
			<div slot="action" slot-scope="text, record">
				<a @click="previewImg(record)">查看表决票图片</a>
			</div>
		</a-table>
	</div>
</template>

<script>
import { getFlowVoteDetails, getDetail, getOneUploadInfo } from '@/api/flow'
import PreviewImg from '@/components/previewImg.vue'
import SigningSituationManageModal from '@/components/signingSituationManageModal.vue'
import UploadVoteModal from '@/components/uploadVoteModal.vue'
import { SectionToChinese, cotingMattersMap } from '@/utils'
import moment from 'moment'

const columns = [
	{
		title: '幢/号',
		dataIndex: 'buildingInformation.building',
	},
	{
		title: '单元',
		dataIndex: 'buildingInformation.unit',
	},
	{
		title: '产业地址',
		dataIndex: 'buildingInformation.address',
	},
	{
		title: '业主小组',
		dataIndex: 'buildingInformation.ownersGroup',
	},
	{
		title: '表决票号',
		dataIndex: 'serialNumber',
	},
	{
		title: '投票形式',
		key: 'answerType',
		scopedSlots: { customRender: 'answerType' },
	},
	{
		title: '表决结果',
		key: 'answersVoting',
		scopedSlots: { customRender: 'answersVoting' },
	},
	{
		title: '操作',
		key: 'action',
		scopedSlots: { customRender: 'action' },
	},
]
export default {
	data() {
		return {
			columns,
			data: [],
			signingSituationForm: this.$form.createForm(this, {
				name: 'signingSituationForm',
			}),
			form: this.$form.createForm(this, {
				name: 'form',
			}),
			flowDetail: null,
			process: `0/0`,
			voteDetail: null,
		}
	},
	created() {
		const {
			params: { id },
		} = this.$route
		this.flowId = id
		this.getFlowDetail(id)
		this.getTableData()
	},
	methods: {
		selHandle() {
			this.getTableData()
		},
		resetHandle() {
			this.signingSituationForm.resetFields()
			this.getTableData()
		},
		getTableData() {
			let val = this.signingSituationForm.getFieldsValue()
			let params = {}
			const { address, ownersGroup, serialNumber } = val
			params.address = address
			params.ownersGroup = ownersGroup
			params.serialNumber = serialNumber

			getFlowVoteDetails(this.flowId, params).then((res) => {
				const { list } = res
				this.data = list
			})
		},
		async getFlowDetail(id) {
			try {
				const flowDetail = await getDetail(id)
				this.flowDetail = flowDetail || {}
			} catch (error) {
				console.log(error)
			}
		},
		async getOneVote(id) {
			try {
				const voteDetail = await getOneUploadInfo(id)
				const { process, data } = voteDetail
				this.process = process
				this.voteDetail = data
			} catch (error) {
				console.log(error)
			}
		},
		formateAnswersVoting(answersVoting) {
			const {
				query: { hideDetail },
			} = this.$route;
			if (hideDetail) {
				return '-'
			}
			let res = `<div>`
			answersVoting.map((item) => {
				res += `表决项${SectionToChinese(
					item.index
				)}： ${cotingMattersMap(item.answer)}<br>`
			})
			res += `</div>`
			return res
		},
		formateAnswerType(type) {
			// 回答类型：1:线下 2:线上
			if (type == 1) {
				return '线下'
			}
			if (type == 2) {
				return '线上'
			}
		},
		previewImg(record) {
			window.open(record.uploadInfo.filePath)
		},
	},
}
</script>

<style lang="scss">
.voting-details {
	.preview-img {
		width: 100%;
		border: 1px #ddd solid;
		border-radius: 8px;
		padding: 16px;
		img {
			width: 100%;
		}
	}
	.form-handle-btn {
		display: flex;
		justify-content: space-between;
		padding: 0px 16px;
	}
}
</style>
<template>
  <div class="tsAgency-members-page  content-panel">
    <a-button type="primary" class="ywh-btn mb32" @click="handleAdd"
      >新建用章记录</a-button
    >
    <searchForm
      :columns="searchFormColumns"
      @search="searchHandle"
    ></searchForm>
    <ztable2
      :detailColumns="detail_columns"
      :columns="table_columns"
      :dataSource="dataSource"
      @change="tableChangeHandle"
      :pagination="pagination"
    >
      <span slot="action" slot-scope="text, record">
        <div class="editable-row-operations">
          <a href="javascript:void(0)" @click="handleEdit(record)">编辑</a>
          <a href="javascript:void(0)" @click="handleDelete(record._id)"
            >删除</a
          >
        </div>
      </span>
    </ztable2>

    <a-modal
      class="ywh-modal"
      :title="modalTitle"
      :visible="modalVisible"
      :confirm-loading="modalLoading"
      @ok="handleModalOk"
      @cancel="handleModalCancel"
      ok-text="确认"
      cancel-text="取消"
    >
      <zform :formConfig="form_config" :formData="{}" ref="modal-form"></zform>
    </a-modal>
  </div>
</template>

<script>
import tableMixin from "@/mixin/table.list.js";
import mixin from "./mixin.js";
import request from "@/api/request";
export default {
  data() {
    return {
      searchFormColumns: [
        {
          props: "numbering",
          widgetType: "input",
          label: "流程编号",
          placeholder: "请输入",
        },
        {
          props: "title",
          widgetType: "input",
          label: "名称",
          placeholder: "请输入",
        },
      ],
    };
  },
  mixins: [tableMixin, mixin],
  methods: {
    // 查询列表接口api处理
    getListRequestHandle() {
      return {
        // 查询接口
        getApi: `/api/dailyWork/sealManage/list`,
        usePagination: true,
      };
    },
    // 弹框提交接口api和params处理
    submitRequestHandle(formValue) {
      formValue.meeting.name = formValue.meeting.label;
      formValue.meeting._id = formValue.meeting.key;
      delete formValue.meeting.key;
      delete formValue.meeting.label;
      return {
        // 保存和更新接口
        postApi: `/api/dailyWork/sealManage`,
        putApi: `/api/dailyWork/sealManage/${this.editId}`,
        // 表单外的提交参数
        params: {},
      };
    },
    // 列表删除api处理
    deleteRequestHandle(recordId) {
      let _id = this.$route.query._id;
      return {
        // 删除接口
        deleteApi: `/api/dailyWork/sealManage/${recordId}`,
      };
    },
    beforeEdit(record) {
      record.meeting.key = record.meeting._id;
      record.meeting.name = record.meeting.label;
      delete record.meeting._id;
      delete record.meeting.label;
      return record;
    },
  },
  created() {
    // 获取页面数据
    this.getList();
    request({
      url: "/api/dailyWork/uocMeeting/list",
      method: "get",
      params: {
        pageNum: 1,
        pageSize: 10000,
      },
    }).then((res) => {
      let options = res.list.map((item) => {
        return {
          text: item.title,
          value: item._id,
        };
      });
      this.form_config.columns.map((item) => {
        if (item.props === "meeting") {
          item.options = options;
        }
      });
    });
  },
  components: {},
};
</script>

<style lang="scss" scoped></style>

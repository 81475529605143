export default {
  data() {
    return {
      table_columns: [
        {
          dataIndex: "numbering",
          title: "流程编号",
        },
        {
          dataIndex: "title",
          title: "标题",
        },
        {
          dataIndex: "remark",
          title: "备注",
          width: 600,
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 120,
        },
      ],
      detail_columns: [
        {
          props: "title",
          type: "text",
        },
        {
          type: "text",
          props: "remark",
          lineClamp: 1,
          width: "580px",
          popoverContent: (dataSource) => {
            return {
              content: <div>{dataSource.remark}</div>,
            };
          },
        },
      ],
      form_config: {
        col: 24,
        formLayout: "vertical",
        textAlign: "left",
        columns: [
          {
            type: "input",
            props: "title",
            label: "标题",
            placeholder: "请输入",
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            type: "textarea",
            props: "remark",
            label: "备注",
            placeholder: "请输入",
            rows: 4,
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            type: "select",
            props: "meeting",
            label: "会议记录",
            placeholder: "请选择",
            options: [],
            labelInValue: true,
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            col: 12,
            props: "useBasis",
            type: "oss-upload",
            label: "使用依据",
            maxUpload: 1,
            listType: "picture-card",
          },
          {
            col: 12,
            props: "sealRecordFile",
            type: "oss-upload",
            label: "公章使用记录",
            maxUpload: 1,
            listType: "picture-card",
          },
        ],
      },
    };
  },
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cw-add-content"},[_c('div',{staticClass:"title-content gen-block"},[_c('h4',[_vm._v("会议工作台")]),_c('div',{staticClass:"bread-crumbs"},[_c('a-breadcrumb',[_c('a-breadcrumb-item',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.backHandle()}}},[_vm._v("会议工作台")])]),_c('a-breadcrumb-item',[_vm._v(_vm._s(_vm.flowDetail ? '修改会议' : '创建会议'))])],1),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.backHandle}},[_vm._v("返回")])],1)]),_c('div',{staticClass:"gen-block",staticStyle:{"padding":"0px"}},[_c('a-card',{staticStyle:{"border-radius":"8px"},attrs:{"title":_vm.flowDetail ? '修改会议' : '创建会议',"bordered":false}},[_c('zform',{ref:"meet-form",attrs:{"formConfig":_vm.meeting_form_config,"formData":{}}}),_c('a-card',{staticStyle:{"border-radius":"8px","background-color":"#fafafa"},attrs:{"title":_vm.flowDetail ? '修改表决项' : '创建表决项'}},[_c('div',[_c('a-form',{attrs:{"form":_vm.form}},[_vm._l((_vm.form.getFieldValue('keys')),function(k,index){return _c('div',{key:k,staticClass:"gen-block meet-vote-list"},[_c('a-form-item',{attrs:{"formItemLayout":_vm.formItemLayout,"required":false}},[_c('div',{staticClass:"mvl-item",staticStyle:{"width":"80px"}},[_c('div',[_vm._v("序号")]),_c('div',[_vm._v(_vm._s(index + 1))])]),_c('div',{staticClass:"mvl-item",staticStyle:{"flex":"1"}},[_c('div',[_vm._v("表决事项")]),_c('div',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
												("votingMatters_" + k),
												{
													validateTrigger: [
														'change',
														'blur' ],
													rules: [
														{
															required: true,
															whitespace: true,
															message:
																'请输入必填项',
														} ],
												} ]),expression:"[\n\t\t\t\t\t\t\t\t\t\t\t\t`votingMatters_${k}`,\n\t\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\t\tvalidateTrigger: [\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t'change',\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t'blur',\n\t\t\t\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\twhitespace: true,\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tmessage:\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t'请输入必填项',\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t\t]"}],staticStyle:{"width":"90%","margin-right":"8px"},attrs:{"placeholder":"请输入"}})],1)]),_c('div',{staticClass:"mvl-item",staticStyle:{"width":"250px"}},[_c('div',[_vm._v("表决形式")]),_c('div',[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
												("votingForm_" + k),
												{
													initialValue: '1',
													rules: [
														{
															required: true,
															whitespace: true,
															message:
																'请输入必填项',
														} ],
												} ]),expression:"[\n\t\t\t\t\t\t\t\t\t\t\t\t`votingForm_${k}`,\n\t\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\t\tinitialValue: '1',\n\t\t\t\t\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\twhitespace: true,\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tmessage:\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t'请输入必填项',\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t\t]"}],staticStyle:{"width":"100%","margin-right":"8px"},attrs:{"placeholder":"请选择","default-value":"1"}},[_c('a-select-option',{attrs:{"value":"1"}},[_vm._v(" 同意/反对/弃权/计入多数 ")]),_c('a-select-option',{attrs:{"value":"2"}},[_vm._v(" 同意/反对/弃权 ")])],1)],1)]),_c('div',{staticClass:"mvl-item",staticStyle:{"width":"80px"}},[_c('div',[_vm._v("操作")]),_c('div',[(
												_vm.form.getFieldValue('keys')
													.length > 1
											)?_c('a',{attrs:{"disabled":_vm.form.getFieldValue('keys')
													.length === 1},on:{"click":function () { return _vm.remove(k); }}},[_vm._v("删除")]):_vm._e()])])])],1)}),_c('a-form-item',[_c('a-button',{staticStyle:{"width":"100%"},attrs:{"type":"dashed"},on:{"click":_vm.add}},[_vm._v(" 添加选项 ")])],1),_c('a-form-item',{staticStyle:{"text-align":"right"}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v(" "+_vm._s(_vm.flowDetail ? '保存' : '提交')+" ")])],1)],2)],1)])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
import request from "@/api/request";
export function getPropertyManagerList(query) {
  return request({
    url: "/api/propertyManager/list",
    method: "get",
    params: query,
  });
}
export function getPropertyContractList() {
  return request({
    url: "/api/PSContract/list",
    method: "get",
  });
}

export function getPRContracttList() {
  return request({
    url: "/api/PRContract/list",
    method: "get",
  });
}



import request from "@/api/request";
// 获取服务机构类型
export function getTsAgencyTypes(query) {
  return request({
    url: "/api/tsAgency/types",
    method: "get",
    params: query,
  });
}

// 创建服务机构
export function createTsAgency(data) {
  return request({
    url: "/api/tsAgency",
    method: "post",
    data,
  });
}

// 修改服务机构
export function updateTsAgency(_id, data) {
  return request({
    url: `/api/tsAgency/${_id}`,
    method: "put",
    data: data,
  });
}

// 删除服务机构
export function deleteTsAgency(_id) {
  return request({
    url: `/api/tsAgency/${_id}`,
    method: "delete",
  });
}

// 获取服务机构
export function getTsAgency() {
  return request({
    url: "/api/tsAgency/list",
    method: "get",
  });
}

// 获取某一个类型下的机构
export function getTsAgencyByType(typeId) {
  return request({
    url: `/api/tsAgency/list/${typeId}`,
    method: "get",
  });
}

// 获取服务机构人员
export function getTsAgencyMembers(_id) {
  return request({
    url: `api/tsAgency/${_id}/members`,
    method: "get",
  });
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"big-screen-voting"},[_c('a-row',[_c('a-col',{attrs:{"span":8}},[_c('div',[(_vm.voteDetail)?[_c('h5',[_vm._v("唱票进度："+_vm._s(_vm.process))]),_c('a-button-group',{staticStyle:{"margin-right":"8px"}},[(_vm.votedIdList && _vm.votedIdList.length > 0)?_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.previousHandle}},[_c('a-icon',{attrs:{"type":"left"}}),_vm._v("上一张 ")],1):_vm._e(),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.nextHandle}},[_vm._v(" 下一张"),_c('a-icon',{attrs:{"type":"right"}})],1)],1)]:_vm._e(),_c('a-button-group',[_c('a-button',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("返回工作台")])],1)],2),_c('div',{staticStyle:{"margin-top":"24px"}},[_c('a-descriptions',{attrs:{"column":1}},[_c('a-descriptions-item',{attrs:{"label":"产业地址"}},[_vm._v(" "+_vm._s(_vm.voteDetail && _vm.voteDetail.buildingInformation.address)+" ")]),_c('a-descriptions-item',{attrs:{"label":"建筑面积"}},[_vm._v(" "+_vm._s(_vm.voteDetail && _vm.voteDetail.buildingInformation.area)+" 平方米 ")]),_c('a-descriptions-item',{attrs:{"label":"表决票编号"}},[_vm._v(" "+_vm._s(_vm.voteDetail && _vm.voteDetail.serialNumber)+" ")])],1)],1),(_vm.flowDetail)?_c('div',[_c('a-form',{attrs:{"form":_vm.form}},_vm._l((_vm.flowDetail
							.relatedInformation.cotingMatters),function(item,index){return _c('a-form-item',{key:index},[_c('span',{staticStyle:{"white-space":"normal","text-align":"left","line-height":"1.5","display":"inline-block"},attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(item.votingMatters)+" ")]),_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
								("cotingMatters_" + (item.index)),
								{
									rules: [
										{
											required: true,
											message: '请输入必填项!',
										} ],
								} ]),expression:"[\n\t\t\t\t\t\t\t\t`cotingMatters_${item.index}`,\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\tmessage: '请输入必填项!',\n\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t]"}],attrs:{"options":item.votingForm == 1
									? [
											{ label: '同意', value: 1 },
											{ label: '反对', value: 2 },
											{ label: '弃权', value: 3 },
											{ label: '计入多数', value: 5 },
											{ label: '废票', value: 4 } ]
									: [
											{ label: '同意', value: 1 },
											{ label: '反对', value: 2 },
											{ label: '弃权', value: 3 },
											{ label: '废票', value: 4 } ]}})],1)}),1),(_vm.voteDetail)?_c('div',{staticClass:"form-handle-btn"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.confirmVote}},[_vm._v(" 确认投票 ")]),_c('a-button',{attrs:{"type":""},on:{"click":function($event){return _vm.form.resetFields()}}},[_vm._v(" 重置 ")])],1):_vm._e()],1):_vm._e()]),_c('a-col',{attrs:{"span":16}},[_c('div',{staticClass:"preview-img"},[_c('img',{attrs:{"src":_vm.voteDetail && _vm.voteDetail.uploadInfo.filePath}})])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
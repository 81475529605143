<template>
	<div class="dashbord-task">
		<div class="tips">
			<a-icon
				type="exclamation-circle"
				class="ywh-icon"
			/>你最近有{{list.length}}个业主大会会议
		</div>
		<div class="item" v-for="item of list" :key="item._id" @click="$router.push(`/conferenceWorkbench/detail/${item._id}`)">
			<div class="title">{{ item.relatedInformation.theme }}</div>
			<div class="desc">
				<p>
					<label>会议创建时间:</label
					>{{ moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss') }}
				</p>
				<p>
					<label>会议开始时间:</label
					>{{ formateTime(item, 'startingTime') }}
				</p>
				<p>
					<label>表决票送达截止时间:</label
					>{{ formateTime(item, 'deadlineDelivery') }}
				</p>
				<p>
					<label>计票时间:</label
					>{{ formateTime(item, 'countingTime') }}
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import moment from 'moment'
import { getFlowList } from '@/api/flow'
export default {
	name: '',

	data() {
		return {
			list: [],
		}
	},
	created() {
		getFlowList({ pageNum: 1, pageSize: 3, type: 1 }).then((res) => {
			this.list = res.list
		})
	},
	computed: {},
	methods: {
		moment,
		formateTime(dataSource, key) {
			const additionalInformation = this.formateAdditionalInformation(
				dataSource
			)
			if (!additionalInformation) {
				return '-'
			}
			return moment(additionalInformation[key]).format(
				'YYYY-MM-DD HH:mm:ss'
			)
		},
		formateAdditionalInformation(dataSource) {
			const { relatedDocuments } = dataSource
			if (!relatedDocuments) {
				return null
			}
			if (!relatedDocuments['MEETING_ANNOUNCEMENT']) {
				return null
			}
			if (
				!relatedDocuments['MEETING_ANNOUNCEMENT'][
					'MEETING_ANNOUNCEMENT'
				]
			) {
				return null
			}
			const additionalInformation =
				relatedDocuments['MEETING_ANNOUNCEMENT'][
					'MEETING_ANNOUNCEMENT'
				]['additionalInformation']
			if (!additionalInformation) {
				return null
			}
			return additionalInformation
		},
	},
}
</script>
<style lang="scss">
.dashbord-task {
	background: #fff;
	.tips {
		height: 42px;
		display: inline-block;
		padding: 0 108px 0 24px;
		background: $red;
		border-radius: 0px 100px 100px 0;
		line-height: 42px;
		color: #fff;
		background: #18a75d;
	}
	.item {
		cursor: pointer;
		margin: 0 24px;
		padding: 10px 0;
		border-bottom: 1px solid #ebebeb;
		.title {
			font-weight: 900;
			margin-bottom: 10px;
		}
		&:hover {
			.title {
				color: #2140D9;
			}
		}
		.desc {
			p {
				margin-bottom: 0;
				color: $gray-600;
			}
		}
	}
}
</style>
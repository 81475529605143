<!--
 * @Author: Yin Xiang Zheng
 * @LastEditors: Yin Xiang Zheng
 * @LastEditTime: 2021-03-08 18:01:09
-->

<template>
	<div>
		<!-- 补打表决票 -->
		<a-modal
			title="补打表决票"
			:visible="visible"
			@ok="visible = false"
			@cancel="visible = false"
			:destroyOnClose="true"
			width="80%"
		>
			<a-form layout="inline" :form="form">
				<a-form-item label="产业地址">
					<a-input v-decorator="['address']" placeholder="产业地址">
					</a-input>
				</a-form-item>
				<a-form-item label="业主小组">
					<a-input
						v-decorator="['ownersGroup']"
						placeholder="业主小组"
					>
					</a-input>
				</a-form-item>
				<a-form-item>
					<a-button
						type=""
						style="margin-left: 8px"
						@click="resetHandle"
					>
						重置
					</a-button>
					<a-button
						type="primary"
						style="margin-left: 8px"
						@click="selHandle"
					>
						查询
					</a-button>
					<a-button
						type="primary"
						style="margin-left: 8px"
						:loading="btnLoading"
						@click="additionalBallot"
					>
						补打表决票
					</a-button>
				</a-form-item>
			</a-form>

			<ztable2
				style="margin-top: 16px"
				:detailColumns="detail_columns"
				:columns="table_columns"
				:dataSource="dataSource"
				:pagination="pagination"
				@change="tableChangeHandle"
				:row-selection="{
					selectedRowKeys: selectedRowKeys,
					onChange: onSelectChange,
				}"
			>
			</ztable2>
		</a-modal>
	</div>
</template>

<script>
import {
	getFlowVoteList,
	updateFlowVoteSigningSituation,
	createFlowVotingBatch,
} from '@/api/flow'
import { SigningSituationStatusMap } from '@/utils/variable'
import tableMixin from '@/mixin/table.list.js'

const columns = [
	{
		title: '业主小组',
		dataIndex: 'ownersGroup',
	},
	{
		title: '幢/号',
		dataIndex: 'building',
	},
	{
		title: '单元',
		dataIndex: 'unit',
	},
	{
		title: '产业地址',
		dataIndex: 'address',
	},
	{
		title: '建筑面积',
		dataIndex: 'area',
	},
	{
		title: '产业类型',
		dataIndex: 'type',
	},
	{
		dataIndex: 'votingRights',
		title: '投票权',
	},
	{
		dataIndex: 'residentManagement',
		title: '居民管理',
	},
	{
		dataIndex: 'remark',
		title: '备注',
	},
]
export default {
	data() {
		return {
			selectedRowKeys: [],
			selectedRow: [],
			table_columns: columns,
			detail_columns: [],
			SigningSituationStatusMap,
			visible: false,
			form: this.$form.createForm(this, {
				name: 'form',
			}),
			data: [],
			loading: false,
			btnLoading: false,
		}
	},
	props: ['value', 'flowId'],
	mixins: [tableMixin],
	watch: {
		value(newval) {
			this.visible = newval
		},
		visible(newval) {
			if (newval) {
				this.getTableData()
			}
			this.$emit('input', newval)
		},
	},
	computed: {},
	methods: {
		additionalBallot() {
			const { selectedRow } = this
			console.log('additionalBallot:', selectedRow);

			if (!selectedRow || selectedRow.length === 0) {
				this.$message.warning('请至少选择一户！')
				return
			}

			this.btnLoading = true
			let buildingInformationIdList = []
			selectedRow.forEach((item) => {
				buildingInformationIdList.push(item._id)
			})
			console.log('additionalBallot:', buildingInformationIdList);
			createFlowVotingBatch(this.flowId, { buildingInformationIdList })
				.then((res) => {
					const { fileUrl } = res
					this.$message.success('补打成功！')
					this.btnLoading = false
					window.open(fileUrl)
				})
				.catch((err) => {
					this.btnLoading = false

					this.$message.error('补打失败！')
					console.log('err', err)
				})
		},
		getListRequestHandle() {
			return {
				// 查询接口
				getApi: `/api/communityInformation/building/list`,
				usePagination: true,
			}
		},
		selHandle() {
			this.getTableData()
		},
		resetHandle() {
			this.params = {
				pageNum: 1,
				pageSize: 10,
			}
			this.form.resetFields()
			this.getTableData()
		},
		tableChangeHandleCB() {
			this.selectedRowKeys = []
			this.selectedRow = []
		},
		getListCB() {
			this.selectedRowKeys = []
			this.selectedRow = []
		},
		getTableData() {
			let val = this.form.getFieldsValue()
			this.loading = true
			const { address, ownersGroup } = val
			this.params.address = address
			this.params.ownersGroup = ownersGroup
			this.getList()
			return
			getFlowVoteList(this.flowId, val)
				.then((res) => {
					console.log('res', res)
					this.data = res.list
					this.loading = false
					this.selectedRowKeys = []
					this.selectedRow = []
				})
				.catch((err) => {
					console.log('err', err)
					this.loading = false
				})
		},
		tableChange(pagination) {
			console.log(pagination)
			this.selectedRowKeys = []
			this.selectedRow = []
		},
		onSelectChange(selectedRowKeys, selectedRow, b) {
			console.log(selectedRow)
			this.selectedRowKeys = selectedRowKeys
			this.selectedRow = selectedRow
		},
	},
}
</script>
<!--
 * @Author: Yin Xiang Zheng
 * @LastEditors: Yin Xiang Zheng
 * @LastEditTime: 2020-11-15 08:12:51
-->
<template>
	<div class="s-news" style="height: auto;">
		<div class="news-title">{{ newsInfo.title }}</div>
		<div class="news-content" v-html="newsInfo.content"></div>
	</div>
</template>

<script>
import { getDetail } from '@/api/news'

export default {
	data() {
		return {
			newsInfo: {},
		}
	},
	mounted() {
		const { _id } = this.$route.query
		this.editID = _id
		if (_id) {
			this.getNewsDetail()
		}
	},
	methods: {
		getNewsDetail() {
			getDetail(this.editID).then((res) => {
				console.log('res', res)
				this.newsInfo = {
					...res,
				}
			})
		},
	},
}
</script>

<style lang="less" scoped>
.s-news {
	width: 800px;
	height: auto!important;
	background: white;
	margin-left: auto;
	margin-right: auto;
	padding: 32px;
	border-radius: 5px;
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.19);
	.news-title {
		font-size: 28px;
		font-weight: bold;
		text-align: center;
	}
	.news-content {
		line-height: 100px !important;
	}
}
</style>
import moment from "moment";
export default {
  data() {
    return {
      table_columns: [
        {
          dataIndex: "relatedInformation.theme",
          title: "会议主题",
        },
        {
          dataIndex: "participants",
          title: "参会人员",
        },
        {
          dataIndex: "startingTime",
          title: "会议开始时间",
          width: 200,
        },
        {
          dataIndex: "deadlineDelivery",
          title: "表决票送达截止时间",
          width: 200,
        },
        {
          dataIndex: "countingTime",
          title: "计票时间",
          width: 200,
        },
        {
          dataIndex: "createdBy",
          title: "创建人",
        },
        {
          dataIndex: "createdAt",
          title: "创建时间",
          width: 150,
        },
        {
          title: "操作",
          dataIndex: "action",
          fixed: 'right',
          width: 100,
        },
      ],
      detail_columns: [
        {
          props: "title",
          type: "text",
        },
        {
          dataIndex: "participants",
          renderContent: ({ value, dataSource }) => {
            const {participants} = dataSource.relatedInformation
            let res = []
            participants.forEach(item => {
              res.push(item.label)
            })
            return res.join('、')
          },
        },
        {
          dataIndex: "meetingTime",
          type: "text",
          renderContent: ({ value, dataSource }) => {
            return `${moment(dataSource.meetingStartTime).format(
              "YYYY-MM-DD HH:mm"
            )} - ${moment(dataSource.meetingEndTime).format(
              "YYYY-MM-DD HH:mm"
            )}`;
          },
        },
        {
          type: "text",
          props: "status",
          mapping: [
            {
              text: "未开始",
              value: 1,
            },
            {
              text: "进行中",
              value: 2,
            },
            {
              text: "未上传会议文件",
              value: 3,
            },
            {
              text: "完成",
              value: 4,
            },
          ],
        },
        {
          type: "group",
          props: "specialInvitation",
          subColumns: [
            {
              type: "text",
              props: "label",
              splitFlagChar: "&",
            },
          ],
        },
        {
          props: "createdAt",
          type: "time",
        },
        {
          props: "startingTime",
          renderContent: ({ value, dataSource }) => {
            const additionalInformation = this.formateAdditionalInformation(dataSource)
            if (!additionalInformation) {
              return '-'
            }
            const { startingTime } = additionalInformation
            return moment(startingTime).format(
              "YYYY-MM-DD HH:mm:ss"
            )
          },
        },
        {
          props: "deadlineDelivery",
          renderContent: ({ value, dataSource }) => {
            const additionalInformation = this.formateAdditionalInformation(dataSource)
            if (!additionalInformation) {
              return '-'
            }
            const { deadlineDelivery } = additionalInformation
            return moment(deadlineDelivery).format(
              "YYYY-MM-DD HH:mm:ss"
            )
          },
        },
        {
          props: "countingTime",
          renderContent: ({ value, dataSource }) => {
            const additionalInformation = this.formateAdditionalInformation(dataSource)
            if (!additionalInformation) {
              return '-'
            }
            const { countingTime } = additionalInformation
            return moment(countingTime).format(
              "YYYY-MM-DD HH:mm:ss"
            )
          },
        }
      ],
      form_config: {
        col: 24,
        // formLayout: "vertical",
        textAlign: "right",
        columns: [
          {
            type: "input",
            props: "title",
            label: "会议标题",
            placeholder: "请输入",
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            type: "select",
            props: "participants",
            label: "业委会人员",
            placeholder: "请选择",
            options: [],
            mode: "tags",
            labelInValue: true,
            rules: [
              {
                required: true,
              },
            ],
          },
          // {
          //   type: "select",
          //   props: "specialInvitation",
          //   label: "特别邀请",
          //   placeholder: "请选择",
          //   options: [],
          //   mode: "tags",
          //   labelInValue: true,
          //   rules: [
          //     {
          //       required: true,
          //     },
          //   ],
          // },
          {
            type: "slot",
            slotName: "selectGroup",
            label: "特别邀请",
          },
          {
            label: "会议时间",
            type: "datepicker",
            props: "rangePicker",
            placeholder: ["会议开始时间", "会议结束时间"],
            datepickerType: "range-picker",
            format: "YYYY-MM-DD HH:mm",
            showTime: true,
            rules: [
              {
                required: true,
              },
            ],
          },
          // {
          //   label: "会议开始时间",
          //   type: "datepicker",
          //   props: "meetingStartTime",
          //   datepickerType: "date-picker",
          //   placeholder: "请选择",
          //   format: "YYYY-MM-DD HH:mm:ss",
          //   allowClear: false,
          //   showTimeDefaultValue: {
          //     format: "HH:mm:ss",
          //   },
          //   rules: [
          //     {
          //       required: true,
          //     },
          //   ],
          // },
          // {
          //   label: "会议结束时间",
          //   type: "datepicker",
          //   props: "meetingEndTime",
          //   datepickerType: "date-picker",
          //   placeholder: "请选择",
          //   format: "YYYY-MM-DD HH:mm:ss",
          //   allowClear: false,
          //   showTimeDefaultValue: {
          //     format: "HH:mm:ss",
          //   },
          //   rules: [
          //     {
          //       required: true,
          //     },
          //   ],
          // },
          {
            type: "input",
            props: "address",
            label: "会议地点",
            placeholder: "请输入",
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            type: "select",
            props: "type",
            label: "会议类型",
            placeholder: "请选择",
            options: [
              {
                text: "业主委员会会议",
                value: 1,
              },
              {
                text: "业主大会会议",
                value: 2,
              },
            ],
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            col: 8,
            props: "minutes",
            type: "oss-upload",
            label: "会议记录",
            maxUpload: 1,
            listType: "picture-card",
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            col: 8,
            props: "otherFiles",
            type: "oss-upload",
            label: "其他文件",
            maxUpload: 1,
            listType: "picture-card",
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            col: 8,
            props: "archiveRecord",
            type: "oss-upload",
            label: "文件存档记录",
            maxUpload: 1,
            listType: "picture-card",
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            type: "buttons",
            label: "&nbsp",
            props: "buttons",
            subColumns: [
              {
                type: "button",
                props: "cancel",
                btnText: "取消",
              },
              {
                type: "button",
                props: "save",
                btnText: "提交",
                btnStatus: "primary",
              },
            ],
          },
        ],
      },
    };
  },
  methods: {
    formateAdditionalInformation(dataSource) {
      const { relatedDocuments } = dataSource
      if (!relatedDocuments) {
				return null
			}
      if (!relatedDocuments['MEETING_ANNOUNCEMENT']) {
        return null
      }
      if (!relatedDocuments['MEETING_ANNOUNCEMENT']['MEETING_ANNOUNCEMENT']) {
        return null
      }
      const additionalInformation = relatedDocuments['MEETING_ANNOUNCEMENT']['MEETING_ANNOUNCEMENT']['additionalInformation']
      if (!additionalInformation) {
        return null
      }
      return additionalInformation
    }
  },
};

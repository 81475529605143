<!--
 * @Author: Yin Xiang Zheng
 * @LastEditors: Yin Xiang Zheng
 * @LastEditTime: 2021-03-09 16:44:40
-->
<template>
	<div class="cw-add-content">
		<div class="title-content gen-block">
			<h4>会议工作台</h4>
			<div class="bread-crumbs">
				<a-breadcrumb>
					<a-breadcrumb-item
						><a href="#" @click="backHandle()"
							>会议工作台</a
						></a-breadcrumb-item
					>
					<a-breadcrumb-item>{{
						flowDetail ? '修改会议' : '创建会议'
					}}</a-breadcrumb-item>
				</a-breadcrumb>
				<a-button type="primary" @click="backHandle">返回</a-button>
			</div>
		</div>

		<div class="gen-block" style="padding: 0px">
			<a-card
				:title="flowDetail ? '修改会议' : '创建会议'"
				:bordered="false"
				style="border-radius: 8px"
			>
				<zform
					:formConfig="meeting_form_config"
					:formData="{}"
					ref="meet-form"
				></zform>
				<a-card
					:title="flowDetail ? '修改表决项' : '创建表决项'"
					style="border-radius: 8px; background-color: #fafafa"
				>
					<div>
						<a-form :form="form">
							<div
								class="gen-block meet-vote-list"
								v-for="(k, index) in form.getFieldValue('keys')"
								:key="k"
							>
								<a-form-item
									:formItemLayout="formItemLayout"
									:required="false"
								>
									<div class="mvl-item" style="width: 80px">
										<div>序号</div>
										<div>{{ index + 1 }}</div>
									</div>
									<div class="mvl-item" style="flex: 1">
										<div>表决事项</div>
										<div>
											<a-input
												v-decorator="[
													`votingMatters_${k}`,
													{
														validateTrigger: [
															'change',
															'blur',
														],
														rules: [
															{
																required: true,
																whitespace: true,
																message:
																	'请输入必填项',
															},
														],
													},
												]"
												placeholder="请输入"
												style="
													width: 90%;
													margin-right: 8px;
												"
											/>
										</div>
									</div>
									<div class="mvl-item" style="width: 250px">
										<div>表决形式</div>
										<div>
											<a-select
												v-decorator="[
													`votingForm_${k}`,
													{
														initialValue: '1',
														rules: [
															{
																required: true,
																whitespace: true,
																message:
																	'请输入必填项',
															},
														],
													},
												]"
												placeholder="请选择"
												style="
													width: 100%;
													margin-right: 8px;
												"
												default-value="1"
											>
												<a-select-option value="1">
													同意/反对/弃权/计入多数
												</a-select-option>
												<a-select-option value="2">
													同意/反对/弃权
												</a-select-option>
												<!-- <a-select-option value="2">
													同意/反对/计入多数
												</a-select-option> -->
											</a-select>
										</div>
									</div>

									<div class="mvl-item" style="width: 80px">
										<div>操作</div>
										<div>
											<a
												v-if="
													form.getFieldValue('keys')
														.length > 1
												"
												:disabled="
													form.getFieldValue('keys')
														.length === 1
												"
												@click="() => remove(k)"
												>删除</a
											>
										</div>
									</div>
								</a-form-item>
							</div>
							<a-form-item>
								<a-button
									type="dashed"
									style="width: 100%"
									@click="add"
								>
									添加选项
								</a-button>
							</a-form-item>
							<a-form-item style="text-align: right">
								<a-button type="primary" @click="handleSubmit">
									{{ flowDetail ? '保存' : '提交' }}
								</a-button>
							</a-form-item>
						</a-form>
					</div>
				</a-card>
			</a-card>
		</div>
	</div>
</template>

<script>
import { createFlow, getDetail, updateRelatedInformation, getBuildingInformationBuildingAndUnitTree } from '@/api/flow'

let id = 1
export default {
	data() {
		return {
			meeting_form_config: {
				col: 8,
				fixedLabelWidth: '80px',
				formLayout: 'horizontal',
				textAlign: 'left',
				columns: [
					{
						type: 'input',
						props: 'theme',
						label: '会议主题',
						placeholder: '请输入',
						rules: [
							{
								required: true,
							},
						],
					},
					// {
					// 	type: 'select',
					// 	props: 'type',
					// 	label: '会议类型',
					// 	placeholder: '请输入',
					// 	options: [
					// 		{
					// 			value: 1,
					// 			text: '普通会议',
					// 		},
					// 	],
					// 	rules: [
					// 		{
					// 			required: true,
					// 		},
					// 	],
					// },
					// {
					// 	type: 'input',
					// 	props: 'conferenceHistory',
					// 	label: '会议历史',
					// 	placeholder: '请输入',
					// 	rules: [
					// 		{
					// 			required: true,
					// 		},
					// 	],
					// },
					{
						type: 'input',
						props: 'contactPerson',
						label: '联系人',
						placeholder: '请输入',
						rules: [
							{
								required: true,
							},
						],
					},
					{
						type: 'input',
						props: 'contactDetails',
						label: '联系方式',
						placeholder: '请输入',
						rules: [
							{
								required: true,
							},
						],
					},
					{
						type: 'input',
						props: 'contactAddress',
						label: '联系地址',
						placeholder: '请输入',
						rules: [
							{
								required: true,
							},
						],
					},
					{
						type: 'tree-select',
						props: 'participants',
						treeData: [],
						label: '参会人员',
						placeholder: '请选择',
						treeCheckable: true,
						showCheckedStrategy: 'SHOW_PARENT',
						labelInValue: true,
						rules: [
							{
								required: true,
							},
						],
					},
				],
			},
			formItemLayout: {
				labelCol: {
					xs: { span: 24 },
					sm: { span: 4 },
				},
				wrapperCol: {
					xs: { span: 24 },
					sm: { span: 20 },
				},
			},
			formItemLayoutWithOutLabel: {
				wrapperCol: {
					xs: { span: 24, offset: 0 },
					sm: { span: 20, offset: 4 },
				},
			},
			form: this.$form.createForm(this, { name: 'dynamic_form_item' }),
			flowDetail: null,
		}
	},
	created() {
		this.form.getFieldDecorator('keys', {
			initialValue: [0],
			preserve: true,
		})
		this.flowDetail = null
		getBuildingInformationBuildingAndUnitTree().then(res => {
			let finded = this.meeting_form_config.columns.find(item => item.props === 'participants') 
			finded.treeData = res.list
			
		})
	},
	mounted() {
		const {
			query: { flowId },
		} = this.$route
		if (flowId) {
			this.getFlowDetail(flowId)
		}
	},
	methods: {
		async getFlowDetail(id) {
			try {
				const flowDetail = await getDetail(id)
				if (!flowDetail) {
					return
				}
				this.flowDetail = flowDetail
				const { relatedInformation } = flowDetail
				this.$refs['meet-form'].updateForm(relatedInformation)
				console.log(
					'JSON.parse(relatedInformation.originCotingMatters)',
					JSON.parse(relatedInformation.originCotingMatters)
				)
				this.form.setFieldsValue(
					JSON.parse(relatedInformation.originCotingMatters)
				)
				this.$nextTick(() => {
					this.form.setFieldsValue(
						JSON.parse(relatedInformation.originCotingMatters)
					)
				})
			} catch (error) {
				console.log(error)
			}
		},
		handleSubmit(e) {
			e.preventDefault()
			this.form.validateFields(async (err, values) => {
				if (err) {
					console.log(err)
					return
				}
				const { keys } = values
				let cotingMatters = [],
					meetForm = null
				
				keys.forEach((key, index) => {
					cotingMatters.push({
						index: index + 1,
						votingForm: values[`votingForm_${key}`],
						votingMatters: values[`votingMatters_${key}`],
					})
				})
				try {
					meetForm = await this.$refs[
						'meet-form'
					].zformValidateFieldsAndScroll()
				} catch (error) {
					console.log(error)
				}
				try {
					const dto = {
						...meetForm,
						cotingMatters,
						originCotingMatters: JSON.stringify(values),
						type: 1,
					}
					if (this.flowDetail) {
						await updateRelatedInformation(this.flowDetail._id, dto)
					} else {
						await createFlow(dto)
					}
					setTimeout(() => {
						this.backHandle()
					}, 1000)
				} catch (error) {
					this.$message.error('创建失败')
					console.log(error)
				}
			})
		},
		backHandle() {
			this.$router.replace('/conferenceWorkbench/list')
		},
		remove(k) {
			const { form } = this
			// can use data-binding to get
			const keys = form.getFieldValue('keys')
			// We need at least one passenger
			if (keys.length === 1) {
				return
			}

			// can use data-binding to set
			form.setFieldsValue({
				keys: keys.filter((key) => key !== k),
			})
		},

		add() {
			const { form } = this
			// can use data-binding to get
			const keys = form.getFieldValue('keys')
			const nextKeys = keys.concat(id++)
			// can use data-binding to set
			// important! notify form to detect changes
			form.setFieldsValue({
				keys: nextKeys,
			})
		},
	},
}
</script>

<style lang="scss">
.cw-add-content {
	.title-content {
	}
	.bread-crumbs {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.meet-vote-list {
		padding-bottom: 1px;
		margin-bottom: 8px;
		border-radius: 4px;
		.ant-form-item-children {
			display: flex;
			.mvl-item {
				border-radius: 4px;
			}
		}
	}
}
</style>
<template>
  <div class="tsAgency-members-page">
    <div class="ywh-small-card">
      <div class="item">
        <div class="title">物业管理费总收入</div>
        <div class="content">
          <div class="data">{{ listRes.maintenanceFundTotal || 0 }}</div>
        </div>
      </div>
      <div class="item">
        <div class="title">
          上年度结余<a-icon
            type="edit"
            class="ywh-icon ywh-edit"
            @click="openModal"
          />
        </div>
        <div class="content">
          <div class="data">
            {{ listRes.lastYearBalance || 0 }}
          </div>
        </div>
      </div>
      <div class="item">
        <div class="title">
          累计结余<a-icon
            type="edit"
            class="ywh-icon ywh-edit"
            @click="openModal1"
          />
        </div>
        <div class="content">
          <div class="data">{{ listRes.accumulatedBalance || 0 }}</div>
        </div>
      </div>
      <div class="item">
        <div class="title">当月支出总计</div>
        <div class="content">
          <div class="data">{{ listRes.totalExpenditure || 0 }}</div>
        </div>
      </div>
      <div class="item">
        <div class="title">累计支出</div>
        <div class="content">
          <div class="data">{{ listRes.numberExpenditures || 0 }}</div>
        </div>
      </div>
      <div class="item">
        <div class="title">当月收入总计</div>
        <div class="content">
          <div class="data">{{ listRes.totalIncome || 0 }}</div>
        </div>
      </div>
      <div class="item">
        <div class="title">累计收入</div>
        <div class="content">
          <div class="data">{{ listRes.numberIncome || 0 }}</div>
        </div>
      </div>
    </div>
    <div class="content-panel">
      <a-button type="primary" class="ywh-btn mb32" @click="handleAdd"
        >新建使用记录</a-button
      >
      <searchForm
        :columns="searchFormColumns"
        @search="searchHandle"
      ></searchForm>
      <ztable2
        :detailColumns="detail_columns"
        :columns="table_columns"
        :dataSource="dataSource"
        @change="tableChangeHandle"
        :pagination="pagination"
      >
        <span slot="action" slot-scope="text, record">
          <div class="editable-row-operations">
            <a href="javascript:void(0)" @click="handleEdit(record, true)"
              >查看详情
            </a>
          </div>
        </span>
      </ztable2>
    </div>

    <a-modal
      class="ywh-modal"
      :title="modalTitle"
      :visible="modalVisible"
      :confirm-loading="modalLoading"
      @ok="handleModalOk"
      @cancel="handleModalCancel"
      ok-text="确认"
      cancel-text="取消"
      :footer="showModalBtn"
    >
      <zform :formConfig="form_config" :formData="{}" ref="modal-form"></zform>
    </a-modal>

    <modal
      ref="ywhModal"
      :columns="modalColumns"
      api="/api/uoc/propertyManagementFeeFunds"
      :width="400"
      @successAction="getList"
    ></modal>
    <modal
      ref="ywhModal1"
      :columns="modalColumns1"
      api="/api/uoc/propertyManagementFeeFunds"
      :width="400"
      @successAction="getList"
    ></modal>
  </div>
</template>

<script>
import tableMixin from "@/mixin/table.list.js";
import mixin from "./mixin.js";
export default {
  data() {
    return {
      searchFormColumns: [
        {
          props: "numbering",
          widgetType: "input",
          label: "项目编号",
          placeholder: "请输入",
        },
        {
          props: "projectName",
          widgetType: "input",
          label: "项目名称",
          placeholder: "请输入",
        },
        {
          props: "type",
          widgetType: "select",
          label: "类型",
          placeholder: "请选择",
          options: [
            {
              label: "收入",
              key: 1,
            },
            {
              label: "支出",
              key: 2,
            },
          ],
          style: {
            width: "200px",
          },
        },
      ],
    };
  },
  mixins: [tableMixin, mixin],
  methods: {
    // 查询列表接口api处理
    getListRequestHandle() {
      return {
        // 查询接口
        getApi: `/api/fm/PMFeeFunds`,
        usePagination: true,
      };
    },
    // 弹框提交接口api和params处理
    submitRequestHandle() {
      return {
        // 保存和更新接口
        postApi: `/api/fm/PMFeeFunds`,
        putApi: `/api/fm/PMFeeFunds/${this.editId}`,
        // 表单外的提交参数
        params: {},
      };
    },
    // 列表删除api处理
    deleteRequestHandle(recordId) {
      return {
        // 删除接口
        deleteApi: `/api/fm/PMFeeFunds/${recordId}`,
      };
    },
    openModal() {
      this.$refs.ywhModal.showModal();
      this.$refs.ywhModal.updateForm();
    },
    openModal1() {
      this.$refs.ywhModal1.showModal();
      this.$refs.ywhModal1.updateForm();
    },
  },
  created() {
    // 获取页面数据
    this.getList();
  },
  components: {},
};
</script>

<style lang="scss" scoped></style>

<template>
	<div class="doc-record-page">
		<div v-if="dataList && dataList.length === 0">
			<a-empty>
				<span slot="description">
					暂无备案信息
					<a href="javascript:void(0)" @click="handleAdd">现在添加</a>
				</span>
			</a-empty>
		</div>
		<div v-if="dataList && dataList.length > 0">
			<div class="ywh-desc">
				<a-row>
					<a-col :span="6">
						<div class="ywh-desc-header">
							<div class="title">备案详情信息</div>
						</div>
						<div class="ywh-desc-content">
							<div class="desc-item">
								<span class="label">业主大会代码：</span>
								<span>{{ dataList[0].code }}</span>
							</div>
							<div class="desc-item">
								<span class="label">业主委员会名称：</span>
								<span>{{ dataList[0].committeeName }}</span>
							</div>
							<div class="desc-item">
								<span class="label">届别：</span>
								<span>{{ dataList[0].class }}</span>
							</div>
							<div class="mt40 status">
								状态：<a href="javascript:void(0)">已归档</a>
							</div>
						</div>
					</a-col>
					<a-col :span="6">
						<div class="ywh-desc-header"></div>
						<div class="ywh-desc-content">
							<div class="desc-item">
								<span class="label">业主大会名称：</span>
								<span>{{ dataList[0].assemblyName }}</span>
							</div>
							<div class="desc-item">
								<span class="label">业主委员会地址：</span>
								<span>{{ dataList[0].committeeAddress }}</span>
							</div>
							<div class="desc-item">
								<span class="label">任期：</span>
								<span
									>{{
										dataList[0] &&
										dataList[0].termOffice &&
										dataList[0].termOffice[0]
									}}至{{
										dataList[0] &&
										dataList[0].termOffice &&
										dataList[0].termOffice[1]
									}}</span
								>
							</div>
						</div>
					</a-col>
					<a-col :span="6">
						<div class="ywh-desc-header">
							<div class="title">备案证明预览</div>
						</div>
						<div class="ywh-desc-content">
							<img
								width="100%"
								height="214"
								@click="$download(dataList[0].fileUrl[0].url)"
								:src="dataList[0].fileUrl[0].url"
							/>
						</div>
					</a-col>
					<a-col :span="6">
						<div class="tl-right">
							<a-button
								type="primary"
								class="ywh-btn"
								@click="handleEdit(dataList[0])"
								>修改备案信息</a-button
							>
						</div>
					</a-col>
				</a-row>
			</div>
			<div class="ywh-search-form">
				<div class="item-form"></div>
			</div>

			<ztable2
				:detailColumns="record_detail_columns"
				:columns="record_table_columns"
				:dataSource="dataList"
				@change="tableChangeHandle"
			>
				<span slot="action" slot-scope="text, record">
					<div class="editable-row-operations">
						<a
							href="javascript:void(0)"
							@click="handleEdit(record, true)"
							>查看详情</a
						>
						<!-- <a href="javascript:void(0)" @click="handleEdit(record)">编辑</a>
          <a href="javascript:void(0)" @click="handleDelete(record._id)"
            >删除</a
          > -->
					</div>
				</span>
			</ztable2>
		</div>

		<a-modal
			class="ywh-modal"
			:title="modalTitle"
			:visible="modalVisible"
			:confirm-loading="modalLoading"
			@ok="handleModalOk"
			@cancel="handleModalCancel"
			ok-text="确认"
			cancel-text="取消"
			:footer="showModalBtn"
		>
			<zform
				:formConfig="record_form_config"
				:formData="{}"
				ref="modal-form"
			></zform>
		</a-modal>
	</div>
</template>

<script>
import tableMixin from '@/mixin/table.list.js'
import mixin from './mixin.js'
import { getRecordInfo } from '@/api/community/UOC'
export default {
	data() {
		return {
			modalVisible: false,
			detailInfo: {},
			dataList: [],
			isDisable: false,
		}
	},
	mixins: [tableMixin, mixin],
	methods: {
		getList() {
			getRecordInfo().then((res) => {
				this.dataList = res.list
			})
		},
		// 弹框提交接口api和params处理
		submitRequestHandle(formValues) {
			this.editId = 1

			return {
				// 保存和更新接口
				putApi: `/api/uoc/recordInformation`,
				// 表单外的提交参数
				params: {},
			}
		},
		// 列表删除api处理
		deleteRequestHandle(recordId) {},
	},
	created() {
		// 获取页面数据
		this.getList()
	},
	components: {},
}
</script>

<style lang="scss" scoped></style>

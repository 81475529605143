<!--
 * @Author: Yin Xiang Zheng
 * @LastEditors: Yin Xiang Zheng
 * @LastEditTime: 2021-03-09 17:00:17
 Big Screen Voting
 大屏唱票
-->
<template>
	<div class="big-screen-voting">
		<a-row>
			<a-col :span="8">
				<div>
					<template v-if="voteDetail">
						<h5>唱票进度：{{ process }}</h5>
						<a-button-group style="margin-right: 8px">
							<a-button
								v-if="votedIdList && votedIdList.length > 0"
								@click="previousHandle"
								type="primary"
							>
								<a-icon type="left" />上一张
							</a-button>
							<a-button type="primary" @click="nextHandle">
								下一张<a-icon type="right" />
							</a-button>
						</a-button-group>
					</template>
					<a-button-group>
						<!-- <a-button>跳过</a-button> -->
						<a-button @click="$router.go(-1)">返回工作台</a-button>
					</a-button-group>
				</div>
				<div style="margin-top: 24px">
					<a-descriptions :column="1">
						<a-descriptions-item label="产业地址">
							{{
								voteDetail &&
								voteDetail.buildingInformation.address
							}}
						</a-descriptions-item>
						<a-descriptions-item label="建筑面积">
							{{
								voteDetail &&
								voteDetail.buildingInformation.area
							}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;平方米
						</a-descriptions-item>
						<a-descriptions-item label="表决票编号">
							{{ voteDetail && voteDetail.serialNumber }}
						</a-descriptions-item>
					</a-descriptions>
				</div>
				<div v-if="flowDetail">
					<a-form :form="form">
						<a-form-item
							v-for="(item, index) of flowDetail
								.relatedInformation.cotingMatters"
							:key="index"
						>
						<span slot="label" style="white-space: normal;text-align: left;line-height: 1.5;display: inline-block;">
							{{item.votingMatters}}
						</span>
							<a-radio-group
								
								v-decorator="[
									`cotingMatters_${item.index}`,
									{
										rules: [
											{
												required: true,
												message: '请输入必填项!',
											},
										],
									},
								]"
								:options="
									item.votingForm == 1
										? [
												{ label: '同意', value: 1 },
												{ label: '反对', value: 2 },
												{ label: '弃权', value: 3 },
												{ label: '计入多数', value: 5 },
												{ label: '废票', value: 4 },
										  ]
										: [
												{ label: '同意', value: 1 },
												{ label: '反对', value: 2 },
												{ label: '弃权', value: 3 },
												{ label: '废票', value: 4 },
										  ]
								"
							/>
						</a-form-item>
					</a-form>
					<div class="form-handle-btn" v-if="voteDetail">
						<a-button type="primary" @click="confirmVote">
							确认投票
						</a-button>
						<a-button type="" @click="form.resetFields()">
							重置
						</a-button>
					</div>
				</div>
			</a-col>
			<a-col :span="16">
				<div class="preview-img">
					<img :src="voteDetail && voteDetail.uploadInfo.filePath" />
				</div>
			</a-col>
		</a-row>
	</div>
</template>

<script>
import {
	getFlowTypeNode,
	getOtherFiles,
	updateRelatedDocuments,
	getDetail,
	getOneUploadInfo,
	updateFlowVoteAnswer,
	completedNodeFn,
} from '@/api/flow'
import PreviewImg from '@/components/previewImg.vue'
import SigningSituationManageModal from '@/components/signingSituationManageModal.vue'
import UploadVoteModal from '@/components/uploadVoteModal.vue'
import moment from 'moment'

export default {
	data() {
		return {
			form: this.$form.createForm(this, {
				name: 'form',
			}),
			votedIdList: [],
			flowDetail: null,
			process: `0/0`,
			voteDetail: null,
		}
	},
	created() {
		const {
			params: { id },
		} = this.$route
		this.flowId = id
		this.getFlowDetail(id)
		this.getOneVote(id)
	},
	methods: {
		previousHandle() {},
		nextHandle() {
			this.confirmVote()
		},
		confirmVote() {
			this.form.validateFieldsAndScroll((errors, values) => {
				if (errors) {
					this.$message.error('请输入必填项!')
					console.log('err', errors)
					return
				}
				console.log(values)
				if (!this.voteDetail) {
					return
				}
				updateFlowVoteAnswer(this.flowId, {
					answersVoting: values,
					voteId: this.voteDetail._id,
					type: 1,
				})
					.then((res) => {
						this.$message.success('更新成功！', 2)
						this.votedIdList.push(this.voteDetail._id)
						this.form.resetFields()
						this.getOneVote(this.flowId)
					})
					.catch((err) => {
						this.$message.error('更新失败：' + err)
					})
			})
		},
		async getFlowDetail(id) {
			try {
				const flowDetail = await getDetail(id)
				this.flowDetail = flowDetail || {}
			} catch (error) {
				console.log(error)
			}
		},
		async getOneVote(id) {
			try {
				const voteDetail = await getOneUploadInfo(id)
				const { process, data } = voteDetail
				this.process = process
				this.voteDetail = data
			} catch (error) {
				this.voteDetail = null
				console.log(error)
			}
		},
	},
}
</script>

<style lang="scss">
.big-screen-voting {
	.preview-img {
		width: 100%;
		border: 1px #ddd solid;
		border-radius: 8px;
		padding: 16px;
		img {
			width: 100%;
		}
	}
	.form-handle-btn {
		display: flex;
		justify-content: space-between;
		padding: 0px 16px;
	}
}
</style>
<!--
 * @Author: Yin Xiang Zheng
 * @LastEditors: Yin Xiang Zheng
 * @LastEditTime: 2020-11-14 23:53:56
-->
<template>
	<div class="home" ref="home">
		<div class="edit-container">
			<div id="editor"></div>
			<div class="ancillary-information">
				<a-form
					:form="form"
					:label-col="{ span: 5 }"
					:wrapper-col="{ span: 12 }"
				>
					<a-row>
						<a-col :span="24">
							<a-form-item>
								<a-input
									v-decorator="[
										'title',
										{
											rules: [
												{
													required: true,
													message: '必填项',
												},
											],
										},
									]"
									placeholder="请输入公告标题"
									:maxLength="120"
								/>
							</a-form-item>
						</a-col>

						<a-col :span="2">
							<a-form-item>
								<uploadFile
									v-decorator="[
										'cover',
										{
											getValueFromEvent: uploadNormFile,
											rules: [
												{
													required: true,
													message: '必填项',
												},
											],
										},
									]"
									:item="{
										props: 'cover',
										uploadText: '长x宽(1440x1440)',
										maxUpload: 1,
									}"
									:form="form"
								></uploadFile>
							</a-form-item>
						</a-col>
						<a-col :span="18">
							<a-form-item>
								<a-textarea
									v-decorator="[
										'introduction',
										{
											rules: [
												{
													required: true,
													message: '必填项',
												},
											],
										},
									]"
									placeholder="请公告摘要"
									:maxLength="120"
									:auto-size="{ minRows: 4, maxRows: 6 }"
								/>
							</a-form-item>
						</a-col>
					</a-row>
				</a-form>
			</div>
		</div>

		<div class="action-bar">
			<span class="current-word-count">当前字数: {{ textLength }}</span>
			<div>
				<a-button type="" @click="saveBtn(0)">保存</a-button>
				<a-button
					type="primary"
					style="margin-left: 12px"
					@click="saveBtn(1)"
					>保存并发布</a-button
				>
			</div>
		</div>
	</div>
</template>

<script>
// 引入 wangEditor
import wangEditor from 'wangeditor'
import UpladFile from '@/components/zform/utils/uploadToAliOss'
import uploadFile from '@/components/zform/components/zossUpload.vue'
import { createNews, getDetail, updateNews } from '@/api/announcement'

export default {
	data() {
		return {
			editor: null,
			editorData: '',
			textLength: '',
			form: this.$form.createForm(this, { name: 'edit' }),
			editID: null,
		}
	},
	components: {
		uploadFile,
	},
	mounted() {
		const editor = new wangEditor(`#editor`)
		// 配置 onchange 回调函数，将数据同步到 vue 中
		editor.config.onchange = (newHtml) => {
			this.editorData = newHtml
			this.textLength = editor.txt.text().length
		}
		let height = this.$refs.home.offsetHeight //100
		editor.config.uploadImgMaxLength = 1
		editor.config.pasteFilterStyle = false
		editor.config.height = height - 90
		editor.config.uploadImgMaxSize = 100 * 1024 * 1024 // 100M
		editor.config.customUploadImg = function (resultFiles, insertImgFn) {
			UpladFile(resultFiles[0]).then((res) => {
				insertImgFn([res.url])
			})
		}

		editor.create()
		this.editor = editor

		const { _id } = this.$route.query
		this.editID = _id
		if (_id) {
			this.getNewsDetail()
		}
	},

	methods: {
		getNewsDetail(editor) {
			getDetail(this.editID).then((res) => {
				console.log('res', res)
				const { content, cover, introduction, title } = res
				this.editor.txt.html(content)
				this.form.setFieldsValue({
					cover: [{ url: cover, uid: 1 }],
					introduction,
					title,
				})
			})
		},
		saveBtn(status) {
			this.form.validateFieldsAndScroll(async (error, values) => {
				if (error) {
					return
				}
				const { title, introduction, cover } = values
				const data = {
					title: title,
					content: this.editorData,
					status: status,
					introduction: introduction,
					cover: cover[0].url,
				}
				try {
					if (this.editID) {
						await updateNews(this.editID, data)
					} else {
						await createNews(data)
					}
				} catch (error) {}

				this.$message.success('保存成功！')
				setTimeout(() => {
					this.$router.push('/community/officialNews')
				}, 1000)
			})
		},
		uploadNormFile(e) {
			if (Array.isArray(e)) {
				return e
			}
			return e && e.fileList
		},
		getEditorData() {
			// 通过代码获取编辑器内容
			let data = this.editor.txt.html()
			alert(data)
		},
	},
	beforeDestroy() {
		// 调用销毁 API 对当前编辑器实例进行销毁
		this.editor.destroy()
		this.editor = null
	},
}
</script>

<style lang="scss">
.home {
	width: 100%;
	height: 100%;
	margin: auto;
	position: relative;

	.edit-container {
		overflow-y: auto;
		height: 100%;
		#editor {
			.w-e-text-container {
				border: 0 !important;
			}
		}
		.ancillary-information {
			width: 100%;
			height: 255px;
			background: white;
			padding: 20px 60px 0 30px;
			border-top: 1px solid #ebebeb !important;
		}
	}
	.action-bar {
		height: 50px;
		width: 100%;
		background: #ffffff;
		position: absolute;
		bottom: 0px;
		z-index: 10001;
		border-top: 1px solid #d9dadc;
		border-top-width: 0;
		box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.05);
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 60px 0 30px;
		.current-word-coun {
			color: #9a9a9a;
		}
	}
}
</style>